import { BellFilled } from "@ant-design/icons";
import { Badge, Popover } from "antd";
import { NotificationPopoverContent } from "./NotificationPopoverContent";

export const NotificationPopover: React.FC = () => {
  return (
    <Popover
      title=""
      trigger="click"
      showArrow={false}
      placement="bottomRight"
      content={<NotificationPopoverContent />}
    >
      <span style={{ cursor: "pointer" }}>
        <Badge size="small" count={4}>
          <BellFilled style={{ fontSize: 20, color: "#172B4D" }} />
        </Badge>
      </span>
    </Popover>
  );
};
