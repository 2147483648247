import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { PageHeader, useModal, useSelect } from "@refinedev/antd";
import { HttpError, useTranslate } from "@refinedev/core";
import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import WithLabel from "components/withLabel";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IProduct, IProductQuanity } from "interfaces";
import { useMemo, useState } from "react";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

interface ProductTableProps {
  productList: IProductQuanity[];
  onProductListChanged?: (list: IProductQuanity[]) => void;
  hasAdd?: boolean;
  hasEdit?: boolean;
  hasDelete?: boolean;
  isCreate?: boolean;
}

export const ProductTable: React.FC<ProductTableProps> = ({
  productList = [],
  onProductListChanged,
  hasAdd,
  hasEdit,
  hasDelete,
  isCreate,
}) => {
  const t = useTranslate();

  const { hasPharRep, hasInvManagement } = useUserRole();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const {
    selectProps: {
      showSearch: showSearchProduct,
      onSearch: onSearchProduct,
      ...productSelectProps
    },
  } = useSelect<IProduct, HttpError>({
    resource: API_RESOURCES.products,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const filterProductSelectOptions = useMemo(() => {
    if (!productList.length) {
      return productSelectProps.options;
    }

    return productSelectProps.options?.filter(
      (opt) => !productList.find((it) => it.product?.id === opt.value)
    );
  }, [productList, productSelectProps.options]);

  const [upsertProductValues, setUpsertProductValues] =
    useState<IProductQuanity | null>(null);

  const {
    modalProps: modalAddProductProps,
    show: showAddProduct,
    close: closeAddProduct,
  } = useModal();

  const addOrEditProduct = async ({ key, ...rest }: IProductQuanity) => {
    const newProductList = !key
      ? productList.concat({
          key: productList.length + 1,
          ...rest,
        })
      : productList.map((product) => {
          if (product.key === key) {
            return {
              key,
              ...rest,
            };
          }
          return product;
        });

    closeAddProduct();
    setUpsertProductValues(null);
    onProductListChanged?.(newProductList);
  };

  const deleteProduct = async (record: IProductQuanity) => {
    const newProductList = productList
      .filter((product) => product.key !== record.key)
      .map((visitor, index) => ({
        ...visitor,
        key: index + 1,
      }));

    onProductListChanged?.(newProductList);
  };

  const [isEdit, setIsEdit] = useState(false);

  const onEdit = (record: IProductQuanity) => {
    setIsEdit(true);
    showAddProduct();
    setTimeout(() => setUpsertProductValues({ ...record }), 0);
  };

  const onAdd = () => {
    setIsEdit(false);
    showAddProduct();
  };

  const onDelete = (record: IProductQuanity) => {
    deleteProduct(record);
  };

  const modalEnableOk = () => {
    if (hasPharRep) {
      return (
        upsertProductValues &&
        upsertProductValues.product &&
        upsertProductValues.requestQuantity
      );
    }

    if (hasInvManagement) {
      return (
        upsertProductValues &&
        upsertProductValues.product &&
        upsertProductValues.requestQuantity &&
        upsertProductValues.acceptQuantity
      );
    }

    return false;
  };

  return (
    <>
      <PageHeader
        backIcon={null}
        style={{ padding: 0 }}
        title={
          <Typography.Text
            style={{
              fontSize: 16,
            }}
          >
            {t("orders.productList.title")}
          </Typography.Text>
        }
        extra={
          hasAdd
            ? [
                <Button
                  key="add-product"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={onAdd}
                >
                  {t("orders.productList.buttons.addProduct")}
                </Button>,
              ]
            : []
        }
      />

      <Table
        rowKey="key"
        dataSource={productList}
        pagination={false}
        scroll={{ x: 300 }}
      >
        <Table.Column<IProduct>
          dataIndex="key"
          key="key"
          title={"#"}
          render={(_, record, index) => {
            return index + 1;
          }}
        />

        <Table.Column
          dataIndex={["product", "name"]}
          key="product"
          title={t("orders.productList.fields.name")}
        />

        <Table.Column
          dataIndex="requestQuantity"
          key="requestQuantity"
          title={t("orders.productList.fields.requestQuantity")}
        />

        {!isCreate && (
          <Table.Column<IProductQuanity>
            dataIndex="acceptQuantity"
            key="acceptQuantity"
            title={t("orders.productList.fields.acceptQuantity")}
            render={(acceptQuantity, record) => {
              const needHighlight = record.requestQuantity !== acceptQuantity;
              return (
                <span
                  style={{ color: needHighlight ? "#FF4D4F" : "#000000E0" }}
                >
                  {acceptQuantity}
                </span>
              );
            }}
          />
        )}

        <Table.Column
          dataIndex="employeeNote"
          key="employeeNote"
          title={t("orders.productList.fields.employeeNote")}
        />

        {!isCreate && (
          <Table.Column
            dataIndex="warehouseNote"
            key="warehouseNote"
            title={t("orders.productList.fields.warehouseNote")}
          />
        )}

        {(hasEdit || hasDelete) && (
          <Table.Column<IProductQuanity>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {hasEdit && (
                  <Button
                    icon={<EditOutlined />}
                    size="small"
                    onClick={() => onEdit(record)}
                  />
                )}
                {hasDelete && (
                  <Popconfirm
                    title={t("buttons.confirm")}
                    onConfirm={() => onDelete(record)}
                    onCancel={() => null}
                    okText={t("buttons.clear")}
                    cancelText={t("buttons.cancel")}
                    okType="danger"
                  >
                    <Button icon={<DeleteOutlined />} danger size="small" />
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        )}
      </Table>

      <Modal
        {...modalAddProductProps}
        width={700}
        title={
          isEdit
            ? t("orders.productList.modalEditTitle")
            : t("orders.productList.modalTitle")
        }
        okButtonProps={{
          disabled: !modalEnableOk(),
        }}
        onCancel={() => {
          setUpsertProductValues(null);
          closeAddProduct();
        }}
        onOk={() => {
          if (modalEnableOk()) {
            addOrEditProduct(upsertProductValues!);
          }
        }}
      >
        <Row gutter={[16, 24]} style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Col xs={12}>
            <WithLabel
              label={t("orders.productList.fields.name")}
              labelStyles={{ color: "#000000D9" }}
              hasRequiredMark
            >
              <Select
                disabled={!hasPharRep}
                {...productSelectProps}
                options={filterProductSelectOptions}
                labelInValue
                allowClear
                value={{
                  value: upsertProductValues?.product?.id ?? "",
                  label: upsertProductValues?.product?.name ?? "",
                }}
                onChange={(item) => {
                  setUpsertProductValues({
                    ...upsertProductValues,
                    product: item
                      ? {
                          id: item.value,
                          name: item.label,
                        }
                      : undefined,
                  });
                }}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </WithLabel>
          </Col>

          <Col xs={12}></Col>

          <Col xs={24} md={12}>
            <WithLabel
              label={t("orders.productList.fields.requestQuantity")}
              labelStyles={{ color: "#000000D9" }}
              hasRequiredMark
            >
              <Input
                disabled={!hasPharRep}
                type="number"
                value={upsertProductValues?.requestQuantity}
                onChange={(event) =>
                  setUpsertProductValues({
                    ...upsertProductValues,
                    requestQuantity: Number(event.target.value),
                    quantity: Number(event.target.value),
                  })
                }
              />
            </WithLabel>
          </Col>

          <Col xs={24} md={12}>
            <WithLabel
              labelStyles={{ color: "#000000D9" }}
              label={
                hasInvManagement
                  ? t("orders.productList.fields.employeeNote")
                  : t("orders.productList.fields.note")
              }
            >
              <Input
                disabled={!hasPharRep}
                value={upsertProductValues?.employeeNote}
                onChange={(event) =>
                  setUpsertProductValues({
                    ...upsertProductValues,
                    employeeNote: event.target.value,
                  })
                }
              />
            </WithLabel>
          </Col>

          {hasInvManagement && (
            <Col xs={24} md={12}>
              <WithLabel
                label={t("orders.productList.fields.acceptQuantity")}
                hasRequiredMark
                labelStyles={{ color: "#000000D9" }}
              >
                <Input
                  type="number"
                  value={upsertProductValues?.acceptQuantity}
                  onChange={(event) =>
                    setUpsertProductValues({
                      ...upsertProductValues,
                      acceptQuantity: Number(event.target.value),
                    })
                  }
                />
              </WithLabel>
            </Col>
          )}

          {hasInvManagement && (
            <Col xs={24} md={12}>
              <WithLabel
                label={t("orders.productList.fields.warehouseNote")}
                labelStyles={{ color: "#000000D9" }}
              >
                <Input
                  value={upsertProductValues?.warehouseNote}
                  onChange={(event) =>
                    setUpsertProductValues({
                      ...upsertProductValues,
                      warehouseNote: event.target.value,
                    })
                  }
                />
              </WithLabel>
            </Col>
          )}
        </Row>
      </Modal>
    </>
  );
};
