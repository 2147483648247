import { List, Avatar, Card } from "antd";

export const mockData = [
  {
    title: "New message from Sophie",
    description: <>2 days ago</>,
    avatar: null,
  },
  {
    title: "New album by Travis Scott",
    description: <>2 days ago</>,
    avatar: null,
  },
  {
    title: "Payment completed",
    description: <>2 days ago</>,
    avatar: null,
  },
];

export const NotificationPopoverContent: React.FC = () => {
  const menu = (
    <List
      itemLayout="horizontal"
      dataSource={mockData}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                shape="square"
                src={
                  item.avatar ||
                  "https://api.dicebear.com/6.x/big-smile/svg?seed=Sasha"
                }
              />
            }
            title={item.title}
            description={item.description}
          />
        </List.Item>
      )}
    />
  );

  return (
    <Card
      bordered={false}
      style={{ width: 300, boxShadow: "none" }}
      bodyStyle={{ padding: 8 }}
    >
      {menu}
    </Card>
  );
};
