import { RangeValue } from "components/selectDate";
import { endOfDay, startOfDay, subDays, subMonths, subYears } from "date-fns";
import dayjs from "dayjs";

export function rangeDayFilters(date: Date, daysBefore: number): RangeValue {
  return [dayjs(startOfDay(subDays(date, daysBefore))), dayjs(endOfDay(date))];
}

export function rangeMonthFilters(
  date: Date,
  monthsBefore: number
): RangeValue {
  return [
    dayjs(startOfDay(subMonths(date, monthsBefore))),
    dayjs(endOfDay(date)),
  ];
}

export function rangeYearFilters(date: Date, yearsBefore: number): RangeValue {
  return [
    dayjs(startOfDay(subYears(date, yearsBefore))),
    dayjs(endOfDay(date)),
  ];
}
