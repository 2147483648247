import { CSSProperties } from "react";

interface SelectOptionLabelProps {
  prefix?: string;
  label?: string;
  prefixStyle?: CSSProperties;
  labelStyle?: CSSProperties;
}

export const SelectOptionLabel: React.FC<SelectOptionLabelProps> = ({
  prefix,
  label,
  prefixStyle = {},
  labelStyle = {},
}) => {
  return (
    <div>
      <span style={{ color: "#A0A4A8", ...prefixStyle }}>
        {prefix} {" : "}
      </span>
      <span style={labelStyle}>{label}</span>
    </div>
  );
};
