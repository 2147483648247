import {
  CrudFilters,
  CrudOperators,
  CrudSorting,
  DataProvider,
  MetaQuery,
} from "@refinedev/core";
import { AxiosHeaders, AxiosInstance, HeadersDefaults } from "axios";
import {
  DEFAULT_ALL_FILTER_VALUE,
  SERVICE_BILLING,
  SERVICE_COMPANIES,
  SERVICE_MOBISALE,
} from "config";
import { stringify } from "query-string";

export const generateSort = (sorters?: CrudSorting) => {
  if (sorters && sorters.length > 0) {
    const _sort: string[] = [];
    const _order: string[] = [];

    sorters.forEach((item) => {
      _sort.push(item.field);
      _order.push(item.order);
    });

    return {
      _sort,
      _order,
    };
  }

  return;
};

export const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "ne":
    case "gte":
    case "lte":
      return `_${operator}`;
    case "contains":
      return "_like";
    case "eq":
    default:
      return "";
  }
};

export const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};

  if (filters) {
    filters.forEach((filter) => {
      if (filter.operator === "or" || filter.operator === "and") {
        throw new Error(
          `[@refinedev/simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`
        );
      }

      if ("field" in filter) {
        const { field, operator, value } = filter;

        if (field === "q") {
          queryFilters[field] =
            typeof value === "string" ? value.trim() : value;
          return;
        }

        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] =
          value === DEFAULT_ALL_FILTER_VALUE ? undefined : value;
      }
    });
  }

  return queryFilters;
};

function urlAdapter(
  apiUrl: string,
  resource: string,
  meta?: MetaQuery,
  action?: "getList" | "getMany" | "create"
) {
  let url = `${apiUrl}/${resource}`;

  if (meta?.service === SERVICE_COMPANIES && meta?.extraProps?.companyId) {
    url = `${apiUrl}/${SERVICE_COMPANIES}/${meta?.extraProps?.companyId}/${resource}`;
  } else if (meta?.service === SERVICE_MOBISALE) {
    url = `${apiUrl}/${SERVICE_MOBISALE}/${resource}`;

    if (action === "create") {
      url = `${apiUrl}/${SERVICE_MOBISALE}/${resource}/create`;
    }
  } else if (meta?.service === SERVICE_BILLING) {
    url = `${apiUrl}/${SERVICE_BILLING}/${resource}`;
  }

  return url;
}

function urlAdapterOne(
  apiUrl: string,
  resource: string,
  id: string,
  meta?: MetaQuery,
  action?: "update" | "getOne" | "deleteOne"
) {
  let url = `${apiUrl}/${resource}/${id}`;

  if (meta?.service === SERVICE_COMPANIES && meta?.extraProps?.companyId) {
    url = `${apiUrl}/${SERVICE_COMPANIES}/${meta?.extraProps?.companyId}/${resource}/${id}`;
  } else if (meta?.service === SERVICE_MOBISALE) {
    url = `${apiUrl}/${SERVICE_MOBISALE}/${resource}/${id}`;

    if (action === "update") {
      url = `${apiUrl}/${SERVICE_MOBISALE}/${resource}/update`;

      // TODO: remove later
      if (resource === "employees") {
        url = `${apiUrl}/${SERVICE_MOBISALE}/${resource}/${id}`;
      }
    }
  } else if (meta?.service === SERVICE_BILLING) {
    url = `${apiUrl}/${SERVICE_BILLING}/${resource}/${id}`;
  }

  return url;
}

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): Omit<
  Required<DataProvider>,
  "createMany" | "updateMany" | "deleteMany"
> => ({
  getList: async ({
    resource,
    pagination = { current: 1, pageSize: 10 },
    filters,
    sorters,
    meta,
  }) => {
    const url = urlAdapter(apiUrl, resource, meta, "getList");

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const queryFilters = generateFilter(filters);

    const query: {
      page?: number;
      size?: number;
      sort?: string;
      order?: string;
    } =
      pagination.mode !== "off"
        ? {
            page: current - 1, // @IMPORTANT: server page starts from 0
            size: pageSize,
          }
        : {};

    if (mode === "server") {
      // query._start = (current - 1) * pageSize;
      // query._end = current * pageSize;
    }

    const generatedSort = generateSort(sorters);
    if (generatedSort) {
      const { _sort, _order } = generatedSort;
      query.sort = _sort.join(",");
      query.order = _order.join(",");
    }

    const { data } = await httpClient.get(
      `${url}?${stringify(query)}&${stringify(queryFilters)}`
    );

    if (pagination.mode !== "off") {
      return {
        data: data.content.map((item: any, idx: number) => ({
          ...item,
          idx: idx + pageSize * (current - 1) + 1,
        })),
        total: data.totalElements,
      };
    }

    return {
      data: data.map((item: any, idx: number) => ({
        ...item,
        idx: idx + 1,
      })),
      total: data.length,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const url = urlAdapter(apiUrl, resource, meta, "getMany");

    const { data } = await httpClient.get(`${url}?${stringify({ id: ids })}`);

    return {
      data,
    };
  },

  create: async ({ resource, variables, meta }) => {
    const url = urlAdapter(apiUrl, resource, meta, "create");

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = urlAdapterOne(apiUrl, resource, id as string, meta, "update");

    const { data } = await httpClient.put(url, { ...variables, id });

    return {
      data,
    };
  },

  getOne: async ({ resource, id, meta }) => {
    const url = urlAdapterOne(apiUrl, resource, id as string, meta, "getOne");

    const { data } = await httpClient.get(url);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = urlAdapterOne(
      apiUrl,
      resource,
      id as string,
      meta,
      "deleteOne"
    );

    const { data } = await httpClient.delete(url, {
      data: variables,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          sort: _sort.join(","),
          order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      } as HeadersDefaults & {
        [key: string]:
          | AxiosHeaders
          | string
          | string[]
          | number
          | boolean
          | null;
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
