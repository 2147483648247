import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Spin } from "antd";
import { useMemo, useState } from "react";

import imgTrend1 from "assets/img_trend_1.svg";
import { BarChartWidget } from "components/chart";
import { RangeValue, SelectDateType } from "components/selectDate";
import {
  DEFAULT_DAYS_FOR_REPORTS,
  DEFAULT_MONTHS_FOR_REPORTS,
  DEFAULT_YEARS_FOR_REPORTS,
  SERVICE_MOBISALE,
} from "config";
import { IAreaSaleReportItem, IWithPaging } from "interfaces";
import {
  rangeDayFilters,
  rangeMonthFilters,
  rangeYearFilters,
} from "utils/dayjsHelper";
import { toMillions } from "utils/numberHelper";

interface AreaSalesProps {
  areaId: string;
}

export const AreaSales: React.FC<AreaSalesProps> = ({ areaId }) => {
  return (
    <Card style={{ marginTop: 20 }}>
      <AreaSalesChart areaId={areaId} />
    </Card>
  );
};

type AreaSalesChartProps = AreaSalesProps;

const AreaSalesChart: React.FC<AreaSalesChartProps> = ({ areaId }) => {
  const t = useTranslate();

  const apiUrl = useApiUrl();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<IAreaSaleReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/areas/report`,
    method: "get",
    queryOptions: {
      enabled: Boolean(rangeValue?.[0] && rangeValue?.[1] && areaId && type),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        areaId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return toMillions(
      data?.data?.content?.map((item) => item.totalPrice ?? 0) ?? []
    );
  }, [data]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("areas.details.chart.title")}
        image={imgTrend1}
        series={[
          {
            name: t("areas.details.chart.unit"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("areas.details.chart.unit")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
        wrapperStyle={{ padding: 0 }}
      />
    </Spin>
  );
};
