import { useApiUrl, useCustom } from "@refinedev/core";
import { Spin } from "antd";

import { PackageList } from "components/billing";
import { BILLING_SERVICE_MOBISALE, SERVICE_BILLING } from "config";
import { useCompanyId } from "hooks/useCompanyId";
import { IMobisalePackage, IMobisaleSubscription } from "interfaces";

export const BillingInfo: React.FC = () => {
  const apiUrl = useApiUrl();

  const companyId = useCompanyId();

  const {
    data: subscriptionData,
    isLoading: isLoadingSubscription,
    refetch: refetchSubscriptionData,
  } = useCustom<IMobisaleSubscription>({
    url: `${apiUrl}/${SERVICE_BILLING}/subscriptions/service/${BILLING_SERVICE_MOBISALE}/company/${companyId}`,
    method: "get",
  });

  const { data: packagesData, isLoading: isLoadingPackages } = useCustom<
    IMobisalePackage[]
  >({
    url: `${apiUrl}/${SERVICE_BILLING}/packages/service/${BILLING_SERVICE_MOBISALE}`,
    method: "get",
  });

  return (
    <Spin spinning={isLoadingPackages || isLoadingSubscription}>
      <PackageList
        createSubscriptionInModal
        packages={packagesData?.data}
        subscription={subscriptionData?.data}
        onAfterCreateSubscription={() => {
          refetchSubscriptionData();
        }}
      />
    </Spin>
  );
};
