import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  ROLE_CODE_RECORDS,
  USER_AVATAR_KEY,
  USER_ID_KEY,
  USER_NAME_KEY,
  USER_ROLE_KEY,
  USER_USERNAME_KEY,
} from "config";
import { IUserRoleCode } from "interfaces";
import { KeycloakTokenParsed } from "keycloak-js";

function getFirstRoleInListSupported(
  supportedRoleCodes: string[],
  userRoleCodes: IUserRoleCode[]
) {
  for (const _roleCode of supportedRoleCodes) {
    const roleCode = _roleCode as IUserRoleCode;

    if (userRoleCodes.includes(roleCode)) {
      return roleCode;
    }
  }

  return null;
}

export function getUserRole(
  tokenParsed?: KeycloakTokenParsed
): IUserRoleCode | null {
  const supportedRoleCodes = Object.keys(ROLE_CODE_RECORDS);

  // check roles in resource_access
  if (tokenParsed?.resource_access?.mobisale?.roles) {
    const mobisaleRoleCodes =
      (tokenParsed?.resource_access?.mobisale?.roles.filter(
        (role) => !!ROLE_CODE_RECORDS[role as IUserRoleCode]
      ) ?? []) as IUserRoleCode[];

    return getFirstRoleInListSupported(supportedRoleCodes, mobisaleRoleCodes);
  }

  // check roles in realm access
  if (tokenParsed?.realm_access?.roles) {
    const mobisaleRoleCodes = (tokenParsed?.realm_access?.roles.filter(
      (role) => !!ROLE_CODE_RECORDS[role as IUserRoleCode]
    ) ?? []) as IUserRoleCode[];

    return getFirstRoleInListSupported(supportedRoleCodes, mobisaleRoleCodes);
  }

  return null;
}

export function removeAllUserIdentities() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(USER_ID_KEY);
  localStorage.removeItem(USER_NAME_KEY);
  localStorage.removeItem(USER_USERNAME_KEY);
  localStorage.removeItem(USER_ROLE_KEY);
  localStorage.removeItem(USER_AVATAR_KEY);
}
