import { Col, Image, Row } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import TextBox from "components/textBox";
import WithLabel from "components/withLabel";
import { DATE_FORMAT, GENDER_LIST, PERCENTAGE_UNIT, ROLE_LIST } from "config";
import { IEmployee } from "interfaces";
import { formatCurrency } from "utils/numberHelper";

interface EmployeeCommonProps {
  data?: IEmployee;
}

export const EmployeeCommon: React.FC<EmployeeCommonProps> = ({ data }) => {
  const { t } = useTranslation();

  const itemRole = (
    <TextBox
      value={
        data?.role
          ? ROLE_LIST(t).find((role) => role.value === data?.role)?.label
          : "--"
      }
      label={t("employees.fields.role")}
    />
  );

  const itemUsername = (
    <TextBox
      value={data?.username ?? "--"}
      label={t("employees.fields.username")}
    />
  );

  const itemFirstName = (
    <TextBox
      value={data?.firstName ?? "--"}
      label={t("employees.fields.firstName")}
    />
  );

  const itemLastName = (
    <TextBox
      value={data?.lastName ?? "--"}
      label={t("employees.fields.lastName")}
    />
  );

  // const itemCompany = (
  //   <TextBox
  //     value={data?.company?.name ?? "--"}
  //     label={t("employees.fields.company")}
  //   />
  // );

  const itemPhone = (
    <TextBox value={data?.phone ?? "--"} label={t("employees.fields.phone")} />
  );

  const itemEmail = (
    <TextBox value={data?.email ?? "--"} label={t("employees.fields.email")} />
  );

  const itemDoB = (
    <TextBox
      value={data?.dob ? dayjs(data.dob).format(DATE_FORMAT) : "--"}
      label={t("employees.fields.dob")}
    />
  );

  const itemGender = (
    <TextBox
      value={
        data?.gender
          ? GENDER_LIST(t).find((item) => item.value === data?.gender)?.label
          : "--"
      }
      label={t("employees.fields.gender")}
    />
  );

  const itemArea = (
    <TextBox
      value={data?.area?.name ?? "--"}
      label={t("employees.fields.area")}
    />
  );

  const itemWarehouse = (
    <TextBox
      value={data?.warehouse?.name ?? "--"}
      label={t("employees.fields.warehouse")}
    />
  );

  const itemSalary = (
    <TextBox
      value={data?.salary ? formatCurrency(data.salary) : "--"}
      label={t("employees.fields.salary_unit")}
    />
  );

  const itemCommissionRate = (
    <TextBox
      value={`${data?.commissionRate} ${PERCENTAGE_UNIT}` ?? "--"}
      label={t("employees.fields.commissionRate")}
    />
  );

  const itemMonthlySalesTarget = (
    <TextBox
      value={
        data?.monthlySalesTarget
          ? formatCurrency(data.monthlySalesTarget)
          : "--"
      }
      label={t("employees.fields.monthlySalesTarget_unit")}
    />
  );

  const itemAvatar = data?.avatar ? (
    <WithLabel label={t("employees.fields.avatar")}>
      <Image style={{ maxWidth: 64, maxHeight: 64 }} src={data?.avatar} />
    </WithLabel>
  ) : null;

  const showCommon = (
    <>
      <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
        <Col xs={24} md={12} lg={8}>
          {itemRole}
        </Col>

        <Col xs={24} md={12} lg={8}>
          {/* {itemCompany} */}
        </Col>
      </Row>

      <Row gutter={[16, 24]}>
        {data?.role === "ADMIN" && (
          <>
            <Col xs={24} md={12} lg={8}>
              {itemUsername}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemFirstName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemLastName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemPhone}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemEmail}
            </Col>
          </>
        )}
        {data?.role === "DIRECTOR" && (
          <>
            <Col xs={24} md={12} lg={8}>
              {itemUsername}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemFirstName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemLastName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemPhone}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemEmail}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemDoB}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemGender}
            </Col>

            <Col xs={24}>{itemAvatar}</Col>
          </>
        )}
        {data?.role === "INV_MANAGEMENT" && (
          <>
            <Col xs={24} md={12} lg={8}>
              {itemUsername}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemFirstName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemLastName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemPhone}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemEmail}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemDoB}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemGender}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemWarehouse}
            </Col>

            <Col xs={24}>{itemAvatar}</Col>
          </>
        )}
        {data?.role === "PHAR_REP" && (
          <>
            <Col xs={24} md={12} lg={8}>
              {itemUsername}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemFirstName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemLastName}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemPhone}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemEmail}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemDoB}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemGender}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemArea}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemSalary}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemCommissionRate}
            </Col>

            <Col xs={24} md={12} lg={8}>
              {itemMonthlySalesTarget}
            </Col>

            <Col xs={24}>{itemAvatar}</Col>
          </>
        )}
      </Row>
    </>
  );

  return showCommon;
};
