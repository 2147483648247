import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { PageHeader, useModal, useSelect } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import WithLabel from "components/withLabel";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { IProduct, IProductWarehouseQuanity, IWarehouse } from "interfaces";
import { useMemo, useState } from "react";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { AddMoreProducts } from "./AddMoreProducts";
import { AddProductHitory } from "./AddProductHistory";

interface WarehouseTableProps {
  warehouseList: IProductWarehouseQuanity[];
  onWarehouseListChanged?: (list: IProductWarehouseQuanity[]) => void;
  hasAdd?: boolean;
  hasEdit?: boolean;
  hasDelete?: boolean;
  hasAddMore?: boolean;
  hasViewHistory?: boolean;
}

export const WarehouseTable: React.FC<WarehouseTableProps> = ({
  warehouseList = [],
  onWarehouseListChanged,
  hasAdd,
  hasEdit,
  hasDelete,
  hasAddMore,
  hasViewHistory,
}) => {
  const t = useTranslate();

  const { replace } = useNavigation();

  const invalidate = useInvalidate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const {
    selectProps: {
      showSearch: showSearchWarehouse,
      onSearch: onSearchWarehouse,
      ...warehouseSelectProps
    },
  } = useSelect<IWarehouse, HttpError>({
    resource: API_RESOURCES.warehouses,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const filterWarehouseSelectOptions = useMemo(() => {
    if (!warehouseList.length) {
      return warehouseSelectProps.options;
    }

    return warehouseSelectProps.options?.filter(
      (opt) => !warehouseList.find((it) => it.warehouse?.id === opt.value)
    );
  }, [warehouseList, warehouseSelectProps.options]);

  const [upsertWarehouseValues, setUpsertWarehouseValues] =
    useState<IProductWarehouseQuanity | null>(null);

  const {
    modalProps: modalAddWarehouseProps,
    show: showAddWarehouse,
    close: closeAddWarehouse,
  } = useModal();

  const {
    modalProps: modalAddMoreProductsProps,
    show: showAddMoreProducts,
    close: closeAddMoreProducts,
  } = useModal();

  const {
    modalProps: modalAddProductHistoryProps,
    show: showAddProductHistory,
    close: closeAddProductHistory,
  } = useModal();

  const [viewingWarehouseHistory, setViewingWarehouseHistory] =
    useState<IWarehouse | null>(null);

  const addOrEditWarehouse = async ({
    key,
    ...rest
  }: IProductWarehouseQuanity) => {
    const newWarehouseList = !key
      ? warehouseList.concat({
          key: warehouseList.length + 1,
          ...rest,
        })
      : warehouseList.map((product) => {
          if (product.key === key) {
            return {
              key,
              ...rest,
            };
          }
          return product;
        });

    closeAddWarehouse();
    setUpsertWarehouseValues(null);
    onWarehouseListChanged?.(newWarehouseList);
  };

  const deleteProduct = async (record: IProductWarehouseQuanity) => {
    const newWarehouseList = warehouseList
      .filter((product) => product.key !== record.key)
      .map((visitor, index) => ({
        ...visitor,
        key: index + 1,
      }));

    onWarehouseListChanged?.(newWarehouseList);
  };

  const onEdit = (record: IProductWarehouseQuanity) => {
    showAddWarehouse();
    setTimeout(() => setUpsertWarehouseValues({ ...record }), 0);
  };

  const onDelete = (record: IProductWarehouseQuanity) => {
    deleteProduct(record);
  };

  return (
    <>
      <PageHeader
        backIcon={null}
        style={{ padding: 0 }}
        title={
          <Typography.Text
            style={{
              fontSize: 16,
            }}
          >
            {t("products.warehouses.title")}
          </Typography.Text>
        }
        extra={
          hasAdd || hasAddMore
            ? [
                ...(hasAddMore
                  ? [
                      <Button
                        key="add-more-products"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => showAddMoreProducts()}
                      >
                        {t("products.warehouses.buttons.addMoreProducts")}
                      </Button>,
                    ]
                  : []),
                ...(hasAdd
                  ? [
                      <Button
                        key="add-warehouse"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => showAddWarehouse()}
                      >
                        {t("products.warehouses.buttons.addWarehouse")}
                      </Button>,
                    ]
                  : []),
              ]
            : []
        }
      />

      <Table
        rowKey={(record) => String(record?.warehouse?.id)}
        dataSource={warehouseList}
        pagination={false}
        scroll={{ x: 300 }}
      >
        <Table.Column<IProduct>
          dataIndex="key"
          key="key"
          title={"#"}
          render={(_, record, index) => {
            return index + 1;
          }}
        />

        <Table.Column
          dataIndex={["warehouse", "name"]}
          key="warehouse"
          title={t("products.warehouses.fields.warehouse")}
        />

        <Table.Column
          dataIndex="quantity"
          key="quantity"
          title={t("products.warehouses.fields.quantity")}
        />

        {(hasEdit || hasDelete || hasViewHistory) && (
          <Table.Column<IProductWarehouseQuanity>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {hasEdit && (
                  <Button
                    icon={<EditOutlined />}
                    size="small"
                    onClick={() => onEdit(record)}
                  />
                )}
                {hasViewHistory && (
                  <Button
                    icon={<HistoryOutlined />}
                    size="small"
                    onClick={() => {
                      setViewingWarehouseHistory(record.warehouse ?? null);
                      showAddProductHistory();
                    }}
                  />
                )}
                {hasDelete && (
                  <Popconfirm
                    title={t("buttons.confirm")}
                    onConfirm={() => onDelete(record)}
                    onCancel={() => null}
                    okText={t("buttons.clear")}
                    cancelText={t("buttons.cancel")}
                    okType="danger"
                  >
                    <Button icon={<DeleteOutlined />} danger size="small" />
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        )}
      </Table>

      {(hasEdit || hasAdd) && (
        <Modal
          {...modalAddWarehouseProps}
          title={
            upsertWarehouseValues
              ? t("products.warehouses.modalEditTitle")
              : t("products.warehouses.modalAddTitle")
          }
          okButtonProps={{
            disabled:
              !upsertWarehouseValues ||
              !upsertWarehouseValues.warehouse ||
              !upsertWarehouseValues.quantity,
          }}
          onCancel={() => {
            setUpsertWarehouseValues(null);
            closeAddWarehouse();
          }}
          onOk={() => {
            if (
              upsertWarehouseValues &&
              upsertWarehouseValues.warehouse &&
              upsertWarehouseValues.quantity
            ) {
              addOrEditWarehouse(upsertWarehouseValues);
            }
          }}
        >
          <Row gutter={[16, 24]} style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Col xs={24}>
              <WithLabel
                label={t("products.warehouses.fields.warehouse")}
                hasRequiredMark
                labelStyles={{ color: "#000000D9" }}
              >
                <Select
                  {...warehouseSelectProps}
                  options={filterWarehouseSelectOptions}
                  labelInValue
                  allowClear
                  value={{
                    value: upsertWarehouseValues?.warehouse?.id ?? "",
                    label: upsertWarehouseValues?.warehouse?.name ?? "",
                  }}
                  onChange={(item) => {
                    setUpsertWarehouseValues({
                      ...upsertWarehouseValues,
                      warehouse: item
                        ? {
                            id: item.value,
                            name: item.label,
                          }
                        : undefined,
                    });
                  }}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </WithLabel>
            </Col>

            <Col xs={24}>
              <WithLabel
                label={t("products.warehouses.fields.quantity")}
                hasRequiredMark
                labelStyles={{ color: "#000000D9" }}
              >
                <Input
                  type="number"
                  value={upsertWarehouseValues?.quantity}
                  onChange={(event) =>
                    setUpsertWarehouseValues({
                      ...upsertWarehouseValues,
                      quantity: Number(event.target.value),
                    })
                  }
                />
              </WithLabel>
            </Col>
          </Row>
        </Modal>
      )}

      {hasAddMore && (
        <AddMoreProducts
          warehouseList={warehouseList}
          modalProps={modalAddMoreProductsProps}
          close={closeAddMoreProducts}
          onSuccess={() => {
            invalidate({
              resource: API_RESOURCES.products,
              invalidates: ["all"],
            });
          }}
        />
      )}

      {hasViewHistory && viewingWarehouseHistory && (
        <AddProductHitory
          warehouse={viewingWarehouseHistory}
          modalProps={modalAddProductHistoryProps}
          close={() => {
            setViewingWarehouseHistory(null);
            closeAddProductHistory();
            replace(window.location.pathname);
          }}
        />
      )}
    </>
  );
};
