import { AuthBindings } from "@refinedev/core";
import { AxiosInstance } from "axios";
import { PATHS } from "config";
import { IUserIdentity } from "interfaces";
import Keycloak from "keycloak-js";
import { getUserRole } from "utils/identityHelper";

export const authProvider = (
  keycloak: Keycloak,
  httpClient: AxiosInstance
): AuthBindings => {
  return {
    login: async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const { to } = Object.fromEntries(urlSearchParams.entries());
        await keycloak.login({
          redirectUri: to ? `${window.location.origin}${to}` : undefined,
        });
        return {
          success: true,
          redirectTo: PATHS.splash,
        };
      } catch (error) {
        return {
          success: false,
          error: new Error("Login failed"),
        };
      }
    },
    logout: async () => {
      try {
        await keycloak.logout({
          redirectUri: window.location.origin,
        });
        return {
          success: true,
          redirectTo: PATHS.login,
        };
      } catch (error) {
        return {
          success: false,
          error: new Error("Logout failed"),
        };
      }
    },
    check: async () => {
      try {
        const { token } = keycloak;
        if (token) {
          httpClient.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };
          return {
            authenticated: true,
          };
        } else {
          return {
            authenticated: false,
            logout: true,
            redirectTo: PATHS.login,
            error: {
              message: "Check failed",
              name: "Token not found",
            },
          };
        }
      } catch (error) {
        return {
          authenticated: false,
          logout: true,
          redirectTo: PATHS.login,
          error: {
            message: "Check failed",
            name: "Token not found",
          },
        };
      }
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      if (keycloak?.tokenParsed) {
        const tokenParsed = keycloak?.tokenParsed;
        console.log({ tokenParsed });
        const userIdentity: IUserIdentity = {
          id: tokenParsed?.sub,
          name: tokenParsed.name,
          username: tokenParsed.preferred_username,
          phone: undefined, // TODO: update phone number
          email: tokenParsed.email,
          avatar: undefined, // TODO: update user avatar
          companyId: tokenParsed.company_id,
          role: getUserRole(tokenParsed),
        };
        return userIdentity;
      }
      return null;
    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
  };
};
