interface BulletNumberProps {
  number: number;
}

export const BulletNumber: React.FC<BulletNumberProps> = ({ number }) => {
  return (
    <span
      style={{
        width: 16,
        height: 16,
        fontSize: 11,
        borderRadius: "50%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#13C2C2",
        color: "#FFF",
      }}
    >
      {number}
    </span>
  );
};
