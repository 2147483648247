import { useApiUrl, useCustom } from "@refinedev/core";
import { Spin } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { RangeValue, SelectDateType } from "components/selectDate";
import {
  DEFAULT_DAYS_FOR_REPORTS,
  DEFAULT_MONTHS_FOR_REPORTS,
  DEFAULT_YEARS_FOR_REPORTS,
  SERVICE_MOBISALE,
} from "config";
import {
  rangeDayFilters,
  rangeMonthFilters,
  rangeYearFilters,
} from "utils/dayjsHelper";
import imgTrend1 from "../../assets/img_trend_1.svg";
import { BarChartWidget } from "../../components/chart/BarChartWidget";
import { useCompanyId } from "hooks/useCompanyId";
import { IOrderReportItem, IWithPaging } from "interfaces";

export const ItemOrdersChart: React.FC = () => {
  const { t } = useTranslation();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const apiUrl = useApiUrl();
  const companyId = useCompanyId();

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<IOrderReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/dashboard/order_no`,
    method: "get",
    queryOptions: {
      enabled: Boolean(rangeValue?.[0] && rangeValue?.[1] && type),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        companyId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return data?.data?.content?.map((item) => item.totalNo ?? 0) ?? [];
  }, [data]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("dashboard.barChart.title.itemSales")}
        image={imgTrend1}
        series={[
          {
            name: t("dashboard.barChart.bill"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("dashboard.barChart.bill")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
      />
    </Spin>
  );
};
