import { TitleProps as RefineTitleProps, useLink } from "@refinedev/core";
import React, { CSSProperties } from "react";

interface TitleProps extends RefineTitleProps {
  imgWidth?: number;
  imgCollapsedWidth?: number;
  imgMaxHeight?: number;
  imgCollapsedMaxHeight?: number;
  linkStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  title?: React.ReactNode;
}

export const Title: React.FC<TitleProps> = ({
  collapsed,
  imgWidth = 200,
  imgCollapsedWidth = 80,
  imgMaxHeight = 80,
  imgCollapsedMaxHeight = 80,
  linkStyle = {},
  imageStyle = {},
  title,
}) => {
  const Link = useLink();
  const to = window.location.pathname.includes("login")
    ? `${window.location.pathname}${window.location.search}`
    : "/";

  return (
    <Link
      to={to}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: window.location.pathname.includes("login")
          ? "default"
          : "pointer",
        ...linkStyle,
      }}
    >
      {collapsed ? (
        <img
          src={"/logo-collapsed.svg"}
          alt="logo"
          style={{
            width: imgCollapsedWidth,
            maxHeight: imgCollapsedMaxHeight,
            padding: "20px 24px 16px",
            ...imageStyle,
          }}
        />
      ) : (
        <img
          src={"/logo.svg"}
          alt="logo"
          style={{
            width: imgWidth,
            maxHeight: imgMaxHeight,
            padding: "20px 24px 16px",
            ...imageStyle,
          }}
        />
      )}
      {!collapsed ? title : null}
    </Link>
  );
};
