import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { SelectDate, SelectDateProps } from "components/selectDate";
import { CSSProperties } from "react";

interface BarChartWidgetProps extends SelectDateProps {
  title?: string;
  image?: string;
  series?: ApexOptions["series"];
  categories?: string[];
  yAxisTitle?: string;
  options?: ApexOptions;
  wrapperStyle?: CSSProperties;
}

export const BarChartWidget: React.FC<BarChartWidgetProps> = ({
  title,
  image,
  series = [],
  categories = [],
  yAxisTitle = "",
  options = {},
  type = "DAY",
  rangeValue = null,
  onTypeChange,
  onRangeValueChange,
  wrapperStyle = {},
}) => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 8,
        padding: 24,
        ...wrapperStyle,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 15,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
          <img src={image} alt={title} />
          <span style={{ fontWeight: 600, fontSize: 16 }}>{title}</span>
        </div>

        <SelectDate
          type={type}
          rangeValue={rangeValue}
          onTypeChange={onTypeChange}
          onRangeValueChange={onRangeValueChange}
        />
      </div>

      <ReactApexChart
        type="bar"
        width={"100%"}
        height={350}
        series={series}
        options={{
          chart: {
            type: "bar",
            fontFamily:
              "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
            height: 350,
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: "55%",
              // columnWidth: 50,
              borderRadius: 6,
              borderRadiusApplication: "end",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: categories,
          },
          yaxis: {
            title: {
              text: yAxisTitle,
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return val;
              },
            },
          },
        }}
        {...options}
      />
    </div>
  );
};
