import { CheckCircleOutlined } from "@ant-design/icons";

interface AmountCardProps {
  title?: string;
  amount?: number;
  bgImage?: string;
  color?: string;
}

export const AmountCard: React.FC<AmountCardProps> = ({
  title,
  amount,
  bgImage,
  color,
}) => {
  return (
    <div
      style={{
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: 24,
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: 45,
          height: 45,
          boxShadow: "0px 12px 17px -5px rgba(46, 118, 87, 0.3)",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckCircleOutlined style={{ color: "#ffffff", fontSize: 20 }} />
      </div>
      <div style={{ color: "#ffffff" }}>{title}</div>
      <div style={{ fontSize: 45, fontWeight: 700, color: "#ffffff" }}>
        {amount}
      </div>
    </div>
  );
};
