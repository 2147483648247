import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Tabs, TabsProps } from "antd";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { IEmployee } from "interfaces";
import React, { useState } from "react";
import { EmployeeCommon } from "../../components/profile";
import { PharRepReport } from "./private/PharRepReport";
import { PharRepSales } from "./private/PharRepSales";
import useUserRole from "hooks/useUserRole";

export const EmployeeShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const { hasAdmin } = useUserRole();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { queryResult } = useShow<IEmployee, HttpError>({
    meta,
  });

  const { data, isLoading, isFetching, isRefetching } = queryResult;

  const record = data?.data;

  const isProcessing = isLoading ?? isFetching ?? isRefetching;

  const [activeKey, setActiveKey] = useState("common");

  const tabItemCommon = {
    key: "common",
    label: t("employees.tabs.common.title"),
    children: <EmployeeCommon data={record} />,
  };

  const tabItemReports = {
    key: "reports",
    label: t("employees.tabs.reports.title"),
    children: record ? <PharRepReport employee={record} /> : null,
  };

  const tabItemSales = {
    key: "sales",
    label: t("employees.tabs.sales.title"),
    children: record ? <PharRepSales employee={record} /> : null,
  };

  const items: TabsProps["items"] = [
    tabItemCommon,
    ...(record?.role === "PHAR_REP" ? [tabItemReports, tabItemSales] : []),
  ];

  return (
    <>
      <Show
        isLoading={isProcessing}
        headerButtons={
          <>
            {/* <ListButton disabled={isProcessing} /> */}
            {resource?.canEdit &&
              activeKey === "common" &&
              (record?.role !== "ADMIN" || hasAdmin) && (
                <EditButton disabled={isProcessing} type="primary" />
              )}
            {resource?.meta?.canDelete && activeKey === "common" && (
              <DeleteButton
                disabled={isProcessing}
                meta={meta}
                onSuccess={() => {
                  list(API_RESOURCES.employees);
                }}
                errorNotification={(error) => {
                  return {
                    type: "error",
                    message:
                      (error as any)?.message ??
                      t("employees.notification.deleteError"),
                  };
                }}
                successNotification={{
                  type: "success",
                  message: t("employees.notification.deleteSuccess"),
                }}
              />
            )}
            {/* <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
          </>
        }
      >
        <Tabs items={items} activeKey={activeKey} onChange={setActiveKey} />
      </Show>
    </>
  );
};
