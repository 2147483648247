import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { useApiUrl, useCustomMutation, useNavigation } from "@refinedev/core";
import { Button, Popconfirm, Space } from "antd";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { ICustomer, ICustomerStatus } from "interfaces";
import { useTranslation } from "react-i18next";

interface ApproveRejectProps {
  customer?: ICustomer;
}

export const ApproveReject: React.FC<ApproveRejectProps> = ({ customer }) => {
  const { t } = useTranslation();

  const { hasAdmin, hasDirector } = useUserRole();

  const hasPermission = Boolean(hasAdmin ?? hasDirector);

  const allowApprove = customer?.status === "CREATED"; // || customer?.status === "REJECTED";

  const allowReject = customer?.status === "CREATED"; // || customer?.status === "APPROVED";

  const apiUrl = useApiUrl();

  const { mutate: updateArea } = useCustomMutation<ICustomer>();

  const { list } = useNavigation();

  const handleApproveReject = async (status: ICustomerStatus) => {
    updateArea(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/customers/action`,
        method: "post",
        values: {
          customerId: customer?.id,
          status,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("customers.notification.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("customers.notification.editSuccess"),
        },
      },
      {
        onSuccess() {
          list(API_RESOURCES.customers);
        },
      }
    );
  };

  return hasPermission && (allowApprove || allowReject) ? (
    <Space>
      <Popconfirm
        title={t("common.confirm_approve")}
        onConfirm={() => handleApproveReject("APPROVED")}
        onCancel={() => null}
        okText={t("buttons.agree")}
        cancelText={t("buttons.cancel")}
        okType="primary"
        disabled={!allowApprove}
      >
        <Button
          type="primary"
          icon={<CheckOutlined />}
          disabled={!allowApprove}
        >
          {t("common.approve")}
        </Button>
      </Popconfirm>

      <Popconfirm
        title={t("common.confirm_reject")}
        onConfirm={() => handleApproveReject("REJECTED")}
        onCancel={() => null}
        okText={t("buttons.agree")}
        cancelText={t("buttons.cancel")}
        okType="primary"
        disabled={!allowReject}
      >
        <Button danger icon={<StopOutlined />} disabled={!allowReject}>
          {t("common.reject")}
        </Button>
      </Popconfirm>
    </Space>
  ) : (
    <div />
  );
};
