import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Row, Space, Switch, Typography } from "antd";
import TextBox from "components/textBox";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { IUpdateWarehouseStatusTypes, IWarehouse } from "interfaces";
import React from "react";
import { ProductList } from "./private/ProductList";

export const WarehouseShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { queryResult } = useShow<IWarehouse, HttpError>({
    meta,
  });

  const { data, isLoading, isFetching, isRefetching } = queryResult;

  const record = data?.data;

  const isProcessing = isLoading ?? isFetching ?? isRefetching;

  const apiUrl = useApiUrl();

  const invalidate = useInvalidate();

  const { mutate: updateWarehouseStatus, isLoading: isUpdatingStatus } =
    useCustomMutation<IUpdateWarehouseStatusTypes>();

  const handleUpdateWarehouseStatus = (values: IUpdateWarehouseStatusTypes) => {
    updateWarehouseStatus(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/warehouses/action`,
        method: "post",
        values: {
          ...values,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("warehouses.notification.updateStatusError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("warehouses.notification.updateStatusSuccess"),
        },
      },
      {
        onSuccess() {
          invalidate({
            resource: API_RESOURCES.warehouses,
            invalidates: ["detail"],
            id: record?.id,
          });
        },
      }
    );
  };

  return (
    <Show
      title={
        <Space size="small" style={{ alignItems: "center" }}>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {t("warehouses.titles.show")}
          </Typography.Title>
          <Switch
            disabled
            loading={isUpdatingStatus}
            checked={record?.warehouseStatus === "ACTIVE"}
            onChange={(checked) => {
              handleUpdateWarehouseStatus({
                warehouseId: String(record?.id),
                status: checked ? "ACTIVE" : "INACTIVE",
              });
            }}
          />
        </Space>
      }
      isLoading={isProcessing}
      headerButtons={
        <>
          {/* <ListButton disabled={isProcessing} /> */}
          {resource?.canEdit && (
            <EditButton disabled={isProcessing} type="primary" />
          )}
          {resource?.meta?.canDelete && (
            <DeleteButton
              disabled={isProcessing}
              meta={meta}
              onSuccess={() => {
                list(API_RESOURCES.warehouses);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ?? t("warehouses.notification.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("warehouses.notification.deleteSuccess"),
              }}
            />
          )}
          {/* <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
        </>
      }
    >
      <Row gutter={[16, 24]}>
        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.name ?? "--"}
            label={t("warehouses.fields.name")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.address ?? "--"}
            label={t("warehouses.fields.address")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.manager?.name ?? "--"}
            label={t("warehouses.fields.manager")}
          />
        </Col>

        <Col xs={24}>
          <TextBox
            value={record?.description ?? "--"}
            label={t("warehouses.fields.description")}
            valueStyles={{
              minHeight: 88,
            }}
          />
        </Col>
      </Row>

      {record?.id && <ProductList warehouseId={record?.id} />}
    </Show>
  );
};
