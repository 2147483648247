import { useGetIdentity, useLogout, useTranslate } from "@refinedev/core";
import { Modal, Popover, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PATHS } from "config";
import { IUserIdentity } from "interfaces";

import { useCompanyId } from "hooks/useCompanyId";
import useRoleName from "hooks/useRoleName";
import { MenuPopoverContent } from "./MenuPopoverContent";
import { UserAvatar } from "./UserAvatar";

export const MenuPopover: React.FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const { data: user } = useGetIdentity<IUserIdentity>();

  const hasCompany = useCompanyId();

  const displayName = user?.name || user?.username || t("menu.popover.unknown");

  const roleName = useRoleName();

  const [popupOpen, setPopupOpen] = useState(false);

  const { mutate: logout } = useLogout<{ redirectPath: string }>();

  return (
    <Popover
      title=""
      trigger="click"
      showArrow={false}
      placement="bottomRight"
      onOpenChange={(visible) => setPopupOpen(visible)}
      open={popupOpen}
      content={
        <MenuPopoverContent
          name={displayName}
          role={roleName}
          avatar={user?.avatar}
          // onChangePasswordClicked={() => {
          //   if (window.location.pathname !== PATHS.updatePassword) {
          //     navigate(PATHS.updatePassword);
          //   }
          //   setPopupOpen(false);
          // }}
          onAccountInfoClicked={
            hasCompany
              ? () => {
                  if (window.location.pathname !== PATHS.accountMe) {
                    navigate(PATHS.accountMe);
                  }
                  setPopupOpen(false);
                }
              : undefined
          }
          onLogoutClicked={() => {
            Modal.confirm({
              icon: null,
              title: (
                <Typography.Title level={5}>
                  {t("dialogs.logout.title")}
                </Typography.Title>
              ),
              content: (
                <Typography.Text>{t("dialogs.logout.content")}</Typography.Text>
              ),
              okText: t("buttons.agree"),
              cancelText: t("buttons.cancel"),
              onOk() {
                logout({
                  redirectPath: PATHS.home,
                });
              },
            });
            setPopupOpen(false);
          }}
        />
      }
    >
      <div
        style={{
          cursor: "pointer",
          backgroundColor: "#f4f4f4",
          padding: "6px 12px",
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          gap: 8,
        }}
      >
        <UserAvatar size={32} src={user?.avatar} name={displayName} />
        <span style={{ lineHeight: 1.65, fontWeight: 600 }}>{displayName}</span>
        {/* <CaretDownFilled style={{ color: "#7F879E", marginLeft: 3 }} /> */}
      </div>
    </Popover>
  );
};
