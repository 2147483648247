import { useIsAuthenticated } from "@refinedev/core";
import { PATHS } from "config";
import { useCompanyId } from "hooks/useCompanyId";
import { Navigate } from "react-router-dom";

interface NoneCompanyGuardProps {
  children: React.ReactNode;
}

export const NoneCompanyGuard: React.FC<NoneCompanyGuardProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  const hasCompany = useCompanyId();

  if (!isAuthenticated) {
    return null;
  }

  if (hasCompany) {
    return <Navigate to={PATHS.splash} />;
  }

  return <>{children}</>;
};
