import { Create, useForm, useSelect } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import {
  API_RESOURCES,
  DEFAULT_TEXTAREA_MAX_LENGTH,
  SERVICE_MOBISALE,
} from "config";
import { IArea, IEmployee, IUserRoleCode, IWarehouse } from "interfaces";
import React from "react";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const AreaCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { formProps, saveButtonProps } = useForm<IArea, HttpError>({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("areas.notification.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("areas.notification.createSuccess"),
    },
  });

  // const {
  //   selectProps: {
  //     showSearch: showSearchArea,
  //     onSearch: onSearchArea,
  //     ...areaParentSelectProps
  //   },
  // } = useSelect<IArea, HttpError>({
  //   resource: API_RESOURCES.areas,
  //   meta,
  //   optionLabel: "name",
  //   pagination: {
  //     current: 1,
  //     pageSize: 99999, // use the large PageSize to get full
  //     mode: "server",
  //   },
  //   // onSearch: (value: string) => [
  //   //   {
  //   //     field: "q",
  //   //     operator: "eq",
  //   //     value,
  //   //   },
  //   // ],
  // });

  const {
    selectProps: {
      showSearch: showSearchWarehouse,
      onSearch: onSearchWarehouse,
      ...warehouseSelectProps
    },
  } = useSelect<IWarehouse, HttpError>({
    resource: API_RESOURCES.warehouses,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const {
    selectProps: {
      showSearch: showSearchEmployee,
      onSearch: onSearchEmployee,
      ...employeeSelectProps
    },
    queryResult: employeeQueryResult,
  } = useSelect<IEmployee, HttpError>({
    resource: API_RESOURCES.employees,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    filters: [
      {
        field: "role",
        operator: "eq",
        value: "PHAR_REP" as IUserRoleCode,
      },
    ],
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const employeeSelectOptions = employeeQueryResult?.data?.data
    ?.filter((employee) => !employee.areaId && !employee.area)
    .map((employee) => ({
      value: employee?.id,
      label: employee?.name,
    }));

  const parentId = Form.useWatch("parentId", formProps.form);
  // const warehouseId = Form.useWatch("warehouseId", formProps.form);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("areas.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("areas.errors.nameRequired"),
                },
              ]}
            >
              <Input placeholder={t("areas.placeholders.name")} />
            </Form.Item>
          </Col>

          {/* <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("areas.fields.parent")} name={["parentId"]}>
              <Select
                allowClear
                {...areaParentSelectProps}
                placeholder={t("areas.placeholders.parent")}
                disabled={Boolean(warehouseId)}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col> */}

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("areas.fields.warehouse")}
              name={["warehouseId"]}
              rules={[
                {
                  required: true,
                  message: t("areas.errors.warehouseRequired"),
                },
              ]}
            >
              <Select
                allowClear
                {...warehouseSelectProps}
                placeholder={t("areas.placeholders.warehouse")}
                disabled={Boolean(parentId)}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("areas.fields.employee")} name={["employee"]}>
              <Select
                allowClear
                {...employeeSelectProps}
                options={employeeSelectOptions}
                placeholder={t("areas.placeholders.employee")}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("areas.fields.description")}
              name={["description"]}
            >
              <Input.TextArea
                rows={3}
                placeholder={t("areas.placeholders.description")}
                maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
