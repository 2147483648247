import { EditOutlined } from "@ant-design/icons";
import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
  useModalForm,
  useSelect,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { DatePicker } from "components/datepicker";
import UploadImage from "components/upload/uploadImage";
import {
  API_RESOURCES,
  DATE_FORMAT,
  GENDER_LIST,
  MONEY_UNIT,
  PASSWORD_PLACEHOLDER,
  PERCENTAGE_UNIT,
  ROLE_LIST,
  SERVICE_MOBISALE,
} from "config";
import dayjs from "dayjs";
import { useCompanyId } from "hooks/useCompanyId";
import { IArea, IEmployee, IUserRoleCode, IWarehouse } from "interfaces";
import React from "react";
import { getFileUrlsFromEvent, getFileValueProps } from "utils/formHelper";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const EmployeeEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const companyId = useCompanyId();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { formProps, saveButtonProps, queryResult } = useForm<
    IEmployee,
    HttpError
  >({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("employees.notification.editError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("employees.notification.editSuccess"),
    },
  });

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  const {
    selectProps: {
      showSearch: showSearchArea,
      onSearch: onSearchArea,
      ...areaSelectProps
    },
    queryResult: areaQueryResult,
  } = useSelect<IArea, HttpError>({
    resource: API_RESOURCES.areas,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const areaSelectOptions = areaQueryResult?.data?.data
    ?.filter(
      (area) =>
        !area.employee || area.employee?.id === queryResult?.data?.data.id
    )
    .map((area) => ({
      value: area?.id,
      label: area?.name,
    }));

  const {
    selectProps: {
      showSearch: showSearchWarehouse,
      onSearch: onSearchWarehouse,
      ...warehouseSelectProps
    },
    queryResult: warehouseQueryResult,
  } = useSelect<IWarehouse, HttpError>({
    resource: API_RESOURCES.warehouses,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const warehouseSelectOptions = warehouseQueryResult?.data?.data
    ?.filter(
      (warehouse) =>
        !warehouse.manager ||
        warehouse.manager?.id === queryResult?.data?.data.id
    )
    .map((warehouse) => ({
      value: warehouse?.id,
      label: warehouse?.name,
    }));

  const roleCode = Form.useWatch("role", formProps.form) as IUserRoleCode;

  const formItemRole = (
    <Form.Item
      label={t("employees.fields.role")}
      name={["role"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.roleRequired"),
        },
      ]}
    >
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          fuzzySearch(
            toLowerCaseNonAccentVietnamese(String(option?.children ?? "")),
            toLowerCaseNonAccentVietnamese(input)
          )
        }
        placeholder={t("employees.placeholders.role")}
        disabled
      >
        {ROLE_LIST(t)
          .filter((role) => role.isCreatable)
          .map((role) => (
            <Select.Option key={role.value} value={role.value}>
              {role.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );

  const formItemUserName = (
    <Form.Item
      label={t("employees.fields.username")}
      name={["username"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.usernameRequired"),
        },
      ]}
    >
      <Input
        autoComplete="new-username"
        placeholder={t("employees.placeholders.username")}
      />
    </Form.Item>
  );

  const formItemFirstName = (
    <Form.Item
      label={t("employees.fields.firstName")}
      name={["firstName"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.firstNameRequired"),
        },
      ]}
    >
      <Input placeholder={t("employees.placeholders.firstName")} />
    </Form.Item>
  );

  const formItemLastName = (
    <Form.Item
      label={t("employees.fields.lastName")}
      name={["lastName"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.lastNameRequired"),
        },
      ]}
    >
      <Input placeholder={t("employees.placeholders.lastName")} />
    </Form.Item>
  );

  const formItemPhone = (
    <Form.Item
      label={t("employees.fields.phone")}
      name={["phone"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.phoneRequired"),
        },
        {
          validator: (_, value) => {
            if (!value || isValidPhoneNumb(value)) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(t("employees.errors.phoneInvalid"))
            );
          },
        },
      ]}
    >
      <Input placeholder={t("employees.placeholders.phone")} />
    </Form.Item>
  );

  const formItemEmail = (
    <Form.Item
      label={t("employees.fields.email")}
      name={["email"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.emailRequired"),
        },
        {
          type: "email",
          message: t("employees.errors.emailInvalid"),
        },
      ]}
    >
      <Input placeholder={t("employees.placeholders.email")} />
    </Form.Item>
  );

  const formItemDoB = (
    <Form.Item
      label={t("employees.fields.dob")}
      name={["dob"]}
      getValueProps={(value) => ({
        value: value ? dayjs(value) : "",
      })}
    >
      <DatePicker
        format={DATE_FORMAT}
        style={{ width: "100%" }}
        placeholder={t("employees.placeholders.dob")}
      />
    </Form.Item>
  );

  const formItemGender = (
    <Form.Item label={t("employees.fields.gender")} name={["gender"]}>
      <Select
        options={GENDER_LIST(t)}
        placeholder={t("employees.placeholders.gender")}
      />
    </Form.Item>
  );

  const formItemArea = (
    <Form.Item label={t("employees.fields.area")} name={["area", "id"]}>
      <Select
        allowClear
        placeholder={t("employees.placeholders.area")}
        {...areaSelectProps}
        options={areaSelectOptions}
        showSearch
        filterOption={(input, option) =>
          fuzzySearch(
            toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
            toLowerCaseNonAccentVietnamese(input)
          )
        }
      />
    </Form.Item>
  );

  const formItemWarehouse = (
    <Form.Item
      label={t("employees.fields.warehouse")}
      name={["warehouse", "id"]}
    >
      <Select
        allowClear
        placeholder={t("employees.placeholders.warehouse")}
        {...warehouseSelectProps}
        options={warehouseSelectOptions}
        showSearch
        filterOption={(input, option) =>
          fuzzySearch(
            toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
            toLowerCaseNonAccentVietnamese(input)
          )
        }
      />
    </Form.Item>
  );

  const formItemSalary = (
    <Form.Item label={t("employees.fields.salary")} name={["salary"]}>
      <Input
        placeholder={t("employees.placeholders.salary")}
        type="number"
        addonAfter={MONEY_UNIT}
      />
    </Form.Item>
  );

  const formItemCommissionRate = (
    <Form.Item
      label={t("employees.fields.commissionRate")}
      name={["commissionRate"]}
    >
      <Input
        placeholder={t("employees.placeholders.commissionRate")}
        type="number"
        addonAfter={PERCENTAGE_UNIT}
      />
    </Form.Item>
  );

  const formItemMonthlySalesTarget = (
    <Form.Item
      label={t("employees.fields.monthlySalesTarget")}
      name={["monthlySalesTarget"]}
    >
      <Input
        placeholder={t("employees.placeholders.monthlySalesTarget")}
        type="number"
        addonAfter={MONEY_UNIT}
      />
    </Form.Item>
  );

  const formItemUploadImage = (
    <Col xs={24}>
      <Form.Item
        label={t("employees.fields.avatar")}
        name="avatar"
        getValueFromEvent={(e) => {
          const fileUrls = getFileUrlsFromEvent(e);
          return fileUrls.length < 1 ? "" : fileUrls[0];
        }}
        getValueProps={(value) => {
          const imgs = getFileValueProps(value, t(`employees.fields.avatar`));
          return {
            defaultFileList: imgs,
          };
        }}
      >
        <UploadImage maxCount={1} />
      </Form.Item>
    </Col>
  );

  const formItemPassword = (
    <Form.Item
      label={t("employees.fields.password")}
      name={["password"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.passwordRequired"),
        },
      ]}
    >
      <Input.Password
        placeholder={PASSWORD_PLACEHOLDER}
        autoComplete="new-password"
      />
    </Form.Item>
  );

  const formItemConfirmPassword = (
    <Form.Item
      label={t("employees.fields.confirmPassword")}
      name={["confirmPassword"]}
      dependencies={["password"]}
      rules={[
        {
          required: true,
          message: t("employees.errors.confirmPasswordRequired"),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(t("employees.errors.confirmPasswordNotMatch"))
            );
          },
        }),
      ]}
    >
      <Input.Password
        placeholder={PASSWORD_PLACEHOLDER}
        autoComplete="new-password"
      />
    </Form.Item>
  );

  const {
    modalProps,
    formProps: { onFinish: _, form: modalForm, ...restModalFormProps },
    show: showChangePassword,
    close: closeChangePassword,
  } = useModalForm({
    action: "edit",
    meta,
  });

  const { mutate: updatePassword } = useCustomMutation<IEmployee>();

  const apiUrl = useApiUrl();

  const handleChangePassword = async ({ password }: IEmployee) => {
    updatePassword(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/employees/${queryResult?.data?.data?.id}/change-password`,
        method: "patch",
        values: {
          password,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ??
              t("employees.change_password.notification.changePassError"),
          };
        },
        successNotification: {
          type: "success",
          message: t(
            "employees.change_password.notification.changePassSuccess"
          ),
        },
      },
      {
        onSuccess() {
          closeChangePassword();
        },
      }
    );
  };

  // TODO: workaround to update employees,
  // Remove these code later after BE update API update
  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: updateEmployee } = useCustomMutation<IEmployee>();

  const handleUpdateEmployee = async (values: IEmployee) => {
    updateEmployee(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/employees/${queryResult?.data?.data.id}`,
        method: "put",
        values: {
          ...values,
          id: queryResult?.data?.data?.id,
          warehouseId: values?.warehouse?.id,
          areaId: values?.area?.id,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("employees.notification.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("employees.notification.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.employees);
          }, 100);
        },
      }
    );
  };
  //

  return (
    <>
      <Edit
        headerButtons={
          <>
            <Button
              // type="primary"
              onClick={() => showChangePassword()}
              icon={<EditOutlined />}
            >
              {t("employees.change_password.action")}
            </Button>
            <ShowButton />
            {/* <ListButton disabled={isProcessing} />
          <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
          </>
        }
        footerButtons={
          <>
            {resource?.meta?.canDelete && (
              <DeleteButton
                disabled={isProcessing}
                meta={meta}
                onSuccess={() => {
                  list(API_RESOURCES.employees);
                }}
                errorNotification={(error) => {
                  return {
                    type: "error",
                    message:
                      (error as any)?.message ??
                      t("employees.notification.deleteError"),
                  };
                }}
                successNotification={{
                  type: "success",
                  message: t("employees.notification.deleteSuccess"),
                }}
              />
            )}
            <SaveButton disabled={isProcessing} {...saveButtonProps} />
          </>
        }
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            ...formProps.initialValues,
            company: {
              id: companyId,
            },
          }}
          onFinish={(values) => handleUpdateEmployee(values)}
        >
          <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
            <Col xs={24} md={12} lg={8}>
              {formItemRole}
            </Col>
          </Row>

          <Row gutter={[16, 24]}>
            {roleCode === "ADMIN" && (
              <>
                <Col xs={24} md={12} lg={8}>
                  {formItemUserName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemFirstName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemLastName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemPhone}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemEmail}
                </Col>
              </>
            )}

            {roleCode === "DIRECTOR" && (
              <>
                <Col xs={24} md={12} lg={8}>
                  {formItemUserName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemFirstName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemLastName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemPhone}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemEmail}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemDoB}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemGender}
                </Col>

                <Col xs={24}>{formItemUploadImage}</Col>
              </>
            )}

            {roleCode === "INV_MANAGEMENT" && (
              <>
                <Col xs={24} md={12} lg={8}>
                  {formItemUserName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemFirstName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemLastName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemPhone}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemEmail}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemWarehouse}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemDoB}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemGender}
                </Col>

                <Col xs={24}>{formItemUploadImage}</Col>
              </>
            )}

            {roleCode === "PHAR_REP" && (
              <>
                <Col xs={24} md={12} lg={8}>
                  {formItemUserName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemFirstName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemLastName}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemPhone}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemEmail}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemArea}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemDoB}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemGender}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemSalary}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemCommissionRate}
                </Col>

                <Col xs={24} md={12} lg={8}>
                  {formItemMonthlySalesTarget}
                </Col>

                <Col xs={24}>{formItemUploadImage}</Col>
              </>
            )}

            <Form.Item name={"companyId"}>
              <Input hidden />
            </Form.Item>
          </Row>
        </Form>
      </Edit>

      <Modal
        {...modalProps}
        title={t("employees.change_password.title")}
        width={500}
      >
        <Form
          {...restModalFormProps}
          layout="vertical"
          form={modalForm}
          onFinish={() =>
            modalForm
              ?.validateFields()
              .then((values) => handleChangePassword(values))
              .catch((error) => console.log("error", error))
          }
        >
          <Row gutter={16}>
            <Col xs={24}>{formItemPassword}</Col>
            <Col xs={24}>{formItemConfirmPassword}</Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
