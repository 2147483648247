import { DownloadOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Button, Form, Image, Input, Space, Table, Typography } from "antd";
import { SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IProduct } from "interfaces";
import React from "react";
import { formatCurrency } from "utils/numberHelper";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps, searchFormProps } = useTable<IProduct, HttpError>({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    meta,
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const apiUrl = useApiUrl();

  const { isRefetching: isExportingProducts, refetch: exportProducts } =
    useCustom({
      url: `${apiUrl}/${SERVICE_MOBISALE}/export/products`,
      method: "get",
      queryOptions: {
        enabled: false,
        retry: 0,
      },
      errorNotification(error) {
        return {
          type: "error",
          message: error?.message ?? t("products.notification.exportError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("products.notification.exportSuccess"),
      },
    });

  const handleExport = () => {
    exportProducts();
  };

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchWarehouse } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  const { hasAdmin, hasDirector } = useUserRole();

  return (
    <List
      headerButtons={
        <>
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q" style={{ marginBottom: 0 }}>
              <Input.Search
                allowClear
                placeholder={t("placeholders.input_search")}
                style={{ width: 230 }}
                onSearch={() => searchWarehouse()}
              />
            </Form.Item>
          </Form>

          {(hasAdmin || hasDirector) && (
            <Button
              icon={<DownloadOutlined />}
              shape="circle"
              onClick={handleExport}
              loading={isExportingProducts}
              disabled={isExportingProducts}
            />
          )}

          {resource?.canCreate && (hasAdmin || hasDirector) && <CreateButton />}
        </>
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("products.fields.idx")} />
        <Table.Column dataIndex="name" title={t("products.fields.name")} />
        <Table.Column<IProduct>
          dataIndex="image"
          title={t("products.fields.images")}
          render={(_, record) =>
            record.images && record.images.length > 0 ? (
              <Image
                style={{ maxWidth: 64, maxHeight: 64 }}
                src={record.images[0]}
              />
            ) : null
          }
        />
        <Table.Column
          width={300}
          dataIndex="description"
          title={t("products.fields.description")}
          render={(description) => (
            <Typography.Paragraph ellipsis={{ rows: 3 }}>
              {description}
            </Typography.Paragraph>
          )}
        />
        <Table.Column
          dataIndex="price"
          title={t("products.fields.price_unit")}
          render={(price) => formatCurrency(price)}
        />
        <Table.Column<IProduct>
          dataIndex="summary-totalSoldPerWarehouses"
          title={t("products.fields.totalSoldPerWarehouses")}
          render={(_, record) =>
            record.totalSoldPerWarehouses?.reduce((accum, curr) => {
              return accum + (curr.quantity ?? 0);
            }, 0)
          }
        />
        <Table.Column<IProduct>
          dataIndex="summary-inventoryPerWarehouses"
          title={t("products.fields.inventoryPerWarehouses")}
          render={(_, record) =>
            record.inventoryPerWarehouses?.reduce((accum, curr) => {
              return accum + (curr.quantity ?? 0);
            }, 0)
          }
        />
        <Table.Column
          title={t("products.fields.actions")}
          dataIndex="actions"
          render={(_, record: IProduct) => (
            <Space>
              {resource?.canShow && (
                <ShowButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.canEdit && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.meta?.canDelete && (
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  meta={meta}
                  errorNotification={(error) => {
                    return {
                      type: "error",
                      message:
                        (error as any)?.message ??
                        t("products.notification.deleteError"),
                    };
                  }}
                  successNotification={{
                    type: "success",
                    message: t("products.notification.deleteSuccess"),
                  }}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
