import { useApiUrl, useCustomMutation, useTranslate } from "@refinedev/core";
import { Form, FormInstance, Input, Modal, ModalProps, Table } from "antd";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { SERVICE_MOBISALE } from "config";
import {
  IProductWarehouseQuanity,
  IProductWarehouseQuanityMore,
} from "interfaces";

interface AddMoreProductsProps {
  warehouseList: IProductWarehouseQuanity[];
  modalProps: ModalProps;
  close: () => void;
  onSuccess?: () => void;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IProductWarehouseQuanityMore;
  record: IProductWarehouseQuanityMore;
  handleSave: (record: IProductWarehouseQuanityMore) => void;
  disabled?: boolean;
}

const EditableContext = createContext<FormInstance<any> | null>(null);

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  disabled,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (!form || !record) return;

    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  }, [form, dataIndex, record]);

  const save = async () => {
    if (!form) return;

    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const childNode = editable ? (
    <Form.Item
      style={{ margin: 0 }}
      name={dataIndex}
      // rules={[
      //   {
      //     required: true,
      //     message: `${title} là bắt buộc`,
      //   },
      //   {
      //     validator: (_, value) => {
      //       if (!value || Number(value) >= 0) {
      //         return Promise.resolve();
      //       }
      //       return Promise.reject(
      //         new Error(t("products.addMoreProducts.errors.moreInvalid"))
      //       );
      //     },
      //   },
      // ]}
    >
      <Input
        type="number"
        onPressEnter={save}
        onBlur={save}
        min={0}
        disabled={disabled}
      />
    </Form.Item>
  ) : (
    children
  );

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const AddMoreProducts: React.FC<AddMoreProductsProps> = ({
  warehouseList,
  modalProps,
  close,
  onSuccess,
}) => {
  const t = useTranslate();

  const { id } = useParams();

  const [warehouseListMore, setWarehouseListMore] = useState<
    IProductWarehouseQuanityMore[]
  >([]);

  const resetWarehouseListMore = useCallback(() => {
    setWarehouseListMore(
      warehouseList.map((item, index) => {
        const productMore: IProductWarehouseQuanityMore = {
          ...item,
          more: undefined,
          idx: index + 1,
        };
        return productMore;
      })
    );
  }, [warehouseList]);

  useEffect(() => {
    resetWarehouseListMore();
  }, [resetWarehouseListMore]);

  const apiUrl = useApiUrl();

  const { mutate: addMoreProducts, isLoading: isAddingMoreProducts } =
    useCustomMutation<IProductWarehouseQuanityMore>();

  const handleAddMoreProducts = () => {
    addMoreProducts(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/import_warehouses/create`,
        method: "post",
        values: {
          productId: id,
          items: warehouseListMore
            .filter((item) => item.more && item.more > 0)
            .map((item) => ({
              warehouseId: item.warehouse?.id ?? "",
              quantity: item.more,
            })),
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ??
              t("products.addMoreProducts.notification.addMoreError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("products.addMoreProducts.notification.addMoreSuccess"),
        },
      },
      {
        onSuccess() {
          close();
          resetWarehouseListMore();
          onSuccess?.();
        },
      }
    );
  };

  const handleClose = () => {
    close();
    resetWarehouseListMore();
  };

  const handleSaveCell = (row: IProductWarehouseQuanityMore) => {
    const newData = [...warehouseListMore];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      more: Number(row.more),
    });
    setWarehouseListMore(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: t("products.addMoreProducts.fields.idx"),
      dataIndex: "idx",
    },
    {
      title: t("products.addMoreProducts.fields.warehouse"),
      dataIndex: "warehouse",
      render: (warehouse) => warehouse?.name ?? "",
    },
    {
      title: t("products.addMoreProducts.fields.quantity"),
      dataIndex: "quantity",
    },
    {
      title: t("products.addMoreProducts.fields.more"),
      dataIndex: "more",
      editable: true,
      width: 200,
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IProductWarehouseQuanityMore) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSaveCell,
        disabled: isAddingMoreProducts,
      }),
    };
  });

  return (
    <Modal
      {...modalProps}
      title={t("products.addMoreProducts.title")}
      width={768}
      okButtonProps={{
        disabled: !warehouseListMore.find((item) => item.more && item.more > 0),
        loading: isAddingMoreProducts,
      }}
      onCancel={handleClose}
      onOk={() => handleAddMoreProducts()}
      okText={t("buttons.save")}
    >
      <Table
        style={{ marginTop: 30, marginBottom: 30 }}
        pagination={false}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={warehouseListMore}
        columns={columns as ColumnTypes}
      />
    </Modal>
  );
};
