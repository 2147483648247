import { useGetIdentity, useTranslate } from "@refinedev/core";
import { ROLE_LIST } from "config";
import { IUserIdentity } from "interfaces";
import { useMemo } from "react";

export default function useRoleName() {
  const t = useTranslate();

  const { data: user } = useGetIdentity<IUserIdentity>();

  const roleName = useMemo(() => {
    if (!user?.role) return t("menu.popover.unknown");

    return ROLE_LIST(t).find((item) => item.value === user?.role)?.label
  }, [user, t]);

  return roleName;
}
