import { HttpError, useList } from "@refinedev/core";
import { Grid, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { IArea, IEmployee, IProduct } from "interfaces";
import { AmountCard } from "./AmountCard";

import imgCardBg1 from "../../assets/img_card_bg_1.png";
import imgCardBg2 from "../../assets/img_card_bg_2.png";
import imgCardBg3 from "../../assets/img_card_bg_3.png";

export const SummaryWidget: React.FC = () => {
  const { t } = useTranslation();

  const breakpoint = Grid.useBreakpoint();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { data: productsData, isLoading: isLoadingProducts } = useList<
    IProduct,
    HttpError
  >({
    resource: API_RESOURCES.products,
    meta,
    pagination: {
      current: 1,
      pageSize: 1,
      mode: "server",
    },
  });

  const { data: employeesData, isLoading: isLoadingEmployees } = useList<
    IEmployee,
    HttpError
  >({
    resource: API_RESOURCES.employees,
    meta,
    pagination: {
      current: 1,
      pageSize: 1,
      mode: "server",
    },
  });

  const { data: areasData, isLoading: isLoadingAreas } = useList<
    IArea,
    HttpError
  >({
    resource: API_RESOURCES.areas,
    meta,
    pagination: {
      current: 1,
      pageSize: 1,
      mode: "server",
    },
  });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: breakpoint.xs ? "1fr" : "1fr 1fr 1fr",
        gap: 24,
      }}
    >
      <Spin spinning={isLoadingProducts}>
        <AmountCard
          title={t("dashboard.itemAmount")}
          amount={productsData?.total ?? 0}
          color="#5072CA"
          bgImage={imgCardBg1}
        />
      </Spin>

      <Spin spinning={isLoadingEmployees}>
        <AmountCard
          title={t("dashboard.staffAmount")}
          amount={employeesData?.total ?? 0}
          color="#F4943A"
          bgImage={imgCardBg2}
        />
      </Spin>

      <Spin spinning={isLoadingAreas}>
        <AmountCard
          title={t("dashboard.areaAmount")}
          amount={areasData?.total ?? 0}
          color="#31B099"
          bgImage={imgCardBg3}
        />
      </Spin>
    </div>
  );
};
