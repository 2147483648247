import { useKeycloak } from "@react-keycloak/web";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import {
  Authenticated,
  // GitHubBanner,
  Refine,
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { ErrorComponent, notificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";

// import {
//   BlogPostCreate,
//   BlogPostEdit,
//   BlogPostList,
//   BlogPostShow,
// } from "pages/blog-posts";
// import {
//   BlogPostCreate2,
//   BlogPostEdit2,
//   BlogPostList2,
//   BlogPostShow2,
// } from "pages/blog-posts-2";
// import {
//   CategoryCreate,
//   CategoryEdit,
//   CategoryList,
//   CategoryShow,
// } from "pages/categories";

import AccountInfo from "pages/accountInfo";
import Dashboard from "pages/dashboard";
// import ForgotPassword from "pages/forgotPassword";
// import Login from "pages/login";
// import Register from "pages/register";
import Splash from "pages/splash";
import UpdatePassword from "pages/updatePassword";

import { AreaCreate, AreaEdit, AreaList, AreaShow } from "pages/areas";
import {
  CustomerCreate,
  CustomerEdit,
  CustomerList,
  CustomerShow,
} from "pages/customers";
import { LoginWithKeycloakAuto } from "pages/login";
import { OrderCreate, OrderEdit, OrderList, OrderShow } from "pages/orders";
import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
} from "pages/products";
import {
  WarehouseCreate,
  WarehouseEdit,
  WarehouseList,
  WarehouseShow,
} from "pages/warehouses";

import {
  API_RESOURCES,
  HOST_API,
  SITE_CANONICAL,
  SITE_DESCRIPTION,
  SITE_THEME_COLOR,
  SITE_TITLE,
} from "config";

import {
  ContainerOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  SafetyOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import axiosInstance from "axiosInstance";
import { CreateCompanyLayout } from "components/layout/layout";
import LoadingScreen from "components/loadingScreen";
import { CompanyGuard } from "guards";
import { NoneCompanyGuard } from "guards/NoneCompanyGuard";
import { CreateCompany } from "pages/company";
import {
  EmployeeCreate,
  EmployeeEdit,
  EmployeeList,
  EmployeeShow,
} from "pages/employees";
import { AuthenticatedLayout } from "./components/layout";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { accessControlProvider, authProvider, dataProvider } from "./providers";

function App() {
  const { initialized, keycloak } = useKeycloak();

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  if (!initialized) {
    return <LoadingScreen />;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <html lang={i18n.language} />
        <title>{SITE_TITLE}</title>
        <meta name="description" content={SITE_DESCRIPTION} />
        <meta name="theme-color" content={SITE_THEME_COLOR} />
        <link rel="canonical" href={SITE_CANONICAL} />
      </Helmet>

      <BrowserRouter>
        {/* <GitHubBanner /> */}

        <RefineKbarProvider>
          <ColorModeContextProvider>
            <Refine
              dataProvider={dataProvider(HOST_API, axiosInstance)}
              notificationProvider={notificationProvider}
              accessControlProvider={accessControlProvider(
                keycloak?.tokenParsed
              )}
              routerProvider={routerBindings}
              authProvider={authProvider(keycloak, axiosInstance)}
              i18nProvider={i18nProvider}
              resources={[
                {
                  name: API_RESOURCES.splash,
                  list: "/splash",
                  meta: {
                    icon: <SafetyOutlined />,
                  },
                },
                {
                  name: API_RESOURCES.dashboard,
                  list: "/dashboard",
                  meta: {
                    icon: <DashboardOutlined />,
                  },
                },
                {
                  name: API_RESOURCES.orders,
                  list: "/orders",
                  create: "/orders/create",
                  edit: "/orders/edit/:id",
                  show: "/orders/show/:id",
                  meta: {
                    canDelete: true,
                    icon: <ShoppingOutlined />,
                  },
                },
                {
                  name: API_RESOURCES.employees,
                  list: "/employees",
                  create: "/employees/create",
                  edit: "/employees/edit/:id",
                  show: "/employees/show/:id",
                  meta: {
                    canDelete: false,
                    icon: <UserOutlined />,
                  },
                },
                {
                  name: API_RESOURCES.customers,
                  list: "/customers",
                  create: "/customers/create",
                  edit: "/customers/edit/:id",
                  show: "/customers/show/:id",
                  meta: {
                    canDelete: true,
                    icon: <SolutionOutlined />,
                  },
                },
                {
                  name: API_RESOURCES.products,
                  list: "/products",
                  create: "/products/create",
                  edit: "/products/edit/:id",
                  show: "/products/show/:id",
                  meta: {
                    canDelete: true,
                    icon: <ContainerOutlined />,
                  },
                },
                {
                  name: API_RESOURCES.areas,
                  list: "/areas",
                  create: "/areas/create",
                  edit: "/areas/edit/:id",
                  show: "/areas/show/:id",
                  meta: {
                    canDelete: true,
                    icon: <EnvironmentOutlined />,
                  },
                },
                {
                  name: API_RESOURCES.warehouses,
                  list: "/warehouses",
                  create: "/warehouses/create",
                  edit: "/warehouses/edit/:id",
                  show: "/warehouses/show/:id",
                  meta: {
                    canDelete: true,
                    icon: <HomeOutlined />,
                  },
                },
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <AuthenticatedLayout>
                        <CompanyGuard>
                          <Outlet />
                        </CompanyGuard>
                      </AuthenticatedLayout>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={
                      // <NavigateToResource resource={API_RESOURCES.blogPosts} />
                      <Navigate to="/splash" />
                    }
                  />
                  <Route path="/splash" element={<Splash />} />
                  <Route path="/account/me" element={<AccountInfo />} />
                  <Route path="/update-password" element={<UpdatePassword />} />
                  <Route path="/dashboard">
                    <Route index element={<Dashboard />} />
                  </Route>
                  <Route path="/employees">
                    <Route index element={<EmployeeList />} />
                    <Route path="create" element={<EmployeeCreate />} />
                    <Route path="edit/:id" element={<EmployeeEdit />} />
                    <Route path="show/:id" element={<EmployeeShow />} />
                  </Route>
                  <Route path="/areas">
                    <Route index element={<AreaList />} />
                    <Route path="create" element={<AreaCreate />} />
                    <Route path="edit/:id" element={<AreaEdit />} />
                    <Route path="show/:id" element={<AreaShow />} />
                  </Route>
                  <Route path="/products">
                    <Route index element={<ProductList />} />
                    <Route path="create" element={<ProductCreate />} />
                    <Route path="edit/:id" element={<ProductEdit />} />
                    <Route path="show/:id" element={<ProductShow />} />
                  </Route>
                  <Route path="/warehouses">
                    <Route index element={<WarehouseList />} />
                    <Route path="create" element={<WarehouseCreate />} />
                    <Route path="edit/:id" element={<WarehouseEdit />} />
                    <Route path="show/:id" element={<WarehouseShow />} />
                  </Route>
                  <Route path="/orders">
                    <Route index element={<OrderList />} />
                    <Route path="create" element={<OrderCreate />} />
                    <Route path="edit/:id" element={<OrderEdit />} />
                    <Route path="show/:id" element={<OrderShow />} />
                  </Route>
                  <Route path="/customers">
                    <Route index element={<CustomerList />} />
                    <Route path="create" element={<CustomerCreate />} />
                    <Route path="edit/:id" element={<CustomerEdit />} />
                    <Route path="show/:id" element={<CustomerShow />} />
                  </Route>
                  {/* <Route path="/blog_posts">
                    <Route index element={<BlogPostList />} />
                    <Route path="create" element={<BlogPostCreate />} />
                    <Route path="edit/:id" element={<BlogPostEdit />} />
                    <Route path="show/:id" element={<BlogPostShow />} />
                  </Route>
                  <Route path="/categories">
                    <Route index element={<CategoryList />} />
                    <Route path="create" element={<CategoryCreate />} />
                    <Route path="edit/:id" element={<CategoryEdit />} />
                    <Route path="show/:id" element={<CategoryShow />} />
                  </Route>
                  <Route path="/blog_posts_2">
                    <Route index element={<BlogPostList2 />} />
                    <Route path="create" element={<BlogPostCreate2 />} />
                    <Route path="edit/:id" element={<BlogPostEdit2 />} />
                    <Route path="show/:id" element={<BlogPostShow2 />} />
                  </Route> */}
                </Route>

                <Route
                  element={
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <CreateCompanyLayout>
                        <NoneCompanyGuard>
                          <Outlet />
                        </NoneCompanyGuard>
                      </CreateCompanyLayout>
                    </Authenticated>
                  }
                >
                  <Route path="/create-company" element={<CreateCompany />} />
                </Route>

                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<LoginWithKeycloakAuto />} />
                  {/* <Route path="/register" element={<Register />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                </Route>

                <Route
                  element={
                    <Authenticated>
                      <AuthenticatedLayout>
                        <Outlet />
                      </AuthenticatedLayout>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
            </Refine>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
