export function randomInt(min: number, max: number) {
  return Math.floor(min + Math.random() * (max - min));
}

export function formatCurrency(number: number) {
  return number.toLocaleString("vi");
}

export function toMillions(arr: number[]) {
  return arr.map((item) => item / 1000000);
}
