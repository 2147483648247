import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Image, Row, Space } from "antd";
import TextBox from "components/textBox";
import WithLabel from "components/withLabel";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IProduct } from "interfaces";
import React, { useMemo } from "react";
import { formatCurrency } from "utils/numberHelper";
import { ProductSales } from "./private/ProductSales";
import { WarehouseTable } from "./private/WarehouseTable";

export const ProductShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { queryResult } = useShow<IProduct, HttpError>({
    meta,
  });

  const { data, isLoading, isFetching, isRefetching } = queryResult;

  const record = data?.data;

  const isProcessing = isLoading ?? isFetching ?? isRefetching;

  const warehouseList = useMemo(
    () => queryResult?.data?.data?.inventoryPerWarehouses || [],
    [queryResult]
  );

  const { hasAdmin, hasDirector } = useUserRole();

  return (
    <>
      <Show
        isLoading={isProcessing}
        headerButtons={
          <>
            {/* <ListButton disabled={isProcessing} /> */}
            {resource?.canEdit && (hasAdmin || hasDirector) && (
              <EditButton disabled={isProcessing} type="primary" />
            )}
            {resource?.meta?.canDelete && (hasAdmin || hasDirector) && (
              <DeleteButton
                disabled={isProcessing}
                meta={meta}
                onSuccess={() => {
                  list(API_RESOURCES.products);
                }}
                errorNotification={(error) => {
                  return {
                    type: "error",
                    message:
                      (error as any)?.message ??
                      t("products.notification.deleteError"),
                  };
                }}
                successNotification={{
                  type: "success",
                  message: t("products.notification.deleteSuccess"),
                }}
              />
            )}
            {/* <RefreshButton
              disabled={isProcessing}
              loading={isProcessing}
              meta={meta}
            /> */}
          </>
        }
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.name ?? "--"}
              label={t("products.fields.name")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.price ? formatCurrency(record.price) : "--"}
              label={t("products.fields.price_unit")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.unit ?? "--"}
              label={t("products.fields.unit")}
            />
          </Col>

          <Col xs={24}>
            <TextBox
              value={record?.description ?? "--"}
              label={t("products.fields.description")}
              valueStyles={{
                minHeight: 88,
              }}
            />
          </Col>

          <Col xs={24}>
            <WithLabel label={t("products.fields.images")}>
              <Space>
                {record?.images?.map((image, idx) => (
                  <Image
                    key={`image-${idx}`}
                    style={{ maxWidth: 64, maxHeight: 64 }}
                    src={image}
                  />
                ))}
              </Space>
            </WithLabel>
          </Col>

          <Col xs={24}>
            <WarehouseTable warehouseList={warehouseList} hasViewHistory />
          </Col>
        </Row>
      </Show>

      {record?.id && <ProductSales productId={record?.id} />}
    </>
  );
};
