import { FilterOutlined } from "@ant-design/icons";
import { useModal, useSelect } from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  getDefaultFilter,
  useTranslate,
} from "@refinedev/core";
import { Button, Col, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { RangePicker } from "components/datepicker";
import WithLabel from "components/withLabel";
import {
  API_RESOURCES,
  DEFAULT_ALL_FILTER_VALUE,
  DEFAULT_OPTION_ALL,
  FILTER_ORDER_STATUS_LIST,
  SERVICE_MOBISALE,
} from "config";
import useUserRole from "hooks/useUserRole";
import { IArea, IOrderStatus, IProduct } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export interface OrderFilterTypes {
  areaId?: string;
  status?: IOrderStatus | typeof DEFAULT_ALL_FILTER_VALUE;
  productId?: string;
  startDate?: string;
  endDate?: string;
}

interface FilterModalProps extends OrderFilterTypes {
  filters: CrudFilters;
  onFilterChange?: (values: OrderFilterTypes) => void;
}

export const FilterModal: React.FC<FilterModalProps> = ({
  filters,
  onFilterChange,
}) => {
  const t = useTranslate();

  const {
    modalProps: modalFilters,
    show: showFilters,
    close: closeFilters,
  } = useModal();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { hasAdmin, hasDirector } = useUserRole();

  const [filterValues, setFilterValues] = useState<OrderFilterTypes | null>(
    null
  );

  useEffect(() => {
    setFilterValues({
      areaId: getDefaultFilter("areaId", filters, "eq"),
      status: getDefaultFilter("status", filters, "eq"),
      productId: getDefaultFilter("productId", filters, "eq"),
      startDate: getDefaultFilter("startDate", filters, "eq"),
      endDate: getDefaultFilter("endDate", filters, "eq"),
    });
  }, [filters]);

  const {
    selectProps: {
      showSearch: showSearchArea,
      onSearch: onSearchArea,
      ...areaSelectProps
    },
  } = useSelect<IArea, HttpError>({
    resource: API_RESOURCES.areas,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const {
    selectProps: {
      showSearch: showProduct,
      onSearch: onSearchProduct,
      ...productSelectProps
    },
  } = useSelect<IProduct, HttpError>({
    resource: API_RESOURCES.products,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const hasAnyFilter = useMemo(() => {
    const areaId = getDefaultFilter("areaId", filters, "eq");
    const status = getDefaultFilter("status", filters, "eq");
    const productId = getDefaultFilter("productId", filters, "eq");
    const startDate = getDefaultFilter("startDate", filters, "eq");
    const endDate = getDefaultFilter("endDate", filters, "eq");

    if (areaId && areaId !== DEFAULT_ALL_FILTER_VALUE) return true;
    if (status && status !== DEFAULT_ALL_FILTER_VALUE) return true;
    if (productId && productId !== DEFAULT_ALL_FILTER_VALUE) return true;
    if (
      startDate &&
      endDate &&
      startDate !== DEFAULT_ALL_FILTER_VALUE &&
      endDate !== DEFAULT_ALL_FILTER_VALUE
    )
      return true;
    return false;
  }, [filters]);

  return (
    <>
      <Button
        icon={<FilterOutlined />}
        onClick={showFilters}
        shape="circle"
        style={{ color: hasAnyFilter ? "#4568F4" : "#000000E0" }}
      />

      <Modal
        {...modalFilters}
        width={600}
        title={t("common.filters")}
        okText={t("buttons.filter")}
        onCancel={() => {
          //   setFilterValues(null);
          closeFilters();
        }}
        onOk={() => {
          if (filterValues) {
            onFilterChange?.(filterValues!);
          }
          closeFilters();
        }}
      >
        <Row gutter={[16, 24]} style={{ paddingTop: 20, paddingBottom: 20 }}>
          {(hasAdmin || hasDirector) && (
            <Col xs={12}>
              <WithLabel
                label={t("orders.filters.fields.area")}
                labelStyles={{ color: "#000000D9" }}
                hasRequiredMark
              >
                <Select
                  value={filterValues?.areaId}
                  onChange={(value) =>
                    setFilterValues((values) => ({
                      ...values,
                      areaId: value,
                    }))
                  }
                  style={{ width: "100%" }}
                  placeholder={t("orders.filters.placeholders.area")}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.children ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                >
                  {[
                    DEFAULT_OPTION_ALL(t),
                    ...(areaSelectProps.options ?? []),
                  ]?.map((fArea) => (
                    <Select.Option key={fArea.value} value={fArea.value}>
                      {fArea.label}
                    </Select.Option>
                  ))}
                </Select>
              </WithLabel>
            </Col>
          )}

          <Col xs={12}>
            <WithLabel
              label={t("orders.filters.fields.product")}
              labelStyles={{ color: "#000000D9" }}
              hasRequiredMark
            >
              <Select
                value={filterValues?.productId}
                onChange={(value) =>
                  setFilterValues((values) => ({
                    ...values,
                    productId: value,
                  }))
                }
                style={{ width: "100%" }}
                placeholder={t("orders.filters.placeholders.product")}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(
                      String(option?.children ?? "")
                    ),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              >
                {[
                  DEFAULT_OPTION_ALL(t),
                  ...(productSelectProps.options ?? []),
                ]?.map((fProduct) => (
                  <Select.Option key={fProduct.value} value={fProduct.value}>
                    {fProduct.label}
                  </Select.Option>
                ))}
              </Select>
            </WithLabel>
          </Col>

          <Col xs={12}>
            <WithLabel
              label={t("orders.filters.fields.status")}
              labelStyles={{ color: "#000000D9" }}
              hasRequiredMark
            >
              <Select
                value={filterValues?.status}
                onChange={(value) =>
                  setFilterValues((values) => ({
                    ...values,
                    status: value,
                  }))
                }
                style={{ width: "100%" }}
                placeholder={t("orders.filters.placeholders.status")}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(
                      String(option?.children ?? "")
                    ),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              >
                {FILTER_ORDER_STATUS_LIST(t)?.map((tStatus) => (
                  <Select.Option key={tStatus.value} value={tStatus.value}>
                    {tStatus.label}
                  </Select.Option>
                ))}
              </Select>
            </WithLabel>
          </Col>

          <Col xs={12}>
            <WithLabel
              label={t("orders.filters.fields.dateTime")}
              labelStyles={{ color: "#000000D9" }}
              hasRequiredMark
            >
              <RangePicker
                value={[
                  filterValues?.startDate
                    ? dayjs(filterValues?.startDate)
                    : null,
                  filterValues?.endDate ? dayjs(filterValues?.endDate) : null,
                ]}
                onCalendarChange={(rangeValue) => {
                  console.log({ rangeValue });
                  setFilterValues((values) => ({
                    ...values,
                    startDate: rangeValue?.[0]?.toISOString(),
                    endDate: rangeValue?.[1]?.toISOString(),
                  }));
                }}
              />
            </WithLabel>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
