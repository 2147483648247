import {
  IActiveStatus,
  IGenderCode,
  IOrderStatus,
  IProductUnitCode,
  IUserRoleCode,
} from "interfaces";

export const DEFAULT_LOCALE = "vi";

export const SITE_TITLE = "JMT MobiSale";
export const SITE_DESCRIPTION =
  "Hệ thống quản lý doanh số - quản lý cộng tác viên bán hàng | JMT MobiSale";
export const SITE_CANONICAL = `${window.location.origin}/`;
export const SITE_THEME_COLOR = "#1677FF";

export const HOST_API =
  process.env.REACT_APP_HOST_API || "https://api.fake-rest.refine.dev";

export const STORAGE_URL = "/mobisale/storage";

export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN_KEY";
export const REFRESH_TOKEN_KEY = "REFRESH_TOKEN_KEY";
export const USER_ID_KEY = "USER_ID_KEY";
export const USER_NAME_KEY = "USER_NAME_KEY";
export const USER_USERNAME_KEY = "USER_USERNAME_KEY";
export const USER_AVATAR_KEY = "USER_AVATAR_KEY";
export const USER_ROLE_KEY = "USER_ROLE_KEY";

export const PASSWORD_PLACEHOLDER = "●●●●●●●●";

export const DEFAULT_SIDER_WIDTH = 272;
export const DEFAULT_SIDER_UNAUTHENTICATED_WIDTH = 500;
export const DEFAULT_SIDER_COLLAPSED_WIDTH = 80;
export const DEFAULT_SIDER_DARK_BG_COLOR = "#0E172D";
export const DEFAULT_TEXTAREA_MAX_LENGTH = 200;
export const DEFAULT_PRODUCTS_DESCRIPTION_MAX_LENGTH = 5000;

export const DEFAULT_DAYS_FOR_REPORTS = 14;
export const DEFAULT_MONTHS_FOR_REPORTS = 3;
export const DEFAULT_YEARS_FOR_REPORTS = 1;

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
export const FULL_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const SERVICE_COMPANIES = "companies";
export const SERVICE_MOBISALE = "mobisale";
export const SERVICE_BILLING = "billing";

export const BILLING_SERVICE_MOBISALE = "MOBILESALE";
export const BILLING_CICRLE = 3;

export const MONEY_UNIT = "VNĐ";
export const PERCENTAGE_UNIT = "%";

export const PAYMENT_BANK_NAME = "Techcombank";
export const PAYMENT_BANK_BRANCH_NAME = "Chi nhánh Hà Nội";
export const PAYMENT_BANK_ACCOUNT_NUMBER = "99997519";
export const PAYMENT_BANK_ACCOUNT_NAME = "Công ty cổ phần JMT";
export const PAYMENT_TEMPLATE_CONTENT = "{{company}} - goi {{pkg}}";

export const PATHS = {
  home: "/",
  forgotPassword: "/forgot-password",
  updatePassword: "/update-password",
  login: "/login",
  accountMe: "/account/me",
  dashboard: "/dashboard",
  splash: "/splash",
  notFound: "/404",
};

export const ROLE_CODE_RECORDS: Record<IUserRoleCode, any> = {
  ADMIN: 1,
  DIRECTOR: 1,
  INV_MANAGEMENT: 1,
  PHAR_REP: 1,
};

export const ROLE_LIST = (
  t?: Function
): {
  value: IUserRoleCode;
  label: string;
  color: string;
  isCreatable?: boolean;
}[] => {
  return [
    {
      value: "ADMIN",
      label: t ? t("roles.admin") : "Admin",
      color: "#f50",
      isCreatable: false,
    },
    {
      value: "DIRECTOR",
      label: t ? t("roles.director") : "Giám đốc",
      color: "#3890ED",
      isCreatable: true,
    },
    {
      value: "INV_MANAGEMENT",
      label: t ? t("roles.inv_management") : "Quản lý kho",
      color: "#34A853",
      isCreatable: true,
    },
    {
      value: "PHAR_REP",
      label: t ? t("roles.phar_rep") : "Trình dược viên",
      color: "#FAAD14",
      isCreatable: true,
    },
  ];
};

export const DEFAULT_ALL_FILTER_VALUE = "ALL";

export const DEFAULT_OPTION_ALL = (t?: Function) => ({
  value: "ALL" as typeof DEFAULT_ALL_FILTER_VALUE,
  label: t ? t("roles.all") : "Tất cả",
});

export const FILTER_ROLE_LIST = (
  t?: Function
): {
  value: IUserRoleCode | typeof DEFAULT_ALL_FILTER_VALUE;
  label: string;
}[] => {
  return [
    DEFAULT_OPTION_ALL(t),
    ...ROLE_LIST(t).filter((item) => item.isCreatable),
  ];
};

export const ORDER_STATUS_LIST = (
  t?: Function
): {
  value: IOrderStatus;
  label: string;
}[] => {
  return [
    {
      value: "CREATED",
      label: t ? t("orders.fields.status_CREATED") : "Mới",
    },
    {
      value: "WAIT_FOR_APPROVED",
      label: t ? t("orders.fields.status_WAIT_FOR_APPROVED") : "Chờ duyệt",
    },
    {
      value: "COMPLETED",
      label: t ? t("orders.fields.status_COMPLETED") : "Phê duyệt",
    },
    {
      value: "REJECTED",
      label: t ? t("orders.fields.status_REJECTED") : "Từ choois",
    },
  ];
};

export const FILTER_ORDER_STATUS_LIST = (
  t?: Function
): {
  value: IOrderStatus | typeof DEFAULT_ALL_FILTER_VALUE;
  label: string;
}[] => {
  return [DEFAULT_OPTION_ALL(t), ...ORDER_STATUS_LIST(t)];
};

export const ACTIVE_STATUS_LIST = (
  t?: Function
): {
  value: IActiveStatus;
  label: string;
}[] => {
  return [
    {
      value: "ACTIVE",
      label: t ? t("common.activeStatus_ACTIVE") : "Kích hoạt",
    },
    {
      value: "INACTIVE",
      label: t ? t("common.activeStatus_INACTIVE") : "Ngừng kích hoạt",
    },
  ];
};

export const PRODUCT_UNIT_LIST = (
  t?: Function
): {
  value: IProductUnitCode;
  label: string;
}[] => {
  return [
    {
      value: "vien",
      label: t ? t("products.units.vien") : "Viên",
    },
    {
      value: "vi",
      label: t ? t("products.units.vi") : "Vỉ",
    },
    {
      value: "hop_lo_goi",
      label: t ? t("products.units.hop_lo_goi") : "Hộp / Lọ / Gói",
    },
    {
      value: "thung",
      label: t ? t("products.units.thung") : "Thùng",
    },
  ];
};

export const GENDER_LIST = (
  t?: Function
): {
  value: IGenderCode;
  label: string;
}[] => {
  return [
    {
      value: "MALE",
      label: t ? t("gender.male") : "Nam",
    },
    {
      value: "FEMALE",
      label: t ? t("gender.female") : "Nữ",
    },
    {
      value: "OTHER",
      label: t ? t("gender.other") : "Khác",
    },
  ];
};

export const API_RESOURCES = {
  splash: "splash",
  dashboard: "dashboard",
  employees: "employees",
  areas: "areas",
  products: "products",
  orders: "orders",
  warehouses: "warehouses",
  customers: "customers",
};

export const ROLE_BASED_RESOURCES: Record<IUserRoleCode, string[]> = {
  ADMIN: [
    API_RESOURCES.dashboard,
    API_RESOURCES.orders,
    API_RESOURCES.employees,
    API_RESOURCES.customers,
    API_RESOURCES.products,
    API_RESOURCES.areas,
    API_RESOURCES.warehouses,
  ],
  DIRECTOR: [
    API_RESOURCES.dashboard,
    API_RESOURCES.orders,
    API_RESOURCES.employees,
    API_RESOURCES.customers,
    API_RESOURCES.products,
    API_RESOURCES.areas,
    API_RESOURCES.warehouses,
  ],
  INV_MANAGEMENT: [API_RESOURCES.orders, API_RESOURCES.products],
  PHAR_REP: [
    API_RESOURCES.dashboard,
    API_RESOURCES.orders,
    API_RESOURCES.customers,
    API_RESOURCES.products,
  ],
};
