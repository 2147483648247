export function fuzzySearch(input: string, pattern: string): boolean {
  let inputIndex = 0;
  let patternIndex = 0;

  while (inputIndex < input.length && patternIndex < pattern.length) {
    if (input[inputIndex] === pattern[patternIndex]) {
      patternIndex++;
    }
    inputIndex++;
  }

  return patternIndex === pattern.length;
}
