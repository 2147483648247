import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Row, Space, Switch, Typography } from "antd";
import TextBox from "components/textBox";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { IArea, IUpdateAreaStatusTypes } from "interfaces";
import React from "react";
import { AreaSales } from "./private/AreaSales";

export const AreaShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { queryResult } = useShow<IArea, HttpError>({
    meta,
  });

  const { data, isLoading, isFetching, isRefetching } = queryResult;

  const record = data?.data;

  const isProcessing = isLoading ?? isFetching ?? isRefetching;

  const apiUrl = useApiUrl();

  const invalidate = useInvalidate();

  const { mutate: updateAreaStatus, isLoading: isUpdatingStatus } =
    useCustomMutation<IUpdateAreaStatusTypes>();

  const handleUpdateAreaStatus = (values: IUpdateAreaStatusTypes) => {
    updateAreaStatus(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/areas/action`,
        method: "post",
        values: {
          ...values,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("areas.notification.updateStatusError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("areas.notification.updateStatusSuccess"),
        },
      },
      {
        onSuccess() {
          invalidate({
            resource: API_RESOURCES.areas,
            invalidates: ["detail"],
            id: record?.id,
          });
        },
      }
    );
  };

  return (
    <>
      <Show
        title={
          <Space size="small" style={{ alignItems: "center" }}>
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              {t("areas.titles.show")}
            </Typography.Title>
            <Switch
              disabled
              loading={isUpdatingStatus}
              checked={record?.status === "ACTIVE"}
              onChange={(checked) => {
                handleUpdateAreaStatus({
                  areaId: String(record?.id),
                  status: checked ? "ACTIVE" : "INACTIVE",
                });
              }}
            />
          </Space>
        }
        isLoading={isProcessing}
        headerButtons={
          <>
            {/* <ListButton disabled={isProcessing} /> */}
            {resource?.canEdit && (
              <EditButton disabled={isProcessing} type="primary" />
            )}
            {resource?.meta?.canDelete && (
              <DeleteButton
                disabled={isProcessing}
                meta={meta}
                onSuccess={() => {
                  list(API_RESOURCES.areas);
                }}
                errorNotification={(error) => {
                  return {
                    type: "error",
                    message:
                      (error as any)?.message ??
                      t("areas.notification.deleteError"),
                  };
                }}
                successNotification={{
                  type: "success",
                  message: t("areas.notification.deleteSuccess"),
                }}
              />
            )}
            {/* <RefreshButton
              disabled={isProcessing}
              loading={isProcessing}
              meta={meta}
            /> */}
          </>
        }
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.name ?? "--"}
              label={t("areas.fields.name")}
            />
          </Col>

          {/* <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.parentArea?.name ?? "--"}
              label={t("areas.fields.parent")}
            />
          </Col> */}

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.warehouse?.name ?? "--"}
              label={t("areas.fields.warehouse")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.employee?.name ?? "--"}
              label={t("areas.fields.employee")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={
                record?.status
                  ? t(`common.activeStatus_${record?.status}`)
                  : "--"
              }
              label={t("areas.fields.status")}
            />
          </Col>

          <Col xs={24}>
            <TextBox
              value={record?.description ?? "--"}
              label={t("areas.fields.description")}
              valueStyles={{
                minHeight: 88,
              }}
            />
          </Col>
        </Row>
      </Show>

      {record?.id && <AreaSales areaId={record?.id} />}
    </>
  );
};
