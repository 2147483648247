import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select, Space } from "antd";
import UploadImage from "components/upload/uploadImage";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IArea, ICustomer, IEmployee, IUserRoleCode } from "interfaces";
import React, { useEffect } from "react";
import { formatName } from "utils/commonHelper";
import { getFileUrlsFromEvent, getFileValueProps } from "utils/formHelper";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { ApproveReject } from "./private/ApproveReject";

export const CustomerEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { hasAdmin, hasDirector, hasPharRep } = useUserRole();

  const { formProps, saveButtonProps, queryResult } = useForm<
    ICustomer,
    HttpError
  >({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("customers.notification.editError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("customers.notification.editSuccess"),
    },
  });

  const {
    selectProps: {
      showSearch: showSearchArea,
      onSearch: onSearchArea,
      ...areaSelectProps
    },
    queryResult: areaQueryResult,
  } = useSelect<IArea, HttpError>({
    resource: API_RESOURCES.areas,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const {
    selectProps: {
      showSearch: showSearchEmployee,
      onSearch: onSearchEmployee,
      ...employeeSelectProps
    },
    queryResult: employeeQueryResult,
  } = useSelect<IEmployee, HttpError>({
    resource: API_RESOURCES.employees,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    filters: [
      {
        field: "role",
        operator: "eq",
        value: "PHAR_REP" as IUserRoleCode,
      },
    ],
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const employeeOptions = employeeQueryResult?.data?.data.map((item) => ({
    value: item.id,
    label: formatName(item.firstName, item.lastName),
  }));

  const areaId = Form.useWatch(["area", "id"], formProps?.form);

  useEffect(() => {
    if (areaId) {
      formProps?.form?.setFieldsValue({
        employeeId: areaQueryResult?.data?.data.find(
          (area) => area.id === areaId
        )?.employee?.id,
      });
    }
  }, [areaId, areaQueryResult, formProps?.form]);

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  // TODO: workaround to update warehouse,
  // Remove these code later after BE update API update
  const apiUrl = useApiUrl();

  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: updateCustomer } = useCustomMutation<ICustomer>();

  const handleUpdateCustomer = async (values: ICustomer) => {
    updateCustomer(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/customers/update`,
        method: "put",
        values: {
          ...values,
          id: queryResult?.data?.data?.id,
          areaId: values?.area?.id,
          employeeId: values?.employee?.id,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("customers.notification.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("customers.notification.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.customers);
          }, 100);
        },
      }
    );
  };
  //

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={
        <>
          {/* <ListButton disabled={isProcessing} />
          <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
          <ShowButton />
        </>
      }
      footerButtons={
        <>
          <ApproveReject customer={queryResult?.data?.data} />
          <Space>
            {resource?.meta?.canDelete && (
              <DeleteButton
                disabled={isProcessing}
                meta={meta}
                onSuccess={() => {
                  list(API_RESOURCES.customers);
                }}
                errorNotification={(error) => {
                  return {
                    type: "error",
                    message:
                      (error as any)?.message ??
                      t("customers.notification.deleteError"),
                  };
                }}
                successNotification={{
                  type: "success",
                  message: t("customers.notification.deleteSuccess"),
                }}
              />
            )}
            <SaveButton disabled={isProcessing} {...saveButtonProps} />
          </Space>
        </>
      }
      footerButtonProps={{
        style: {
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "0px 24px",
        },
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => handleUpdateCustomer(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("customers.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("customers.errors.nameRequired"),
                },
              ]}
            >
              <Input placeholder={t("customers.placeholders.name")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("customers.fields.phone")}
              name={["phoneNumber"]}
              rules={[
                {
                  required: true,
                  message: t("customers.errors.phoneRequired"),
                },
                {
                  validator: (_, value) => {
                    if (!value || isValidPhoneNumb(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("customers.errors.phoneInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input placeholder={t("customers.placeholders.phone")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("customers.fields.address")} name={["address"]}>
              <Input placeholder={t("customers.placeholders.address")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("customers.fields.area")}
              name={["area", "id"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("customers.errors.areaRequired"),
              //   },
              // ]}
            >
              <Select
                disabled={hasPharRep}
                {...areaSelectProps}
                placeholder={t("customers.placeholders.area")}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            {(hasAdmin || hasDirector) && areaId && (
              <Form.Item
                label={t("customers.fields.employee")}
                name={["employeeId"]}
                // rules={[
                //   {
                //     required: true,
                //     message: t("customers.errors.areaRequired"),
                //   },
                // ]}
              >
                <Select
                  disabled={true}
                  {...employeeSelectProps}
                  placeholder={t("customers.placeholders.employee")}
                  options={employeeOptions}
                  showSearch
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </Form.Item>
            )}
            {hasPharRep && (
              <Form.Item name={["employee", "id"]}>
                <Input hidden />
              </Form.Item>
            )}
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("customers.fields.image")}
              name="image"
              getValueFromEvent={(e) => {
                const fileUrls = getFileUrlsFromEvent(e);
                return fileUrls.length < 1 ? "" : fileUrls[0];
              }}
              getValueProps={(value) => {
                const imgs = getFileValueProps(
                  value,
                  t(`customers.fields.image`)
                );
                return {
                  defaultFileList: imgs,
                };
              }}
            >
              <UploadImage maxCount={1} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
