import { useApiUrl, useCustom } from "@refinedev/core";
import { Button, Grid, Modal, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import imgUpgrade from "assets/img_upgrade.svg";
import { PackageList } from "components/billing";
import { BILLING_SERVICE_MOBISALE, SERVICE_BILLING } from "config";
import { useCompanyId } from "hooks/useCompanyId";
import useUserRole from "hooks/useUserRole";
import { IMobisalePackage, IMobisaleSubscription } from "interfaces";

export const UpgradeService: React.FC = () => {
  const { t } = useTranslation();

  const { hasAdmin, hasDirector } = useUserRole();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const breakpoint = Grid.useBreakpoint();

  const apiUrl = useApiUrl();

  const companyId = useCompanyId();

  const {
    data: subscriptionData,
    isLoading: isLoadingSubscription,
    refetch: refetchSubscriptionData,
  } = useCustom<IMobisaleSubscription>({
    url: `${apiUrl}/${SERVICE_BILLING}/subscriptions/service/${BILLING_SERVICE_MOBISALE}/company/${companyId}`,
    method: "get",
  });

  const { data: packagesData, isLoading: isLoadingPackages } = useCustom<
    IMobisalePackage[]
  >({
    url: `${apiUrl}/${SERVICE_BILLING}/packages/service/${BILLING_SERVICE_MOBISALE}`,
    method: "get",
  });

  const isMaxPackage = useMemo(() => {
    return subscriptionData?.data?.pack?.orders === packagesData?.data?.length;
  }, [subscriptionData, packagesData]);

  const visible = useMemo(
    () => (hasAdmin || hasDirector) && !isMaxPackage,
    [hasAdmin, hasDirector, isMaxPackage]
  );

  const handleUpgrade = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!visible || !companyId) {
      return;
    }

    const hasShowPkg = localStorage.getItem(companyId);
    if (!hasShowPkg) {
      localStorage.setItem(companyId, "true");
      setTimeout(() => {
        setIsModalOpen(true);
      }, 100);
    }
  }, [visible, companyId]);

  return (
    <>
      {visible && (
        <Spin spinning={isLoadingSubscription || isLoadingPackages}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 24,
              minHeight: 180,
              backgroundColor: "#fff",
              padding: 24,
              borderRadius: 8,
              flexWrap: "wrap",
            }}
          >
            <img src={imgUpgrade} alt={"upgrade"} />

            <div>
              <div style={{ color: "#00238A", fontWeight: 600, fontSize: 24 }}>
                {t("dashboard.upgrade.title")}
              </div>
              <div
                style={{
                  color: "#444D56",
                  fontWeight: 400,
                  fontSize: 18,
                  marginTop: 5,
                }}
              >
                {t("dashboard.upgrade.description")}
              </div>
            </div>

            <div style={{ flex: 1 }} />

            <Button
              type="primary"
              size={"large"}
              style={{
                backgroundColor: "#00238A",
                boxShadow:
                  "0px 6px 14px -6px rgba(17, 15, 71, 0.12), 0px 10px 32px -4px rgba(17, 15, 71, 0.1)",
              }}
              onClick={handleUpgrade}
            >
              {t("dashboard.upgrade.upgrade_service")}
            </Button>
          </div>
        </Spin>
      )}

      <Modal
        className="modal-activate-package"
        title={null}
        open={isModalOpen}
        width={breakpoint.xl ? 1150 : breakpoint.lg ? 900 : 768}
        footer={null}
        // closable={false}
        maskClosable={false}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <PackageList
          withTitle
          packages={packagesData?.data}
          subscription={subscriptionData?.data}
          onAfterCreateSubscription={() => {
            refetchSubscriptionData();
          }}
        />
      </Modal>
    </>
  );
};
