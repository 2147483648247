import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { useMemo, useState } from "react";

import { Spin } from "antd";
import imgTrend1 from "assets/img_trend_1.svg";
import { BarChartWidget } from "components/chart";
import { RangeValue, SelectDateType } from "components/selectDate";
import {
  DEFAULT_DAYS_FOR_REPORTS,
  DEFAULT_MONTHS_FOR_REPORTS,
  DEFAULT_YEARS_FOR_REPORTS,
  SERVICE_MOBISALE,
} from "config";
import { useCompanyId } from "hooks/useCompanyId";
import {
  ICustomerSaleReportItem,
  IOrderReportItem,
  IWithPaging,
} from "interfaces";
import {
  rangeDayFilters,
  rangeMonthFilters,
  rangeYearFilters,
} from "utils/dayjsHelper";
import { toMillions } from "utils/numberHelper";

interface CustomerReportProps {
  customerId: string;
}

export const CustomerReport: React.FC<CustomerReportProps> = ({
  customerId,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        marginTop: 24,
      }}
    >
      <CustomerOrderNoChart customerId={customerId} />
      <CustomerMonthIncomeChart customerId={customerId} />
    </div>
  );
};

type CustomerOrderNoChartProps = CustomerReportProps;

const CustomerOrderNoChart: React.FC<CustomerOrderNoChartProps> = ({
  customerId,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const companyId = useCompanyId();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<IOrderReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/customers/report/order_no`,
    method: "get",
    queryOptions: {
      enabled: Boolean(
        rangeValue?.[0] && rangeValue?.[1] && customerId && type
      ),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        customerId,
        companyId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return data?.data?.content?.map((item) => item.totalNo ?? 0) ?? [];
  }, [data]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("customers.tabs.reports.charts.titles.orders")}
        image={imgTrend1}
        series={[
          {
            name: t("ustomers.tabs.reports.charts.bill"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("customers.tabs.reports.charts.bill")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
      />
    </Spin>
  );
};

type CustomerMonthIncomeChartProps = CustomerReportProps;

const CustomerMonthIncomeChart: React.FC<CustomerMonthIncomeChartProps> = ({
  customerId,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const companyId = useCompanyId();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<ICustomerSaleReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/orders/report/customer`,
    method: "get",
    queryOptions: {
      enabled: Boolean(
        rangeValue?.[0] && rangeValue?.[1] && customerId && type
      ),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        customerId,
        companyId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return toMillions(
      data?.data?.content?.map((item) => item.total_price ?? 0) ?? []
    );
  }, [data]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("customers.tabs.reports.charts.titles.monthIncome")}
        image={imgTrend1}
        series={[
          {
            name: t("customers.tabs.reports.charts.money_unit"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("customers.tabs.reports.charts.money_unit")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
      />
    </Spin>
  );
};
