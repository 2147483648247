import axios, { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from "axios";

// config
import { ACCESS_TOKEN_KEY, HOST_API, REFRESH_TOKEN_KEY } from "config";
import { removeAllUserIdentities } from "utils/identityHelper";

// ------------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (accessToken) {
      if (request.headers) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }
      else {
        request.headers = {
          Authorization: `Bearer ${accessToken}`,
        } as AxiosRequestHeaders;
      }
    }

    return request;
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    if (
      error.response?.status === 401 &&
      !error.config.url.includes("/users/authenticate")
    ) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (error.config.url.includes("/users/refresh-token") || !refreshToken) {
        removeAllUserIdentities();

        const pathname = window.location.pathname;
        const search = window.location.search;
        window.location.assign(`/login?to=${pathname}${search}`);

        return Promise.reject({
          ...error,
          message: error.response?.data?.message || "Something went wrong",
          statusCode: error.response?.status,
        });
      }

      try {
        const { data } = await axios.post(`${HOST_API}/users/refresh-token`, {
          refreshToken,
        });

        localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);

        axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
        error.config.headers.Authorization = `Bearer ${data.accessToken}`;
        return axiosInstance.request(error.config);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    return Promise.reject({
      ...error,
      message: error.response?.data?.message || "Something went wrong",
      statusCode: error.response?.status,
    });
  }
);

export default axiosInstance;
