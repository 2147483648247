import { AccessControlProvider } from "@refinedev/core";
import { API_RESOURCES, ROLE_BASED_RESOURCES } from "config";
import { IAccessControlActions, IUserRoleCode } from "interfaces";
import { KeycloakTokenParsed } from "keycloak-js";
import { getUserRole } from "utils/identityHelper";

// function matchApiResources(path: string, excludedApiResources: string[]) {
//   for (const apiRes of excludedApiResources) {
//     if (path.startsWith(apiRes)) return false;
//   }
//   return true;
// }

function matchApiResources(path: string, includedApiResources: string[]) {
  for (const apiRes of includedApiResources) {
    if (path.startsWith(apiRes)) return true;
  }
  return false;
}

function hasAccessWithRole(
  role: IUserRoleCode,
  action: IAccessControlActions,
  path: string
): Promise<boolean> {
  return new Promise((resolve) => {
    // if (path.startsWith(API_RESOURCES.visitorsRegistrations)) {
    //   if (role === "SECURITY_DEPARTMENT" && action === "edit") {
    //     resolve(false);
    //     return;
    //   }

    //   if (role === "ADMIN" && action !== "list" && action !== "show") {
    //     resolve(false);
    //     return;
    //   }
    // }

    // if (path.startsWith(API_RESOURCES.departments)) {
    //   if (role !== "ADMIN" && action !== "list" && action !== "show") {
    //     resolve(false);
    //     return;
    //   }
    // }

    if (path.startsWith(API_RESOURCES.products)) {
      if (
        (role === "PHAR_REP" || role === "INV_MANAGEMENT") &&
        action !== "list" &&
        action !== "show"
      ) {
        resolve(false);
        return;
      }
    }

    if (path.startsWith(API_RESOURCES.orders)) {
      if (
        (role === "ADMIN" || role === "DIRECTOR") &&
        action !== "list" &&
        action !== "show"
      ) {
        resolve(false);
        return;
      }
    }

    if (path.startsWith(API_RESOURCES.dashboard)) {
      if (role === "INV_MANAGEMENT") {
        resolve(false);
        return;
      }
    }

    resolve(matchApiResources(path, ROLE_BASED_RESOURCES[role]));
  });
}

async function canAccess(
  role: IUserRoleCode | null,
  action: IAccessControlActions,
  path: string
): Promise<boolean> {
  if (!role) return false;

  try {
    const hasAccess = await hasAccessWithRole(role, action, path);
    if (hasAccess) return true;

    return false;
  } catch (error) {
    return false;
  }
}

export const accessControlProvider = (
  tokenParsed?: KeycloakTokenParsed
): AccessControlProvider => {
  return {
    can: async ({ resource, action, params }) => {
      const role = getUserRole(tokenParsed);

      if (action === "delete" || action === "edit" || action === "show") {
        return Promise.resolve({
          can: await canAccess(role, action, `${resource}/${params?.id}`),
        });
      }

      if (action === "field") {
        return Promise.resolve({
          can: await canAccess(role, action, `${resource}/${params?.field}`),
        });
      }

      if (action === "list" || action === "create") {
        return Promise.resolve({
          can: await canAccess(role, action, resource ?? ""), // TODO: check
        });
      }

      return Promise.resolve({
        can: false,
      });
    },
  };
};
