import { Create, SaveButton, useForm, useSelect } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Button, Col, Form, Input, Popconfirm, Row, Select } from "antd";
import React, { useCallback, useState } from "react";

import { SendOutlined } from "@ant-design/icons";
import TextBox from "components/textBox";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { ICustomer, IOrder, IProductQuanity } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { CREATE_PRODUCTS_KEY } from "./private/constants";
import { ProductTable } from "./private/productTable";

export const OrderCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  // const { data: user } = useGetIdentity<IUserIdentity>();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const {
    formProps: { form: formCreateOrder, ...restFormCreateOrder },
    saveButtonProps,
    formLoading: formCreateOrderLoading,
  } = useForm<IOrder, HttpError>({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("orders.notification.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("orders.notification.createSuccess"),
    },
  });

  const {
    selectProps: {
      showSearch: showSearchCustomer,
      onSearch: onSearchCustomer,
      ...customerSelectProps
    },
    queryResult: customerQueryResult,
  } = useSelect<ICustomer, HttpError>({
    resource: API_RESOURCES.customers,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // filters: [
    //   {
    //     field: "status",
    //     operator: "eq",
    //     value: "APPROVED" as ICustomerStatus,
    //   },
    // ],
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  // TODO: using filters server
  const customerOptions = customerQueryResult?.data?.data
    ?.filter((item) => item.status === "APPROVED")
    ?.map((item) => ({
      value: item.id,
      label: `${item.name} (${item.phoneNumber})`,
    }));

  const [productList, setProductList] = useState<IProductQuanity[]>([]);

  const updateProductList = useCallback(
    (newProductList: IProductQuanity[]) => {
      setProductList(newProductList);
      formCreateOrder?.setFieldsValue({
        [CREATE_PRODUCTS_KEY]: newProductList.map((item) => ({
          ...item,
          productId: item.product?.id,
        })),
      });
    },
    [formCreateOrder]
  );

  const customerId = Form.useWatch(["customerId"], formCreateOrder);

  const { setWarnWhen } = useWarnAboutChange();

  const { list } = useNavigation();

  const apiUrl = useApiUrl();

  const { mutate: submitOrder } = useCustomMutation<IOrder>();

  const handleSubmitOrder = async () => {
    submitOrder(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/orders/submit`,
        method: "post",
        values: {
          customerId,
          items: productList?.map((item) => ({
            ...item,
            productId: item?.product?.id,
          })),
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t(`orders.notification.createError`),
          };
        },
        successNotification: {
          type: "success",
          message: t(`orders.notification.createSuccess`),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.orders);
          }, 100);
        },
      }
    );
  };

  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        disabled: productList.length === 0,
      }}
      isLoading={formCreateOrderLoading}
      footerButtons={
        <>
          <Popconfirm
            title={t("common.confirm_save_send_approve")}
            onConfirm={() => {
              formCreateOrder
                ?.validateFields()
                .then(() => handleSubmitOrder())
                .catch((error) => console.log("error", error));
            }}
            onCancel={() => null}
            okText={t("buttons.agree")}
            cancelText={t("buttons.cancel")}
            okType="primary"
          >
            <Button icon={<SendOutlined />}>
              {t("common.save_send_approve")}
            </Button>
          </Popconfirm>
          <SaveButton disabled={formCreateOrderLoading} {...saveButtonProps} />
        </>
      }
    >
      <Form
        form={formCreateOrder}
        {...restFormCreateOrder}
        layout="vertical"
        initialValues={{
          ...restFormCreateOrder.initialValues,
          // [EMPLOYEE_KEY]: user?.id,
        }}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("orders.fields.customer")}
              name={["customerId"]}
              rules={[
                {
                  required: true,
                  message: t("orders.errors.customerRequired"),
                },
              ]}
            >
              <Select
                {...customerSelectProps}
                options={customerOptions}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          {customerId && (
            <Col xs={24} md={12} lg={8}>
              <TextBox
                value={
                  customerQueryResult?.data?.data?.find(
                    (item) => item.id === customerId
                  )?.address ?? "--"
                }
                label={t("orders.fields.customerAddress")}
              />
            </Col>
          )}

          <Col xs={24} md={12}>
            <Form.Item name={CREATE_PRODUCTS_KEY}>
              <Input hidden />
            </Form.Item>
          </Col>

          {/* <Col xs={24} md={12}>
            <Form.Item name={EMPLOYEE_KEY}>
              <Input hidden />
            </Form.Item>
          </Col> */}

          <Col xs={24}>
            <ProductTable
              productList={productList}
              onProductListChanged={(newProductList) => {
                updateProductList(newProductList);
              }}
              isCreate
              hasAdd
              hasEdit
              hasDelete
            />
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
