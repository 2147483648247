import { Select, Space } from "antd";
import { RangePicker } from "components/datepicker";
import type { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

export type SelectDateType = "DAY" | "MONTH" | "YEAR";

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

export interface SelectDateProps {
  type: SelectDateType;
  onTypeChange?: (type: SelectDateType, rangeValue?: RangeValue) => void;
  rangeValue?: RangeValue;
  onRangeValueChange?: (rangeValue: RangeValue, type?: SelectDateType) => void;
}

export const SelectDate: React.FC<SelectDateProps> = ({
  type = "DAY",
  rangeValue = null,
  onTypeChange,
  onRangeValueChange,
}) => {
  const { t } = useTranslation();

  return (
    <Space>
      <>
        {type === "DAY" && (
          <RangePicker
            // showTime
            value={rangeValue}
            onCalendarChange={(rangeValue) =>
              onRangeValueChange?.(rangeValue, type)
            }
          />
        )}

        {type === "MONTH" && (
          <RangePicker
            picker="month"
            value={rangeValue}
            onCalendarChange={(rangeValue) =>
              onRangeValueChange?.(rangeValue, type)
            }
          />
        )}

        {type === "YEAR" && (
          <RangePicker
            picker="year"
            value={rangeValue}
            onCalendarChange={(rangeValue) =>
              onRangeValueChange?.(rangeValue, type)
            }
          />
        )}
      </>

      <Select
        value={type}
        onChange={(valueType) => onTypeChange?.(valueType, rangeValue)}
      >
        <Select.Option key={"date"} value={"DAY"}>
          {t("common.date")}
        </Select.Option>
        <Select.Option key={"month"} value={"MONTH"}>
          {t("common.month")}
        </Select.Option>
        <Select.Option key={"year"} value={"YEAR"}>
          {t("common.year")}
        </Select.Option>
      </Select>
    </Space>
  );
};
