import {
  CalendarOutlined,
  CheckOutlined,
  SendOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Button, Col, Divider, Popconfirm, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { HighLightOrderStatus } from "components/orders/HighLightOrderStatus";
import TextBox from "components/textBox";
import { API_RESOURCES, DATE_FORMAT, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IOrder, IOrderStatus, IProductQuanity } from "interfaces";
import { ProductTable } from "./private/productTable";

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const { hasPharRep, hasInvManagement, roleCode } = useUserRole();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { queryResult } = useShow<IOrder, HttpError>({
    meta,
  });

  const { data, isLoading, isFetching, isRefetching } = queryResult;

  const record = data?.data;

  const isProcessing = isLoading ?? isFetching ?? isRefetching;

  const initProductList = useMemo(
    () => queryResult?.data?.data?.orderItems || [],
    [queryResult]
  );

  const [productList, setProductList] = useState<IProductQuanity[]>([]);

  const updateProductList = useCallback((newProductList: IProductQuanity[]) => {
    setProductList(newProductList);
  }, []);

  useEffect(() => {
    updateProductList(
      initProductList.map((product, index) => ({
        ...product,
        acceptQuantity:
          product?.acceptQuantity ?? product?.requestQuantity ?? 0,
        key: index + 1,
      }))
    );
  }, [initProductList, updateProductList]);

  const apiUrl = useApiUrl();

  const { mutate: updateOrder } = useCustomMutation<IOrder>();

  const handleOrderAction = async (status: IOrderStatus, orderId?: string) => {
    updateOrder(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/orders/action`,
        method: "post",
        values: {
          orderId,
          status,
          items: productList?.map((item) => ({
            ...item,
            productId: item?.product?.id,
          })),
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t(`orders.notification.${status}_Error`),
          };
        },
        successNotification: {
          type: "success",
          message: t(`orders.notification.${status}_Success`),
        },
      },
      {
        onSuccess() {
          list(API_RESOURCES.orders);
        },
      }
    );
  };

  return (
    <Show
      isLoading={isProcessing}
      headerButtons={
        <>
          {/* <ListButton disabled={isProcessing} /> */}
          {hasPharRep && (
            <Popconfirm
              title={t("common.confirm_send_approve")}
              onConfirm={() =>
                handleOrderAction("WAIT_FOR_APPROVED", record?.id)
              }
              onCancel={() => null}
              okText={t("buttons.agree")}
              cancelText={t("buttons.cancel")}
              okType="primary"
              disabled={record?.status !== "CREATED"}
            >
              <Button
                icon={<SendOutlined />}
                disabled={record?.status !== "CREATED"}
              >
                {t("common.send_approve")}
              </Button>
            </Popconfirm>
          )}
          {hasInvManagement && (
            <>
              <Popconfirm
                title={t("common.confirm_approve")}
                onConfirm={() => handleOrderAction("COMPLETED", record?.id)}
                onCancel={() => null}
                okText={t("buttons.agree")}
                cancelText={t("buttons.cancel")}
                okType="primary"
                disabled={record?.status !== "WAIT_FOR_APPROVED"}
              >
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  disabled={record?.status !== "WAIT_FOR_APPROVED"}
                >
                  {t("common.approve")}
                </Button>
              </Popconfirm>
              <Popconfirm
                title={t("common.confirm_reject")}
                onConfirm={() => handleOrderAction("REJECTED", record?.id)}
                onCancel={() => null}
                okText={t("buttons.agree")}
                cancelText={t("buttons.cancel")}
                okType="danger"
                disabled={record?.status !== "WAIT_FOR_APPROVED"}
              >
                <Button
                  danger
                  icon={<StopOutlined />}
                  disabled={record?.status !== "WAIT_FOR_APPROVED"}
                >
                  {t("common.reject")}
                </Button>
              </Popconfirm>
            </>
          )}
          {resource?.canEdit && hasPharRep && record?.status === "CREATED" && (
            <EditButton disabled={isProcessing} type="primary" />
          )}
          {resource?.meta?.canDelete &&
            hasPharRep &&
            record?.status === "CREATED" && (
              <DeleteButton
                disabled={isProcessing}
                meta={meta}
                onSuccess={() => {
                  list(API_RESOURCES.orders);
                }}
                errorNotification={(error) => {
                  return {
                    type: "error",
                    message:
                      (error as any)?.message ??
                      t("orders.notification.deleteError"),
                  };
                }}
                successNotification={{
                  type: "success",
                  message: t("orders.notification.deleteSuccess"),
                }}
              />
            )}
          {/* <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
        </>
      }
    >
      <Row gutter={[16, 24]}>
        <Col xs={24}>
          <Space style={{ display: "flex", alignItems: "center" }}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {t("orders.commonDetail.title")}
            </Typography.Title>
            <Typography.Text style={{ margin: 0 }}>
              {t("orders.commonDetail.orderStatus")}:
            </Typography.Text>
            <HighLightOrderStatus
              status={record?.status ?? "CREATED"}
              roleCode={roleCode}
            />
            {(record?.status === "COMPLETED" ||
              record?.status === "REJECTED") &&
              record?.updatedAt && (
                <>
                  <Divider type="vertical" />
                  <CalendarOutlined />
                  <span>{`${t("orders.commonDetail.confirmDate")}: ${
                    record?.updatedAt
                      ? dayjs(record?.updatedAt).format(DATE_FORMAT)
                      : ""
                  }`}</span>
                </>
              )}
          </Space>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={
              record?.createdAt
                ? dayjs(record?.createdAt).format(DATE_FORMAT)
                : "--"
            }
            label={t("orders.commonDetail.fields.createdAt")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.code ?? "--"}
            label={t("orders.commonDetail.fields.code")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.warehouseManager?.name ?? "--"}
            label={t("orders.commonDetail.fields.warehouseManager")}
          />
        </Col>
      </Row>

      <Typography.Title level={4} style={{ marginTop: 50 }}>
        {t("orders.detailTitle")}
      </Typography.Title>

      <Row gutter={[16, 24]} style={{ marginTop: 30 }}>
        {!hasPharRep && (
          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={record?.employee?.name ?? "--"}
              label={t("orders.fields.employee")}
            />
          </Col>
        )}

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.customer?.name ?? "--"}
            label={t("orders.fields.customer")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.customer?.address ?? "--"}
            label={t("orders.fields.customerAddress")}
          />
        </Col>

        {/* <Col xs={24} md={12} lg={8}>
          <TextBox
            value={
              queryResult?.data?.data?.status
                ? t(`orderStatus.${queryResult?.data?.data?.status}`)
                : "--"
            }
            label={t(`orders.fields.status`)}
          />
        </Col> */}

        <Col xs={24}>
          <ProductTable
            productList={productList}
            onProductListChanged={(newProductList) => {
              updateProductList(newProductList);
            }}
            hasAdd={false}
            hasEdit={hasInvManagement && record?.status === "WAIT_FOR_APPROVED"}
            hasDelete={
              hasInvManagement && record?.status === "WAIT_FOR_APPROVED"
            }
          />
        </Col>
      </Row>
    </Show>
  );
};
