import { useEffect } from "react";
import { useLogin } from "@refinedev/core";

import LoadingScreen from "components/loadingScreen";

export const LoginWithKeycloakAuto: React.FC = () => {
  const { mutate: login } = useLogin();

  useEffect(() => {
    setTimeout(() => {
      login({});
    }, 100);
  }, [login]);

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <LoadingScreen
        wrapperStyle={{
          position: "absolute",
        }}
      />
    </div>
  );
};
