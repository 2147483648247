import { IArea, IAreaTree } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export function convertToAreaTree(
  areas: readonly IArea[],
  keyword?: string
): IAreaTree[] {
  const areaMap: { [key: string]: IAreaTree } = {};

  let cnt = 0;
  areas.forEach((area) => {
    const { id, parentArea, ...rest } = area;
    if (parentArea) {
      areaMap[id!] = { ...rest, id, parentArea };
    } else {
      areaMap[id!] = { ...rest, id, parentArea, idx: String(++cnt) };
    }
  });

  const rootAreas: IAreaTree[] = [];

  areas.forEach((area) => {
    const { id } = area;
    const currentArea = areaMap[id!];
    const parentArea = areaMap[currentArea.parentArea?.id ?? "none"];

    if (parentArea) {
      if (!parentArea.children) {
        parentArea.children = [];
      }

      parentArea.children.push(currentArea);
    } else {
      rootAreas.push(currentArea);
    }
  });

  // Update the idx values recursively
  updateIdx(rootAreas);

  if (keyword) {
    return filterTreeByKeyword(rootAreas, keyword);
  }

  return rootAreas;
}

function updateIdx(areas: IAreaTree[], parentIdx = ""): void {
  areas.forEach((area, index) => {
    const childIdx = parentIdx ? `${parentIdx}.${index + 1}` : `${index + 1}`;
    area.idx = childIdx;
    if (area.children) {
      updateIdx(area.children, childIdx);
    }
  });
}

function filterTreeByKeyword(areas: IAreaTree[], keyword: string): IAreaTree[] {
  return areas.reduce((result: IAreaTree[], area: IAreaTree) => {
    const children = filterTreeByKeyword(area.children || [], keyword);

    // const matchesKeyword = area.name
    //   ?.toLowerCase()
    //   .includes(keyword.toLowerCase());

    const matchesKeyword = fuzzySearch(
      toLowerCaseNonAccentVietnamese(area.name ?? ""),
      toLowerCaseNonAccentVietnamese(keyword)
    );

    if (matchesKeyword || children.length > 0) {
      const filteredArea = { ...area };
      if (children.length > 0) {
        filteredArea.children = children;
      }
      result.push(filteredArea);
    }

    return result;
  }, []);
}

// export function findWarehouseOfArea(area?: IArea): IWarehouse | null {
//   if (!area) return null;

//   if (area.warehouse) return area.warehouse;

//   return findWarehouseOfArea(area.parentArea);
// }
