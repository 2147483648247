import { InfoCircleFilled, SaveOutlined } from "@ant-design/icons";
import {
  useApiUrl,
  useCustomMutation,
  useGetIdentity,
  useTranslate,
} from "@refinedev/core";
import { Button, Card, Form, Input, Space, Spin } from "antd";

import { PageHeader } from "@refinedev/antd";
import { PASSWORD_PLACEHOLDER, PATHS, SERVICE_MOBISALE } from "config";
import { IUpdatePasswordTypes, IUserIdentity } from "interfaces";
import { useNavigate } from "react-router-dom";

const UpdatePassword: React.FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const apiUrl = useApiUrl();

  const { data: user } = useGetIdentity<IUserIdentity>();

  const { mutate: updatePassword, isLoading } =
    useCustomMutation<IUpdatePasswordTypes>();

  const handleupdatePassword = async ({
    password,
    newPassword,
    confirmPassword,
  }: IUpdatePasswordTypes) => {
    updatePassword({
      url: `${apiUrl}/${SERVICE_MOBISALE}/employees/${user?.id}/change-password`,
      method: "patch",
      values: {
        oldPassword: password,
        password: newPassword,
        confirmPassword,
      },
      errorNotification(error) {
        return {
          type: "error",
          message:
            error?.message ??
            t("pages.updatePassword.notification.changePasswordError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("pages.updatePassword.notification.changePasswordSuccess"),
      },
    });
  };

  return (
    <PageHeader
      title={t("pages.updatePassword.title")}
      extra={
        <>
          <Button
            icon={<InfoCircleFilled />}
            onClick={() => navigate(PATHS.accountMe)}
          >
            {t("pages.updatePassword.actions.accountInfo")}
          </Button>
        </>
      }
    >
      <Spin spinning={isLoading}>
        <Card
          bordered={false}
          actions={[
            <Space
              key="footer-buttons"
              wrap
              style={{
                float: "right",
                marginRight: 24,
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => handleupdatePassword(values))
                    .catch((error) => console.log("error", error));
                }}
              >
                {t("buttons.save")}
              </Button>
            </Space>,
          ]}
        >
          <Form<IUpdatePasswordTypes> layout="vertical" form={form}>
            <Form.Item
              label={t("pages.updatePassword.fields.currentPass")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("pages.updatePassword.errors.passwordRequired"),
                },
              ]}
            >
              <Input.Password
                placeholder={PASSWORD_PLACEHOLDER}
                autoComplete="current-password"
              />
            </Form.Item>

            <Form.Item
              label={t("pages.updatePassword.fields.newPass")}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: t("pages.updatePassword.errors.newPassRequired"),
                },
              ]}
            >
              <Input.Password
                placeholder={PASSWORD_PLACEHOLDER}
                autoComplete="new-password"
              />
            </Form.Item>

            <Form.Item
              label={t("pages.updatePassword.fields.confirmPass")}
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: t("pages.updatePassword.errors.confirmPassRequired"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t("pages.updatePassword.errors.confirmPassNotMatch")
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={PASSWORD_PLACEHOLDER}
                autoComplete="new-password"
              />
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </PageHeader>
  );
};

export default UpdatePassword;
