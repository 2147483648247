import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Image, Row, Tabs, TabsProps } from "antd";
import TextBox from "components/textBox";
import WithLabel from "components/withLabel";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { ICustomer } from "interfaces";
import React, { useState } from "react";
import { CustomerReport } from "./private/CustomerReport";
import { CustomerSales } from "./private/CustomerSales";

export const CustomerShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { queryResult } = useShow<ICustomer, HttpError>({
    meta,
  });

  const { data, isLoading, isFetching, isRefetching } = queryResult;

  const record = data?.data;

  const isProcessing = isLoading ?? isFetching ?? isRefetching;

  const showCommon = (
    <Row gutter={[16, 24]}>
      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.name ?? "--"}
          label={t("customers.fields.name")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.phoneNumber ?? "--"}
          label={t("customers.fields.phone")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.address ?? "--"}
          label={t("customers.fields.address")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.area?.name ?? "--"}
          label={t("customers.fields.area")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.employee?.name ?? "--"}
          label={t("customers.fields.employee")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={
            record?.status
              ? t(`customers.fields.status_${record?.status}`)
              : "--"
          }
          label={t("customers.fields.status")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.totalPriceInMonth ?? "--"}
          label={t("customers.fields.totalPriceInMonth")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.completeOrderNo ?? "--"}
          label={t("customers.fields.completeOrderNo")}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.pendingOrderNo ?? "--"}
          label={t("customers.fields.pendingOrderNo")}
        />
      </Col>

      <Col xs={24}>
        <WithLabel label={t("customers.fields.image")}>
          <Image src={record?.image} style={{ maxWidth: 64, maxHeight: 64 }} />
        </WithLabel>
      </Col>
    </Row>
  );

  const [activeKey, setActiveKey] = useState("common");

  const tabItemCommon = {
    key: "common",
    label: t("customers.tabs.common.title"),
    children: <>{showCommon}</>,
  };

  const tabItemReports = {
    key: "reports",
    label: t("customers.tabs.reports.title"),
    children: record?.id ? <CustomerReport customerId={record?.id} /> : null,
  };

  const tabItemSales = {
    key: "sales",
    label: t("customers.tabs.sales.title"),
    children: record?.id ? <CustomerSales customerId={record?.id} /> : null,
  };

  const items: TabsProps["items"] = [
    tabItemCommon,
    tabItemReports,
    tabItemSales,
  ];

  return (
    <Show
      isLoading={isProcessing}
      headerButtons={
        <>
          {/* <ListButton disabled={isProcessing} /> */}
          {resource?.canEdit && activeKey === "common" && (
            <EditButton disabled={isProcessing} type="primary" />
          )}
          {resource?.meta?.canDelete && activeKey === "common" && (
            <DeleteButton
              disabled={isProcessing}
              meta={meta}
              onSuccess={() => {
                list(API_RESOURCES.customers);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("customers.notification.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("customers.notification.deleteSuccess"),
              }}
            />
          )}
          {/* <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
        </>
      }
    >
      <Tabs items={items} activeKey={activeKey} onChange={setActiveKey} />
    </Show>
  );
};
