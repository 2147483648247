import { useTranslate } from "@refinedev/core";
import { Layout as AntdLayout, Typography } from "antd";
import React from "react";
import { Header as HeaderToRender } from "../header";

import {
  DEFAULT_SIDER_DARK_BG_COLOR,
  DEFAULT_SIDER_UNAUTHENTICATED_WIDTH,
} from "config";
import { Title as LayoutTitle } from "../title";

import imgCover from "assets/img_cover.png";

const { Title } = Typography;

interface CreateCompanyLayoutProps {
  children: React.ReactNode;
}

export const CreateCompanyLayout: React.FC<CreateCompanyLayoutProps> = ({
  children,
}) => {
  const t = useTranslate();

  return (
    <AntdLayout style={{ height: "100vh" }}>
      <AntdLayout.Sider
        breakpoint="lg"
        width={DEFAULT_SIDER_UNAUTHENTICATED_WIDTH}
        collapsedWidth={0}
        trigger={null}
        style={{
          backgroundColor: DEFAULT_SIDER_DARK_BG_COLOR,
          backgroundImage: `url(${imgCover})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left top",
          backgroundSize: "cover",
        }}
      >
        <LayoutTitle
          collapsed={false}
          imgWidth={90}
          title={
            <Title level={3} style={{ color: "#fff", marginBottom: 0 }}>
              {t("site.title")}
            </Title>
          }
          linkStyle={{
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
            marginTop: 50,
            marginLeft: 20,
          }}
        />
      </AntdLayout.Sider>

      <AntdLayout>
        <HeaderToRender />
        <AntdLayout.Content
          style={{ margin: "auto", overflowY: "auto", width: "100%" }}
        >
          <div style={{ padding: 15 }}>{children}</div>
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  );
};
