import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { useMemo, useState } from "react";

import { Spin } from "antd";
import imgTrend1 from "assets/img_trend_1.svg";
import imgTrend2 from "assets/img_trend_2.svg";
import { BarChartWidget } from "components/chart";
import { RangeValue, SelectDateType } from "components/selectDate";
import {
  DEFAULT_DAYS_FOR_REPORTS,
  DEFAULT_MONTHS_FOR_REPORTS,
  DEFAULT_YEARS_FOR_REPORTS,
  SERVICE_MOBISALE,
} from "config";
import { useCompanyId } from "hooks/useCompanyId";
import {
  IEmployee,
  IEmployeeSaleReportItem,
  IOrderReportItem,
  IWithPaging,
} from "interfaces";
import {
  rangeDayFilters,
  rangeMonthFilters,
  rangeYearFilters,
} from "utils/dayjsHelper";
import { toMillions } from "utils/numberHelper";

interface PharRepReportProps {
  employee: IEmployee;
}

export const PharRepReport: React.FC<PharRepReportProps> = ({ employee }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        marginTop: 24,
      }}
    >
      <PharRepItemSalesReport employee={employee} />
      <PharRepOrdersReport employee={employee} />
      <PharRepMonthIncomeReports employee={employee} />
    </div>
  );
};

type PharRepItemSalesReportProps = PharRepReportProps;

const PharRepItemSalesReport: React.FC<PharRepItemSalesReportProps> = ({
  employee,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const companyId = useCompanyId();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<IEmployeeSaleReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/orders/report/employee`,
    method: "get",
    queryOptions: {
      enabled: Boolean(
        rangeValue?.[0] && rangeValue?.[1] && employee?.id && type
      ),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        employeeId: employee?.id,
        companyId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return toMillions(
      data?.data?.content?.map((item) => item.total_price ?? 0) ?? []
    );
  }, [data]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("employees.tabs.common.charts.titles.itemSales")}
        image={imgTrend1}
        series={[
          {
            name: t("employees.tabs.common.charts.money_unit"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("employees.tabs.common.charts.money_unit")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
      />
    </Spin>
  );
};

type PharRepOrdersReportProps = PharRepReportProps;

const PharRepOrdersReport: React.FC<PharRepOrdersReportProps> = ({
  employee,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const companyId = useCompanyId();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<IOrderReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/employees/report/order_no`,
    method: "get",
    queryOptions: {
      enabled: Boolean(
        rangeValue?.[0] && rangeValue?.[1] && employee?.id && type
      ),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        employeeId: employee?.id,
        companyId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return data?.data?.content?.map((item) => item.totalNo ?? 0) ?? [];
  }, [data]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("employees.tabs.common.charts.titles.orders")}
        image={imgTrend2}
        series={[
          {
            name: t("employees.tabs.common.charts.bill"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("employees.tabs.common.charts.bill")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
      />
    </Spin>
  );
};

type PharRepMonthIncomeProps = PharRepReportProps;

const PharRepMonthIncomeReports: React.FC<PharRepMonthIncomeProps> = ({
  employee,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const companyId = useCompanyId();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<IEmployeeSaleReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/orders/report/employee`,
    method: "get",
    queryOptions: {
      enabled: Boolean(
        rangeValue?.[0] && rangeValue?.[1] && employee?.id && type
      ),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        employeeId: employee?.id,
        companyId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  console.log({ employee });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return toMillions(
      data?.data?.content?.map(
        (item) =>
          ((item.total_price ?? 0) * (employee?.commissionRate ?? 0)) / 100
      ) ?? []
    );
  }, [data, employee?.commissionRate]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("employees.tabs.common.charts.titles.monthIncome")}
        image={imgTrend2}
        series={[
          {
            name: t("employees.tabs.common.charts.money_unit"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("employees.tabs.common.charts.money_unit")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
      />
    </Spin>
  );
};
