import { EditOutlined, LockOutlined } from "@ant-design/icons";
import { PageHeader } from "@refinedev/antd";
import {
  useApiUrl,
  useCustom,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Button, Card, Spin, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { EmployeeCommon } from "components/profile";
import { API_RESOURCES, PATHS, SERVICE_MOBISALE } from "config";
import { IEmployee } from "interfaces";
import { BillingInfo } from "./private/BillingInfo";
import { CompanyInfo } from "./private/CompanyInfo";

const AccountInfo: React.FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const { edit } = useNavigation();

  const apiUrl = useApiUrl();

  const { data, isLoading } = useCustom<IEmployee>({
    url: `${apiUrl}/${SERVICE_MOBISALE}/employees/profile`,
    method: "get",
  });

  const user = data?.data;

  const [activeKey, setActiveKey] = useState("common");

  const tabItemCommon = {
    key: "common",
    label: t("pages.accountInfo.tabs.common.title"),
    children: <EmployeeCommon data={user} />,
  };

  const tabItemCompany = {
    key: "company",
    label: t("pages.accountInfo.tabs.company.title"),
    children: <CompanyInfo data={user?.company} />,
  };

  const tabItemBilling = {
    key: "billing",
    label: t("pages.accountInfo.tabs.billing.title"),
    children: <BillingInfo />,
  };

  const items: TabsProps["items"] = [
    tabItemCommon,
    ...(user?.role === "ADMIN" || user?.role === "DIRECTOR"
      ? [tabItemCompany, tabItemBilling]
      : []),
  ];

  return (
    <Spin spinning={isLoading}>
      <PageHeader
        title={t("pages.accountInfo.title")}
        extra={
          <>
            {activeKey === "common" && (
              <Button
                icon={<EditOutlined />}
                onClick={() => edit(API_RESOURCES.employees, user?.id ?? "")}
              >
                {t("pages.accountInfo.actions.update")}
              </Button>
            )}
            <Button
              type="primary"
              icon={<LockOutlined />}
              onClick={() => navigate(PATHS.updatePassword)}
            >
              {t("pages.accountInfo.actions.changePassword")}
            </Button>
          </>
        }
      >
        <Card bordered={false}>
          <Tabs items={items} activeKey={activeKey} onChange={setActiveKey} />
        </Card>
      </PageHeader>
    </Spin>
  );
};

export default AccountInfo;
