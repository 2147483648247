import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import { useCallback, useState } from "react";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function usePreviewImage() {
  const [previewOpen, setPreviewOpen] = useState(false);

  const [previewImage, setPreviewImage] = useState("");

  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = useCallback(() => setPreviewOpen(false), []);

  const handlePreview = useCallback(
    async (
      file: UploadFile | { url: string; name: string; preview?: string }
    ) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(
          (file as UploadFile).originFileObj as RcFile
        );
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
      );
    },
    []
  );

  return {
    previewOpen,
    previewImage,
    previewTitle,
    handleCancel,
    handlePreview,
  };
}
