import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select, Space, Switch, Typography } from "antd";
import {
  API_RESOURCES,
  DEFAULT_TEXTAREA_MAX_LENGTH,
  SERVICE_MOBISALE,
} from "config";
import {
  IEmployee,
  IUpdateWarehouseStatusTypes,
  IUserRoleCode,
  IWarehouse,
} from "interfaces";
import React from "react";
import { formatName } from "utils/commonHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const WarehouseEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { formProps, saveButtonProps, queryResult } = useForm<
    IWarehouse,
    HttpError
  >({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("warehouses.notification.editError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("warehouses.notification.editSuccess"),
    },
  });

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  const record = queryResult?.data?.data;

  const {
    selectProps: {
      showSearch: showSearchEmployee,
      onSearch: onSearchEmployee,
      ...employeeSelectProps
    },
    queryResult: employeeQueryResult,
  } = useSelect<IEmployee, HttpError>({
    resource: API_RESOURCES.employees,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    filters: [
      {
        field: "role",
        operator: "eq",
        value: "INV_MANAGEMENT" as IUserRoleCode,
      },
    ],
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const employeeOptions = employeeQueryResult?.data?.data
    ?.filter(
      (employee) =>
        !employee?.warehouseId ||
        employee?.warehouseId === queryResult?.data?.data.id
    )
    .map((item) => ({
      value: item.id,
      label: formatName(item.firstName, item.lastName),
    }));

  const apiUrl = useApiUrl();

  const invalidate = useInvalidate();

  const { mutate: updateWarehouseStatus, isLoading: isUpdatingStatus } =
    useCustomMutation<IUpdateWarehouseStatusTypes>();

  const handleUpdateWarehouseStatus = (values: IUpdateWarehouseStatusTypes) => {
    updateWarehouseStatus(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/warehouses/action`,
        method: "post",
        values: {
          ...values,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("warehouses.notification.updateStatusError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("warehouses.notification.updateStatusSuccess"),
        },
      },
      {
        onSuccess() {
          invalidate({
            resource: API_RESOURCES.warehouses,
            invalidates: ["detail"],
            id: record?.id,
          });
        },
      }
    );
  };

  // TODO: workaround to update warehouse,
  // Remove these code later after BE update API update
  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: updateWarehouse } = useCustomMutation<IWarehouse>();

  const handleUpdateWarehouse = async (values: IWarehouse) => {
    updateWarehouse(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/warehouses/update`,
        method: "put",
        values: {
          ...values,
          id: queryResult?.data?.data?.id,
          managerId: values?.manager?.id,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("warehouses.notification.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("warehouses.notification.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.warehouses);
          }, 100);
        },
      }
    );
  };
  //

  return (
    <Edit
      title={
        <Space size="small" style={{ alignItems: "center" }}>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {t("warehouses.titles.edit")}
          </Typography.Title>
          <Switch
            disabled
            loading={isUpdatingStatus}
            checked={record?.warehouseStatus === "ACTIVE"}
            onChange={(checked) => {
              handleUpdateWarehouseStatus({
                warehouseId: String(record?.id),
                status: checked ? "ACTIVE" : "INACTIVE",
              });
            }}
          />
        </Space>
      }
      headerButtons={
        <>
          <ShowButton />
          {/* <ListButton disabled={isProcessing} />
          <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
        </>
      }
      footerButtons={
        <>
          {resource?.meta?.canDelete && (
            <DeleteButton
              disabled={isProcessing}
              meta={meta}
              onSuccess={() => {
                list(API_RESOURCES.warehouses);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("warehouses.notification.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("warehouses.notification.deleteSuccess"),
              }}
            />
          )}
          <SaveButton disabled={isProcessing} {...saveButtonProps} />
        </>
      }
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => handleUpdateWarehouse(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("warehouses.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("warehouses.errors.nameRequired"),
                },
              ]}
            >
              <Input placeholder={t("warehouses.placeholders.name")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("warehouses.fields.manager")}
              name={["manager", "id"]}
            >
              <Select
                allowClear
                {...employeeSelectProps}
                placeholder={t("warehouses.placeholders.manager")}
                options={employeeOptions}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("warehouses.fields.address")}
              name={["address"]}
            >
              <Input placeholder={t("warehouses.placeholders.address")} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("warehouses.fields.description")}
              name={["description"]}
            >
              <Input.TextArea
                rows={3}
                placeholder={t("warehouses.placeholders.description")}
                maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
