import { PlusOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Upload as AntUpload, Modal, UploadProps } from "antd";

import useCustomUpload from "hooks/useCustomUpload";
import usePreviewImage from "hooks/usePreviewImage";

const UploadImage = ({
  listType = "picture-card",
  accept = "image/*",
  children,
  ...rest
}: React.PropsWithChildren<UploadProps>) => {
  const t = useTranslate();

  const { uploadFile } = useCustomUpload();

  const {
    previewOpen,
    previewImage,
    previewTitle,
    handleCancel,
    handlePreview,
  } = usePreviewImage();

  return (
    <>
      <AntUpload
        listType={listType}
        accept={accept}
        customRequest={uploadFile}
        onPreview={handlePreview}
        {...rest}
      >
        {children || (
          <div>
            <PlusOutlined />
            <div>{t("common.uploadDescription")}</div>
          </div>
        )}
      </AntUpload>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="img-preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadImage;
