import { RefineLayoutHeaderProps } from "@refinedev/antd";
import {
  Layout as AntdLayout,
  Space,
  Spin,
  Typography,
  // Switch,
  // Typography,
  theme,
} from "antd";
// import { useContext } from "react";

// import { ColorModeContext } from "../../../contexts/color-mode";
// import { LanguageDropdown } from "./LanguageDropdown";
import {
  useApiUrl,
  useCustom,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { useCompanyId } from "hooks/useCompanyId";
import { IEmployee } from "interfaces";
import { MenuPopover } from "./MenuPopover";
import { NotificationPopover } from "./NotificationPopover";
// import { NotificationPopover } from "./NotificationPopover";

import imgReward from "assets/img_reward.svg";

// const { Text } = Typography;
const { useToken } = theme;

type HeaderProps = RefineLayoutHeaderProps;

export const Header: React.FC<HeaderProps> = () => {
  const t = useTranslate();

  const { resource } = useResource();

  const { token } = useToken();

  const hasCompany = useCompanyId();

  // const { data: user } = useGetIdentity<IUserIdentity>();

  // const { mode, setMode } = useContext(ColorModeContext);

  const apiUrl = useApiUrl();

  const { data, isLoading: isLoadingProfile } = useCustom<IEmployee>({
    url: `${apiUrl}/${SERVICE_MOBISALE}/employees/profile`,
    method: "get",
    queryOptions: {
      enabled: Boolean(hasCompany),
    },
  });

  const user = data?.data;

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        borderBottom: "1px solid #eaeaea",
        // boxShadow:
        //   "0 10px 15px -3px rgba(0,0,0,.04),0 4px 6px -2px rgba(0,0,0,.02)",
        // boxShadow:
        //   "0px 2px 4px -2px rgba(17, 15, 71, 0.12), 0px 4px 4px -2px rgba(17, 15, 71, 0.08)",
      }}
    >
      {hasCompany ? (
        <Spin spinning={isLoadingProfile}>
          <div style={{ display: "flex", alignItems: "start", gap: 6 }}>
            <img src={imgReward} alt={"companny"} />

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Title
                level={5}
                style={{ color: "#00238A", margin: 0 }}
              >
                {user?.company?.name}
              </Typography.Title>
              <Typography.Text style={{ fontWeight: 500 }}>
                {resource?.name && resource?.name !== API_RESOURCES.splash
                  ? t(`${resource?.name}.${resource?.name}`)
                  : ""}
              </Typography.Text>
            </div>
          </div>
        </Spin>
      ) : (
        <div />
      )}

      <Space size="large">
        {hasCompany && <NotificationPopover />}

        {/* <LanguageDropdown /> */}

        {/* <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
        /> */}

        {/* <Space style={{ marginLeft: "8px" }} size="middle">
          {user?.name && <Text strong>{user.name}</Text>}
          {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
        </Space> */}

        <MenuPopover />
      </Space>
    </AntdLayout.Header>
  );
};
