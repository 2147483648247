import { DownloadOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useCustomMutation,
  useInvalidate,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Button, Input, Space, Switch, Table } from "antd";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IArea, IUpdateAreaStatusTypes } from "interfaces";
import React, { useMemo, useState } from "react";
import { convertToAreaTree } from "./utils";

export const AreaList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const t = useTranslate();

  const { hasAdmin, hasDirector } = useUserRole();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps } = useTable<IArea, HttpError>({
    syncWithLocation: false,
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    meta,
  });

  const [keyword, setKeyword] = useState("");
  const [applyKeyword, setApplyKeyword] = useState("");

  const dataSource = useMemo(() => {
    return convertToAreaTree(tableProps.dataSource ?? [], applyKeyword);
  }, [tableProps.dataSource, applyKeyword]);

  const { run: searchArea } = useDebounceFn(
    () => {
      setApplyKeyword(keyword);
    },
    {
      wait: 300,
    }
  );

  const apiUrl = useApiUrl();

  const { isRefetching: isExportingAreas, refetch: exportAreas } = useCustom({
    url: `${apiUrl}/${SERVICE_MOBISALE}/export/areas`,
    method: "get",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("areas.notification.exportError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("areas.notification.exportSuccess"),
    },
  });

  const handleExport = () => {
    exportAreas();
  };

  const invalidate = useInvalidate();

  const { mutate: updateAreaStatus, isLoading: isUpdatingStatus } =
    useCustomMutation<IUpdateAreaStatusTypes>();

  const handleUpdateAreaStatus = (values: IUpdateAreaStatusTypes) => {
    updateAreaStatus(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/areas/action`,
        method: "post",
        values: {
          ...values,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("areas.notification.updateStatusError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("areas.notification.updateStatusSuccess"),
        },
      },
      {
        onSuccess() {
          invalidate({
            resource: API_RESOURCES.areas,
            invalidates: ["list"],
          });
        },
      }
    );
  };

  return (
    <List
      headerButtons={
        <>
          <Input.Search
            allowClear
            placeholder={t("placeholders.input_search")}
            style={{ width: 230 }}
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
            onSearch={() => searchArea()}
          />

          {(hasAdmin || hasDirector) && (
            <Button
              icon={<DownloadOutlined />}
              shape="circle"
              onClick={handleExport}
              loading={isExportingAreas}
              disabled={isExportingAreas}
            />
          )}

          {resource?.canCreate && <CreateButton />}
        </>
      }
    >
      <Table
        {...tableProps}
        dataSource={dataSource}
        pagination={false}
        rowKey="id"
      >
        <Table.Column dataIndex="idx" title={t("areas.fields.idx")} />
        <Table.Column dataIndex="name" title={t("areas.fields.name")} />
        <Table.Column
          dataIndex={["employee", "name"]}
          title={t("areas.fields.employee")}
        />
        <Table.Column
          dataIndex="totalPriceInMonth"
          title={t("areas.fields.totalPriceInMonth")}
        />
        <Table.Column<IArea>
          dataIndex="area-status"
          key="area-status"
          title={t("areas.fields.status")}
          render={(_, record) => (
            <Switch
              disabled
              loading={isUpdatingStatus}
              checked={record.status === "ACTIVE"}
              onChange={(checked) => {
                handleUpdateAreaStatus({
                  areaId: String(record.id),
                  status: checked ? "ACTIVE" : "INACTIVE",
                });
              }}
            />
          )}
        />
        <Table.Column
          title={t("areas.fields.actions")}
          dataIndex="actions"
          render={(_, record: IArea) => (
            <Space>
              {resource?.canShow && (
                <ShowButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.canEdit && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.meta?.canDelete && (
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  meta={meta}
                  errorNotification={(error) => {
                    return {
                      type: "error",
                      message:
                        (error as any)?.message ??
                        t("areas.notification.deleteError"),
                    };
                  }}
                  successNotification={{
                    type: "success",
                    message: t("areas.notification.deleteSuccess"),
                  }}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
