import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useGetIdentity,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import TextBox from "components/textBox";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { ICustomer, IOrder, IProductQuanity, IUserIdentity } from "interfaces";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { EMPLOYEE_KEY, UPDATE_PRODUCTS_KEY } from "./private/constants";
import { ProductTable } from "./private/productTable";

export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const { data: user } = useGetIdentity<IUserIdentity>();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const {
    formProps: { form: formCreateOrder, ...restFormCreateOrder },
    saveButtonProps,
    queryResult,
  } = useForm<IOrder, HttpError>({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("orders.notification.editError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("orders.notification.editSuccess"),
    },
  });

  // TODO: workaround to update warehouse,
  // Remove these code later after BE update API update
  const { setWarnWhen } = useWarnAboutChange();

  const apiUrl = useApiUrl();

  const { mutate: updateOrder } = useCustomMutation<IOrder>();

  const handleOrder = async (values: IOrder) => {
    updateOrder(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/orders/update`,
        method: "put",
        values: {
          ...values,
          orderId: queryResult?.data?.data?.id,
          customerId: customerId,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("orders.notification.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("orders.notification.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.orders);
          }, 100);
        },
      }
    );
  };
  //

  const {
    selectProps: {
      showSearch: showSearchCustomer,
      onSearch: onSearchCustomer,
      ...customerSelectProps
    },
    queryResult: customerQueryResult,
  } = useSelect<ICustomer, HttpError>({
    resource: API_RESOURCES.customers,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // filters: [
    //   {
    //     field: "status",
    //     operator: "eq",
    //     value: "APPROVED" as ICustomerStatus,
    //   },
    // ],
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  // TODO: using filters server
  const customerOptions = customerQueryResult?.data?.data
    ?.filter((item) => item.status === "APPROVED")
    ?.map((item) => ({
      value: item.id,
      label: `${item.name} (${item.phoneNumber})`,
    }));

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  const initProductList = useMemo(
    () => queryResult?.data?.data?.orderItems || [],
    [queryResult]
  );

  const [productList, setProductList] = useState<IProductQuanity[]>([]);

  const updateProductList = useCallback(
    (newProductList: IProductQuanity[]) => {
      setProductList(newProductList);
      formCreateOrder?.setFieldsValue({
        [UPDATE_PRODUCTS_KEY]: newProductList.map((item) => ({
          ...item,
          productId: item.product?.id,
        })),
      });
    },
    [formCreateOrder]
  );

  useEffect(() => {
    updateProductList(
      initProductList.map((product, index) => ({
        ...product,
        quantity: product.quantity ?? product.requestQuantity ?? 0,
        key: index + 1,
      }))
    );
  }, [initProductList, updateProductList]);

  const customerId = Form.useWatch(["customer", "id"], formCreateOrder);

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={
        <>
          {/* <ListButton disabled={isProcessing} />
          <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
          <ShowButton />
        </>
      }
      footerButtons={
        <>
          {resource?.meta?.canDelete && (
            <DeleteButton
              disabled={
                isProcessing || queryResult?.data?.data?.status !== "CREATED"
              }
              meta={meta}
              onSuccess={() => {
                list(API_RESOURCES.orders);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("orders.notification.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("orders.notification.deleteSuccess"),
              }}
            />
          )}
          <SaveButton
            disabled={
              isProcessing || queryResult?.data?.data?.status !== "CREATED"
            }
            {...saveButtonProps}
          />
        </>
      }
    >
      <Form
        form={formCreateOrder}
        {...restFormCreateOrder}
        layout="vertical"
        initialValues={{
          ...restFormCreateOrder.initialValues,
          [EMPLOYEE_KEY]: user?.id,
        }}
        onFinish={(values) => handleOrder(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("orders.fields.customer")}
              name={["customer", "id"]}
              rules={[
                {
                  required: true,
                  message: t("orders.errors.customerRequired"),
                },
              ]}
            >
              <Select
                {...customerSelectProps}
                options={customerOptions}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          {customerId && (
            <Col xs={24} md={12} lg={8}>
              <TextBox
                value={
                  customerQueryResult?.data?.data?.find(
                    (item) => item.id === customerId
                  )?.address ?? "--"
                }
                label={t("orders.fields.customerAddress")}
              />
            </Col>
          )}

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={
                queryResult?.data?.data?.status
                  ? t(`orderStatus.${queryResult?.data?.data?.status}`)
                  : "--"
              }
              label={t(`orders.fields.status`)}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item name={UPDATE_PRODUCTS_KEY}>
              <Input hidden />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item name={EMPLOYEE_KEY}>
              <Input hidden />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <ProductTable
              productList={productList}
              onProductListChanged={(newProductList) => {
                updateProductList(newProductList);
              }}
              hasAdd
              hasEdit
              hasDelete
            />
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
