import { Create, useForm } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import UploadImage from "components/upload/uploadImage";
import {
  DEFAULT_PRODUCTS_DESCRIPTION_MAX_LENGTH,
  MONEY_UNIT,
  PRODUCT_UNIT_LIST,
  SERVICE_MOBISALE,
} from "config";
import { IProduct, IProductWarehouseQuanity } from "interfaces";
import React, { useCallback, useState } from "react";
import { getFileUrlsFromEvent, getFileValueProps } from "utils/formHelper";
import { WarehouseTable } from "./private/WarehouseTable";
import { WAREHOUSES_KEY } from "./private/constants";
import { DEFAULT_MAX_IMAGE_COUNT } from "./utils";

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { formProps, saveButtonProps } = useForm<IProduct, HttpError>({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("products.notification.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("products.notification.createSuccess"),
    },
  });

  const [warehouseList, setWarehouseList] = useState<
    IProductWarehouseQuanity[]
  >([]);

  const updateWarehouseList = useCallback(
    (newWarehouseList: IProductWarehouseQuanity[]) => {
      setWarehouseList(newWarehouseList);
      formProps?.form?.setFieldsValue({
        [WAREHOUSES_KEY]: newWarehouseList.map((item) => ({
          ...item,
          warehouseId: item.warehouse?.id,
        })),
      });
    },
    [formProps?.form]
  );

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("products.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("products.errors.nameRequired"),
                },
              ]}
            >
              <Input placeholder={t("products.placeholders.name")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("products.fields.price")}
              name={["price"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("products.errors.priceRequired"),
              //   },
              // ]}
            >
              <Input
                type="number"
                placeholder={t("products.placeholders.price")}
                addonAfter={MONEY_UNIT}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("products.fields.unit")} name={["unit"]}>
              <Select placeholder={t("products.placeholders.unit")} allowClear>
                {PRODUCT_UNIT_LIST(t).map((unit) => (
                  <Select.Option key={unit.value} value={unit.label}>
                    {unit.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("products.fields.description")}
              name={["description"]}
            >
              <Input.TextArea
                rows={10}
                placeholder={t("products.placeholders.description")}
                maxLength={DEFAULT_PRODUCTS_DESCRIPTION_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("products.fields.images")}
              name="images"
              getValueFromEvent={(e) => {
                const fileUrls = getFileUrlsFromEvent(e);
                return fileUrls;
              }}
              getValueProps={(value) => {
                const imgs = getFileValueProps(
                  value,
                  t(`products.fields.images`)
                );
                return {
                  defaultFileList: imgs,
                };
              }}
            >
              <UploadImage maxCount={DEFAULT_MAX_IMAGE_COUNT} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <WarehouseTable
              warehouseList={warehouseList}
              onWarehouseListChanged={(newWarehouseList) => {
                updateWarehouseList(newWarehouseList);
              }}
              hasAdd
              hasEdit
              hasDelete
            />
          </Col>
        </Row>

        <Form.Item name={WAREHOUSES_KEY}>
          <Input hidden />
        </Form.Item>
      </Form>
    </Create>
  );
};
