import { useIsAuthenticated } from "@refinedev/core";
import { useCompanyId } from "hooks/useCompanyId";
import { Navigate } from "react-router-dom";

interface CompanyGuardProps {
  children: React.ReactNode;
}

export const CompanyGuard: React.FC<CompanyGuardProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  const hasCompany = useCompanyId();

  if (!isAuthenticated) {
    return null;
  }

  if (!hasCompany) {
    return <Navigate to="/create-company" />;
  }

  return <>{children}</>;
};
