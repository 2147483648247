import { DownloadOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  LogicalFilter,
  useApiUrl,
  useCustom,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Button, Form, Input, Space, Table } from "antd";
import dayjs from "dayjs";
import React from "react";

import { HighLightOrderStatus } from "components/orders";
import { DATE_FORMAT, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IOrder } from "interfaces";
import { FilterModal } from "./private/FilterModal";

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const t = useTranslate();

  const { hasPharRep, hasAdmin, hasDirector, roleCode } = useUserRole();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps, searchFormProps, filters, setFilters } = useTable<
    IOrder,
    HttpError
  >({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    meta,
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const apiUrl = useApiUrl();

  const { isRefetching: isExportingOrders, refetch: exportOrders } = useCustom({
    url: `${apiUrl}/${SERVICE_MOBISALE}/export/orders`,
    method: "get",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("orders.notification.exportError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("orders.notification.exportSuccess"),
    },
  });

  const handleExport = () => {
    exportOrders();
  };

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchOrder } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <List
      headerButtons={
        <>
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q" style={{ marginBottom: 0 }}>
              <Input.Search
                allowClear
                placeholder={t("placeholders.input_search")}
                style={{ width: 230 }}
                onSearch={() => searchOrder()}
              />
            </Form.Item>
          </Form>

          <FilterModal
            filters={filters}
            onFilterChange={({
              areaId,
              status,
              productId,
              startDate,
              endDate,
            }) => {
              setFilters((filters) => {
                const finalFilters: CrudFilters = filters
                  .filter((filter) => {
                    const lFilter = filter as LogicalFilter;
                    return (
                      lFilter.field !== "areaId" &&
                      lFilter.field !== "status" &&
                      lFilter.field !== "productId" &&
                      lFilter.field !== "startDate" &&
                      lFilter.field !== "endDate"
                    );
                  })
                  .concat({ field: "areaId", operator: "eq", value: areaId })
                  .concat({ field: "status", operator: "eq", value: status })
                  .concat({
                    field: "productId",
                    operator: "eq",
                    value: productId,
                  })
                  .concat({
                    field: "startDate",
                    operator: "eq",
                    value: startDate,
                  })
                  .concat({ field: "endDate", operator: "eq", value: endDate });
                return finalFilters;
              });
            }}
          />

          {(hasAdmin || hasDirector) && (
            <Button
              icon={<DownloadOutlined />}
              shape="circle"
              onClick={handleExport}
              loading={isExportingOrders}
              disabled={isExportingOrders}
            />
          )}

          {resource?.canCreate && hasPharRep && <CreateButton />}
        </>
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("orders.fields.idx")} />
        <Table.Column dataIndex="code" title={t("orders.fields.code")} />
        <Table.Column
          dataIndex={["customer", "name"]}
          title={t("orders.fields.customer")}
        />
        <Table.Column
          dataIndex={["customer", "address"]}
          title={t("orders.fields.customerAddress")}
        />
        <Table.Column
          dataIndex={["warehouseManager", "name"]}
          title={t("orders.fields.warehouseManager")}
        />
        <Table.Column
          dataIndex="sentTime"
          title={t("orders.fields.sentTime")}
          render={(sentTime) =>
            sentTime ? dayjs(sentTime).format(DATE_FORMAT) : null
          }
        />
        <Table.Column
          dataIndex="updatedAt"
          title={t("orders.fields.replyTime")}
          render={(updatedAt) =>
            updatedAt ? dayjs(updatedAt).format(DATE_FORMAT) : null
          }
        />
        <Table.Column
          dataIndex="status"
          title={t("orders.fields.status")}
          render={(status) => (
            <HighLightOrderStatus status={status} roleCode={roleCode} />
          )}
        />
        <Table.Column
          title={t("orders.fields.actions")}
          dataIndex="actions"
          render={(_, record: IOrder) => (
            <Space>
              {resource?.canShow && (
                <ShowButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.canEdit &&
                hasPharRep &&
                record.status === "CREATED" && (
                  <EditButton hideText size="small" recordItemId={record.id} />
                )}
              {resource?.meta?.canDelete &&
                hasPharRep &&
                record.status === "CREATED" && (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    meta={meta}
                    errorNotification={(error) => {
                      return {
                        type: "error",
                        message:
                          (error as any)?.message ??
                          t("orders.notification.deleteError"),
                      };
                    }}
                    successNotification={{
                      type: "success",
                      message: t("orders.notification.deleteSuccess"),
                    }}
                  />
                )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
