import { StarOutlined } from "@ant-design/icons";
import { Divider, Grid, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { BILLING_CICRLE } from "config";
import { IMobisaleAttributeDetail, IMobisalePackage } from "interfaces";
import { formatCurrency } from "utils/numberHelper";

interface PackageItemProps extends IMobisalePackage {
  active?: boolean;
  actionBtn?: React.ReactNode;
}

export const PackageItem: React.FC<PackageItemProps> = ({
  active,
  actionBtn,
  name,
  price,
  duration,
  attributes,
}) => {
  const { t } = useTranslation();

  const breakpoint = Grid.useBreakpoint();

  return (
    <div
      style={{
        background: "#F6F6F6",
        borderRadius: 24,
        padding: breakpoint.xs ? 20 : 32,
        border: "1px solid rgba(145, 158, 171, 0.16)",
        boxShadow: active ? "rgb(33, 43, 54) 0px 0px 0px 2px" : "none",
        transition: "all 0.2s",
        cursor: "default",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {active && (
        <div style={{ position: "absolute", top: 15, right: 15 }}>
          <Tag color="#87d068" icon={<StarOutlined />}>
            {t("common.current_package")}
          </Tag>
        </div>
      )}

      <div
        className="name"
        style={{
          color: "#272E35",
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        {name}
      </div>

      <div className="price">
        <span
          style={{
            color: "#272E35",
            fontWeight: 600,
            fontSize: 26,
          }}
        >
          {formatCurrency(price ?? 0)}
        </span>
        <span> VNĐ / {t("common.month")}</span>
      </div>

      <Divider />

      <div
        className="duration-title"
        style={{ color: "#6E757C", fontSize: 12 }}
      >
        {t("pages.accountInfo.tabs.billing.duration")}
      </div>

      <div className="duration">
        {!price || !duration ? (
          <span
            style={{
              color: active ? "#25282B" : "#272E35",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            {t("common.no_extend")}
          </span>
        ) : (
          <>
            <span
              style={{
                color: active ? "#25282B" : "#272E35",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              {`${Math.floor((duration ?? 0) / 30) * BILLING_CICRLE} ${t(
                "common.month"
              )}`}
            </span>{" "}
            <span
              style={{
                color: active ? "#25282B" : "#272E35",
                fontSize: 22,
              }}
            >
              / {t("common.time")}
            </span>
          </>
        )}
      </div>

      {attributes && (
        <ul
          className="attributes"
          style={{ padding: "0px 0px 0px 20px", color: "#000000E0", flex: 1 }}
        >
          {Object.values(attributes).map((attribute, index) => {
            const attrDetail = attribute as IMobisaleAttributeDetail;
            return (
              <li key={`attribute-${index}`}>
                {`${t("common.maximum")} ${
                  attrDetail.max
                } ${attrDetail.desc?.toLocaleLowerCase()}`}
              </li>
            );
          })}
        </ul>
      )}

      {actionBtn && (
        <div style={{ display: "flex", justifyContent: "start" }}>
          {actionBtn}
        </div>
      )}
    </div>
  );
};
