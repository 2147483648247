import { EyeOutlined } from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { HttpError, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Table, Typography } from "antd";
import { HighLightOrderStatus } from "components/orders";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import useUserRole from "hooks/useUserRole";
import { IEmployee, IOrder } from "interfaces";
import { formatCurrency } from "utils/numberHelper";

interface PharRepSalesProps {
  employee: IEmployee;
}

export const PharRepSales: React.FC<PharRepSalesProps> = ({ employee }) => {
  const t = useTranslate();

  const { show } = useNavigation();

  const { roleCode } = useUserRole();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps } = useTable<IOrder, HttpError>({
    resource: API_RESOURCES.orders,
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    meta,
    filters: {
      initial: [
        {
          field: "employeeId",
          operator: "eq",
          value: employee?.id,
        },
      ],
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  return (
    <>
      <Typography.Title level={5}>
        {t("employees.tabs.sales.table.title")}
      </Typography.Title>

      <Table {...tableProps} rowKey={"id"} scroll={{ x: 300 }}>
        <Table.Column<IOrder>
          dataIndex="idx"
          title={t("employees.tabs.sales.table.fields.idx")}
        />
        <Table.Column<IOrder>
          dataIndex="code"
          title={t("employees.tabs.sales.table.fields.code")}
        />
        <Table.Column<IOrder>
          dataIndex={["area", "name"]}
          title={t("employees.tabs.sales.table.fields.area")}
        />
        <Table.Column<IOrder>
          dataIndex="totalPrice"
          title={t("employees.tabs.sales.table.fields.totalPrice")}
          render={(value) => formatCurrency(value)}
        />
        <Table.Column<IOrder>
          dataIndex={["employee", "name"]}
          title={t("employees.tabs.sales.table.fields.employee")}
        />
        <Table.Column<IOrder>
          dataIndex={["warehouseManager", "name"]}
          title={t("employees.tabs.sales.table.fields.warehouseManager")}
        />
        <Table.Column<IOrder>
          dataIndex="status"
          title={t("employees.tabs.sales.table.fields.status")}
          render={(status) => (
            <HighLightOrderStatus status={status} roleCode={roleCode} />
          )}
        />
        <Table.Column<IOrder>
          dataIndex="action"
          title={t("employees.tabs.sales.table.fields.action")}
          render={(_, item) => {
            return (
              <>
                <Button
                  icon={<EyeOutlined />}
                  type="text"
                  onClick={() => show(API_RESOURCES.orders, item?.id ?? "")}
                />
              </>
            );
          }}
        />
      </Table>
    </>
  );
};
