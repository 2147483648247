import { useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Table, Typography } from "antd";
import { SERVICE_MOBISALE } from "config";
import { IProductQuanity } from "interfaces";
import { useTranslation } from "react-i18next";

interface ProductListProps {
  warehouseId: string;
}

export const ProductList: React.FC<ProductListProps> = ({ warehouseId }) => {
  const { t } = useTranslation();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps } = useTable<IProductQuanity, HttpError>({
    resource: `warehouses/${warehouseId}/products`,
    meta,
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
  });

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: 24 }}>
        {t("warehouses.productList.title")}
      </Typography.Title>

      <Table
        {...tableProps}
        rowKey={(record) => record?.product?.id ?? Math.random()}
      >
        <Table.Column<IProductQuanity>
          dataIndex="idx"
          title={t("warehouses.productList.fields.idx")}
        />
        <Table.Column<IProductQuanity>
          dataIndex={["product", "name"]}
          title={t("warehouses.productList.fields.product")}
        />
        <Table.Column<IProductQuanity>
          dataIndex="quantity"
          title={t("warehouses.productList.fields.quantity")}
        />
      </Table>
    </>
  );
};
