import { useTranslation } from "react-i18next";

import { Col, Row } from "antd";
import TextBox from "components/textBox";
import { ICompany } from "interfaces";

interface CompanyInfoProps {
  data?: ICompany;
}

export const CompanyInfo: React.FC<CompanyInfoProps> = ({ data }) => {
  const { t } = useTranslation();

  const itemCompanyName = (
    <TextBox
      value={data?.name ?? "--"}
      label={t("pages.accountInfo.tabs.company.fields.name")}
    />
  );

  const itemAdress = (
    <TextBox
      value={data?.address ?? "--"}
      label={t("pages.accountInfo.tabs.company.fields.address")}
    />
  );

  const itemPhone = (
    <TextBox
      value={data?.phone ?? "--"}
      label={t("pages.accountInfo.tabs.company.fields.phone")}
    />
  );

  const itemEmail = (
    <TextBox
      value={data?.email ?? "--"}
      label={t("pages.accountInfo.tabs.company.fields.email")}
    />
  );

  const itemTaxCode = (
    <TextBox
      value={data?.taxCode ?? "--"}
      label={t("pages.accountInfo.tabs.company.fields.taxCode")}
    />
  );

  const itemWebsite = (
    <TextBox
      value={data?.website ?? "--"}
      label={t("pages.accountInfo.tabs.company.fields.website")}
    />
  );

  return (
    <Row gutter={[16, 24]}>
      <Col xs={24} md={12} lg={8}>
        {itemCompanyName}
      </Col>

      <Col xs={24} md={12} lg={8}>
        {itemAdress}
      </Col>

      <Col xs={24} md={12} lg={8}>
        {itemPhone}
      </Col>

      <Col xs={24} md={12} lg={8}>
        {itemEmail}
      </Col>

      <Col xs={24} md={12} lg={8}>
        {itemTaxCode}
      </Col>

      <Col xs={24} md={12} lg={8}>
        {itemWebsite}
      </Col>
    </Row>
  );
};
