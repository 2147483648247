import { CSSProperties } from "react";

import WithLabel, { WithLabelProps } from "components/withLabel";

interface TextBoxProps extends Omit<WithLabelProps, "children"> {
  value?: React.ReactNode;
  valueStyles?: CSSProperties;
}

const TextBox: React.FC<TextBoxProps> = ({
  value,
  label,
  valueStyles,
  labelStyles,
  wrapperStyles,
}) => {
  return (
    <WithLabel
      label={label}
      labelStyles={labelStyles}
      wrapperStyles={wrapperStyles}
    >
      {value !== null && (
        <div
          style={{
            backgroundColor: "#f6f8f9",
            border: "1px solid #f8f8fd",
            borderRadius: "4px",
            padding: "5px 12px",
            width: "100%",
            minHeight: 30,
            wordBreak: "break-word",
            ...(valueStyles || {}),
          }}
        >
          {value}
        </div>
      )}
    </WithLabel>
  );
};

export default TextBox;
