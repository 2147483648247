import { DownloadOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TagField,
  useSelect,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  LogicalFilter,
  getDefaultFilter,
  useApiUrl,
  useCustom,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Button, Form, Image, Input, Select, Space, Table } from "antd";
import React from "react";

import imgPlaceholder from "assets/img_placeholder.svg";
import { SelectOptionLabel } from "components/filters";
import {
  API_RESOURCES,
  DEFAULT_ALL_FILTER_VALUE,
  FILTER_ROLE_LIST,
  ROLE_LIST,
  SERVICE_MOBISALE,
} from "config";
import { IArea, IEmployee, IUserRoleCode, IWarehouse } from "interfaces";
import { formatName } from "utils/commonHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import useUserRole from "hooks/useUserRole";

export const EmployeeList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const t = useTranslate();

  const { hasAdmin, hasDirector } = useUserRole();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps, searchFormProps, filters, setFilters } = useTable<
    IEmployee,
    HttpError
  >({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    meta,
    filters: {
      initial: [
        {
          field: "role",
          operator: "eq",
          value: DEFAULT_ALL_FILTER_VALUE,
        },
      ],
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const setLogicalFilter = ({ field, value, operator }: LogicalFilter) => {
    if (field === "role") {
      setFilters((filters) => {
        const finalFilters: CrudFilters = filters
          .filter((filter) => {
            const logicalFilter = filter as LogicalFilter;
            return (
              logicalFilter.field !== field &&
              logicalFilter.field !== "areaId" &&
              logicalFilter.field !== "warehouseId"
            );
          })
          .concat({
            field,
            operator,
            value,
          });
        return finalFilters;
      });
    } else {
      setFilters((filters) => {
        const finalFilters: CrudFilters = filters
          .filter((filter) => (filter as LogicalFilter).field !== field)
          .concat({
            field,
            operator,
            value,
          });
        return finalFilters;
      });
    }
  };

  const {
    selectProps: {
      showSearch: showSearchArea,
      onSearch: onSearchArea,
      ...areaSelectProps
    },
  } = useSelect<IArea, HttpError>({
    resource: API_RESOURCES.areas,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const {
    selectProps: {
      showSearch: showSearchWarehouse,
      onSearch: onSearchWarehouse,
      ...warehouseSelectProps
    },
  } = useSelect<IWarehouse, HttpError>({
    resource: API_RESOURCES.warehouses,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const filterRole = getDefaultFilter("role", filters, "eq");
  const filterArea = getDefaultFilter("areaId", filters, "eq");
  const filterWarehouse = getDefaultFilter("warehouseId", filters, "eq");

  const apiUrl = useApiUrl();

  const { isRefetching: isExportingEmployees, refetch: exportEmployees } =
    useCustom({
      url: `${apiUrl}/${SERVICE_MOBISALE}/export/employees`,
      method: "get",
      queryOptions: {
        enabled: false,
        retry: 0,
      },
      errorNotification(error) {
        return {
          type: "error",
          message: error?.message ?? t("employees.notification.exportError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("employees.notification.exportSuccess"),
      },
    });

  const handleExport = () => {
    exportEmployees();
  };

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchEmployee } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <List
      headerButtons={
        <>
          <Select
            value={filterRole}
            onChange={(value) =>
              setLogicalFilter({ field: "role", value, operator: "eq" })
            }
            optionLabelProp="label"
            style={{ width: 200 }}
          >
            {FILTER_ROLE_LIST(t).map((fRole) => (
              <Select.Option
                key={fRole.value}
                value={fRole.value}
                label={
                  <SelectOptionLabel
                    prefix={t("employees.filters.roles")}
                    label={fRole.label}
                  />
                }
              >
                {fRole.label}
              </Select.Option>
            ))}
          </Select>

          {filterRole === "PHAR_REP" && (
            <Select
              value={filterArea}
              onChange={(value) =>
                setLogicalFilter({ field: "areaId", value, operator: "eq" })
              }
              optionLabelProp="label"
              style={{ width: 200 }}
              allowClear
              placeholder={t("employees.placeholders.area")}
              showSearch
              filterOption={(input, option) =>
                fuzzySearch(
                  toLowerCaseNonAccentVietnamese(
                    String(option?.children ?? "")
                  ),
                  toLowerCaseNonAccentVietnamese(input)
                )
              }
            >
              {areaSelectProps.options?.map((fArea) => (
                <Select.Option
                  key={fArea.value}
                  value={fArea.value}
                  label={
                    <SelectOptionLabel
                      prefix={t("employees.filters.areas")}
                      label={fArea.label as string}
                    />
                  }
                >
                  {fArea.label}
                </Select.Option>
              ))}
            </Select>
          )}

          {filterRole === "INV_MANAGEMENT" && (
            <Select
              value={filterWarehouse}
              onChange={(value) =>
                setLogicalFilter({
                  field: "warehouseId",
                  value,
                  operator: "eq",
                })
              }
              optionLabelProp="label"
              style={{ width: 200 }}
              allowClear
              placeholder={t("employees.placeholders.warehouse")}
              showSearch
              filterOption={(input, option) =>
                fuzzySearch(
                  toLowerCaseNonAccentVietnamese(
                    String(option?.children ?? "")
                  ),
                  toLowerCaseNonAccentVietnamese(input)
                )
              }
            >
              {warehouseSelectProps.options?.map((fWarehouse) => (
                <Select.Option
                  key={fWarehouse.value}
                  value={fWarehouse.value}
                  label={
                    <SelectOptionLabel
                      prefix={t("employees.filters.warehouses")}
                      label={fWarehouse.label as string}
                    />
                  }
                >
                  {fWarehouse.label}
                </Select.Option>
              ))}
            </Select>
          )}

          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q" style={{ marginBottom: 0 }}>
              <Input.Search
                allowClear
                placeholder={t("placeholders.input_search")}
                style={{ width: 230 }}
                onSearch={() => searchEmployee()}
              />
            </Form.Item>
          </Form>

          {(hasAdmin || hasDirector) && (
            <Button
              icon={<DownloadOutlined />}
              shape="circle"
              onClick={handleExport}
              loading={isExportingEmployees}
              disabled={isExportingEmployees}
            />
          )}

          {resource?.canCreate && <CreateButton />}
        </>
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("employees.fields.idx")} />

        <Table.Column<IEmployee>
          dataIndex="fullName"
          title={t("employees.fields.name")}
          render={(_, record) =>
            formatName(record?.firstName, record?.lastName)
          }
        />

        <Table.Column
          dataIndex="avatar"
          title={t("employees.fields.avatar")}
          render={(avatar: string) => (
            <Image
              src={avatar}
              fallback={imgPlaceholder}
              style={{ maxWidth: 64, maxHeight: 64 }}
            />
          )}
        />

        <Table.Column dataIndex="phone" title={t("employees.fields.phone")} />

        {filterRole === "PHAR_REP" && (
          <>
            <Table.Column
              dataIndex={["area", "name"]}
              title={t("employees.fields.area")}
            />
          </>
        )}

        {filterRole === "INV_MANAGEMENT" && (
          <>
            <Table.Column
              dataIndex={["warehouse", "name"]}
              title={t("employees.fields.warehouse")}
            />
          </>
        )}

        {filterRole === "ALL" && (
          <Table.Column<IEmployee>
            dataIndex={"role"}
            title={t("employees.fields.role")}
            render={(roleCode: IUserRoleCode) => {
              const role = ROLE_LIST(t).find((r) => r.value === roleCode);
              if (role) {
                return <TagField value={role?.label} color={role?.color} />;
              }

              return "--";
            }}
            // filterDropdown={(props) => (
            //   <FilterDropdown {...props}>
            //     <Select
            //       style={{ minWidth: 200 }}
            //       placeholder={t("employees.placeholders.role")}
            //       options={FILTER_ROLE_LIST(t)}
            //     />
            //   </FilterDropdown>
            // )}
            // defaultFilteredValue={getDefaultFilter("role", filters, "eq")}
          />
        )}

        <Table.Column
          title={t("employees.fields.actions")}
          dataIndex="actions"
          render={(_, record: IEmployee) => (
            <Space>
              {resource?.canShow && (
                <ShowButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.canEdit && (record?.role !== "ADMIN" || hasAdmin) && (
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  disabled={record?.role === "ADMIN" && !hasAdmin}
                />
              )}
              {resource?.meta?.canDelete && record?.role !== "ADMIN" && (
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  meta={meta}
                  errorNotification={(error) => {
                    return {
                      type: "error",
                      message:
                        (error as any)?.message ??
                        t("employees.notification.deleteError"),
                    };
                  }}
                  successNotification={{
                    type: "success",
                    message: t("employees.notification.deleteSuccess"),
                  }}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
