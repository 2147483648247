import { useApiUrl, useCustom, useGetIdentity } from "@refinedev/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Modal, Spin } from "antd";

import { PackageList } from "components/billing";
import LoadingScreen from "components/loadingScreen";
import {
  BILLING_SERVICE_MOBISALE,
  PATHS,
  ROLE_BASED_RESOURCES,
  SERVICE_BILLING,
} from "config";
import { useCompanyId } from "hooks/useCompanyId";
import {
  IMobisalePackage,
  IMobisaleSubscription,
  IUserIdentity,
  IUserRoleCode,
} from "interfaces";

const Splash: React.FC = () => {
  const { data: identity } = useGetIdentity<IUserIdentity>();

  const navigate = useNavigate();

  const apiUrl = useApiUrl();

  const companyId = useCompanyId();

  const breakpoint = Grid.useBreakpoint();

  const [initialRoute, setInitialRoute] = useState<string | null>(null);

  const { data: packagesData, isLoading: isLoadingPackages } = useCustom<
    IMobisalePackage[]
  >({
    url: `${apiUrl}/${SERVICE_BILLING}/packages/service/${BILLING_SERVICE_MOBISALE}`,
    method: "get",
    queryOptions: {
      retry: 0,
    },
  });

  const {
    data: subscriptionData,
    isLoading: isLoadingSubscription,
    refetch: refetchSubscriptionData,
  } = useCustom<IMobisaleSubscription>({
    url: `${apiUrl}/${SERVICE_BILLING}/subscriptions/service/${BILLING_SERVICE_MOBISALE}/company/${companyId}`,
    method: "get",
    queryOptions: {
      retry: 0,
    },
    errorNotification: false,
  });

  useEffect(() => {
    if (!identity) {
      navigate(PATHS.notFound);
      return;
    }

    if (!identity?.role) {
      navigate(PATHS.notFound);
      return;
    }

    const role = identity.role as IUserRoleCode;
    const availableResources = ROLE_BASED_RESOURCES[role];
    if (!availableResources || availableResources.length === 0) {
      navigate(PATHS.notFound);
      return;
    }

    const initialRoute = `/${availableResources[0]}`;
    setInitialRoute(initialRoute);
  }, [identity, navigate]);

  useEffect(() => {
    if (initialRoute && subscriptionData) {
      navigate(initialRoute);
    }
  }, [initialRoute, subscriptionData, navigate]);

  return (
    <>
      <div style={{ position: "relative", minHeight: "100vh" }}>
        {(isLoadingSubscription || isLoadingPackages) && (
          <LoadingScreen
            wrapperStyle={{
              position: "absolute",
            }}
          />
        )}
      </div>

      <Modal
        className="modal-activate-package"
        title={null}
        open={
          !subscriptionData &&
          (identity?.role === "ADMIN" || identity?.role === "DIRECTOR")
        }
        width={breakpoint.xl ? 1150 : breakpoint.lg ? 900 : 768}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <Spin spinning={isLoadingSubscription || isLoadingPackages}>
          <PackageList
            withTitle
            packages={packagesData?.data}
            subscription={subscriptionData?.data}
            onAfterCreateSubscription={() => {
              refetchSubscriptionData();
            }}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default Splash;
