import { useGetIdentity } from "@refinedev/core";
import { IUserIdentity } from "interfaces";
import { useMemo } from "react";

export default function useUserRole() {
  const { data: user } = useGetIdentity<IUserIdentity>();

  const hasAdmin = useMemo(() => {
    return user?.role === "ADMIN";
  }, [user]);

  const hasDirector = useMemo(() => {
    return user?.role === "DIRECTOR";
  }, [user]);

  const hasInvManagement = useMemo(() => {
    return user?.role === "INV_MANAGEMENT";
  }, [user]);

  const hasPharRep = useMemo(() => {
    return user?.role === "PHAR_REP";
  }, [user]);

  return {
    hasAdmin,
    hasDirector,
    hasInvManagement,
    hasPharRep,
    roleCode: user?.role,
  };
}
