import { useTranslation } from "react-i18next";
import { useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";

import { ItemTableWidget } from "./ItemTableWidget";
import { SERVICE_MOBISALE } from "config";
import { IProduct } from "interfaces";

export const ProductWorstSales: React.FC = () => {
  const { t } = useTranslation();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps } = useTable<IProduct, HttpError>({
    resource: `dashboard/worst_sale`,
    syncWithLocation: false,
    pagination: {
      mode: "off",
    },
    meta,
  });

  return (
    <ItemTableWidget
      title={t("dashboard.itemTable.title.worstSellingInMonth")}
      dataSource={[...(tableProps.dataSource ?? [])]}
    />
  );
};
