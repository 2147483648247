import { useTable } from "@refinedev/antd";
import { HttpError, useTranslate } from "@refinedev/core";
import { Modal, ModalProps, Table } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import { DATE_FORMAT, SERVICE_MOBISALE } from "config";
import { IImportWarehouseHistory, IWarehouse } from "interfaces";

interface AddProductHistoryProps {
  warehouse: IWarehouse;
  modalProps: ModalProps;
  close: () => void;
}

export const AddProductHitory: React.FC<AddProductHistoryProps> = ({
  warehouse,
  modalProps,
  close,
}) => {
  const { id } = useParams();

  const t = useTranslate();

  const handleClose = () => {
    close();
  };

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps } = useTable<IImportWarehouseHistory, HttpError>({
    resource: `import_warehouses/find_by_warehouse`,
    syncWithLocation: true,
    pagination: {
      mode: "server",
      pageSize: 5,
    },
    filters: {
      initial: [
        {
          field: "productId",
          operator: "eq",
          value: id,
        },
        {
          field: "warehouseId",
          operator: "eq",
          value: warehouse?.id,
        },
      ],
    },
    meta,
  });

  return (
    <Modal
      {...modalProps}
      title={t("products.addProductHistory.title", {
        warehouse: warehouse?.name,
      })}
      width={768}
      onCancel={handleClose}
      footer={null}
    >
      <Table
        {...tableProps}
        rowKey="idx"
        style={{ marginTop: 30, marginBottom: 30 }}
      >
        <Table.Column
          dataIndex="idx"
          title={t("products.addProductHistory.fields.idx")}
        />
        <Table.Column
          dataIndex="time"
          title={t("products.addProductHistory.fields.time")}
          render={(time) => (time ? dayjs(time).format(DATE_FORMAT) : "")}
        />
        <Table.Column
          dataIndex="quantity"
          title={t("products.addProductHistory.fields.quantity")}
        />
      </Table>
    </Modal>
  );
};
