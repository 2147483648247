import { Create, useForm, useSelect } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import {
  API_RESOURCES,
  DEFAULT_TEXTAREA_MAX_LENGTH,
  SERVICE_MOBISALE,
} from "config";
import { IEmployee, IUserRoleCode, IWarehouse } from "interfaces";
import React from "react";
import { formatName } from "utils/commonHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const WarehouseCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { formProps, saveButtonProps } = useForm<IWarehouse, HttpError>({
    meta,
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("warehouses.notification.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("warehouses.notification.createSuccess"),
    },
  });

  const {
    selectProps: {
      showSearch: showSearchEmployee,
      onSearch: onSearchEmployee,
      ...employeeSelectProps
    },
    queryResult: employeeQueryResult,
  } = useSelect<IEmployee, HttpError>({
    resource: API_RESOURCES.employees,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    filters: [
      {
        field: "role",
        operator: "eq",
        value: "INV_MANAGEMENT" as IUserRoleCode,
      },
    ],
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const employeeOptions = employeeQueryResult?.data?.data
    ?.filter((employee) => !employee?.warehouseId && !employee?.warehouse)
    .map((item) => ({
      value: item.id,
      label: formatName(item.firstName, item.lastName),
    }));

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("warehouses.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("warehouses.errors.nameRequired"),
                },
              ]}
            >
              <Input placeholder={t("warehouses.placeholders.name")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("warehouses.fields.address")}
              name={["address"]}
            >
              <Input placeholder={t("warehouses.placeholders.address")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("warehouses.fields.manager")}
              name={["managerId"]}
            >
              <Select
                allowClear
                {...employeeSelectProps}
                placeholder={t("warehouses.placeholders.manager")}
                options={employeeOptions}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("warehouses.fields.description")}
              name={["description"]}
            >
              <Input.TextArea
                rows={3}
                placeholder={t("warehouses.placeholders.description")}
                maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
