import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

import { IOrderStatus, IUserRoleCode } from "interfaces";

interface HighLightOrderStatusProps {
  status: IOrderStatus;
  roleCode?: IUserRoleCode | null;
}

export const HighLightOrderStatus: React.FC<HighLightOrderStatusProps> = ({
  status,
  roleCode,
}) => {
  const { t } = useTranslation();

  const color =
    status === "COMPLETED"
      ? "success"
      : status === "REJECTED"
      ? "warning"
      : status === "WAIT_FOR_APPROVED"
      ? "processing"
      : "default";

  const icon =
    status === "COMPLETED" ? (
      <CheckCircleOutlined />
    ) : status === "REJECTED" ? (
      <WarningOutlined />
    ) : status === "WAIT_FOR_APPROVED" ? (
      <ClockCircleOutlined />
    ) : (
      <InfoCircleOutlined />
    );

  return (
    <Tag color={color} icon={icon}>
      {roleCode
        ? t(`orderStatus.${roleCode}_${status}`)
        : t(`orderStatus.${status}`)}
    </Tag>
  );
};
