import { useApiUrl, useCustomMutation, useTranslate } from "@refinedev/core";
import { Button, Card, Form, Grid, Input, Typography, theme } from "antd";
import { PATHS, SERVICE_COMPANIES } from "config";
import { ICreateCompanyTypes } from "interfaces";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";
import { isWebsite } from "utils/urlHelper";

const { Title } = Typography;
const { useToken } = theme;

export const CreateCompany: React.FC = () => {
  const { token } = useToken();

  const t = useTranslate();

  const [form] = Form.useForm<ICreateCompanyTypes>();

  const apiUrl = useApiUrl();

  const { mutate: createCompany, isLoading } =
    useCustomMutation<ICreateCompanyTypes>();

  const handleCreateCompany = (values: ICreateCompanyTypes) => {
    createCompany(
      {
        url: `${apiUrl}/${SERVICE_COMPANIES}`,
        method: "post",
        values: {
          ...values,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("create_company.notification.error"),
          };
        },
        successNotification: {
          type: "success",
          message: t("create_company.notification.success"),
        },
      },
      {
        onSuccess() {
          window.location.replace(PATHS.splash);
        },
      }
    );
  };

  const CardTitle = (
    <Title
      level={3}
      style={{
        textAlign: "center",
        fontSize: "24px",
        letterSpacing: "-0.04em",
        overflowWrap: "break-word",
        hyphens: "manual",
        textOverflow: "unset",
        whiteSpace: "pre-wrap",
        // color: token.colorPrimaryTextHover,
      }}
    >
      {t("create_company.title")}
    </Title>
  );

  const breakpoint = Grid.useBreakpoint();

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={{ borderBottom: 0 }}
      style={{
        minWidth: breakpoint.md ? "450px" : "320px",
        maxWidth: breakpoint.md ? "550px" : "408px",
        margin: "auto",
        padding: "25px 10px 0px",
        backgroundColor: token.colorBgElevated,
      }}
    >
      <Form<ICreateCompanyTypes>
        layout="vertical"
        form={form}
        onFinish={(values) => handleCreateCompany(values)}
        // requiredMark={false}
      >
        <Form.Item
          name="name"
          label={t("create_company.fields.name")}
          rules={[
            {
              required: true,
              message: t("create_company.errors.nameRequired"),
            },
          ]}
        >
          <Input placeholder={t("create_company.placeholder.name")} />
        </Form.Item>

        <Form.Item name="address" label={t("create_company.fields.address")}>
          <Input placeholder={t("create_company.placeholder.address")} />
        </Form.Item>

        <Form.Item
          name="phone"
          label={t("create_company.fields.phone")}
          rules={[
            {
              validator: (_, value) => {
                if (!value || isValidPhoneNumb(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("create_company.errors.phoneInvalid"))
                );
              },
            },
          ]}
        >
          <Input placeholder={t("create_company.placeholder.phone")} />
        </Form.Item>

        <Form.Item
          name="email"
          label={t("create_company.fields.email")}
          rules={[
            {
              type: "email",
              message: t("create_company.errors.emailInvalid"),
            },
          ]}
        >
          <Input placeholder={t("create_company.placeholder.email")} />
        </Form.Item>

        <Form.Item name="taxCode" label={t("create_company.fields.taxCode")}>
          <Input placeholder={t("create_company.placeholder.taxCode")} />
        </Form.Item>

        <Form.Item
          name="website"
          label={t("create_company.fields.website")}
          rules={[
            {
              validator: (_, value) => {
                if (!value || isWebsite(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("create_company.errors.websiteInvalid"))
                );
              },
            },
          ]}
        >
          <Input placeholder={t("create_company.placeholder.website")} />
        </Form.Item>

        <Form.Item style={{ marginTop: "30px" }}>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            {t("buttons.create")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <div
      style={{
        marginTop: 10,
      }}
    >
      {CardContent}
    </div>
  );
};
