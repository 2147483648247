import { CloseOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Grid, Space, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";

import {
  BILLING_CICRLE,
  MONEY_UNIT,
  PAYMENT_BANK_ACCOUNT_NAME,
  PAYMENT_BANK_ACCOUNT_NUMBER,
  PAYMENT_BANK_BRANCH_NAME,
  PAYMENT_BANK_NAME,
  PAYMENT_TEMPLATE_CONTENT,
  SERVICE_MOBISALE,
} from "config";
import { IEmployee, IMobisalePackage } from "interfaces";
import { BulletNumber } from "./BulletNumber";

import imgQrOrder2 from "assets/img_qr_order_2.png";
import imgQrOrder3 from "assets/img_qr_order_3.png";

import { useApiUrl, useCustom } from "@refinedev/core";
import { formatCurrency } from "utils/numberHelper";
import { toNonAccentVietnamese } from "utils/vietnameseHelper";
import "./PaymentGuide.css";

const imgQRs = ["", imgQrOrder2, imgQrOrder3];

interface PaymentGuideProps {
  pkgData: IMobisalePackage;
  onClose?: () => void;
}

export const PaymentGuide: React.FC<PaymentGuideProps> = ({
  pkgData,
  onClose,
}) => {
  const { t } = useTranslation();

  const breakpoint = Grid.useBreakpoint();

  const apiUrl = useApiUrl();

  const { data, isLoading } = useCustom<IEmployee>({
    url: `${apiUrl}/${SERVICE_MOBISALE}/employees/profile`,
    method: "get",
  });

  return (
    <div
      className="payment-guide-container"
      style={{
        display: "flex",
        borderRadius: 8,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Button
        type="text"
        icon={<CloseOutlined />}
        style={{
          zIndex: 1,
          position: "absolute",
          right: 12,
          top: 12,
          color: breakpoint.xs ? "#fff" : "#000000E0",
        }}
        onClick={onClose}
      />

      <div
        className="left"
        style={{
          backgroundColor: "#4568F4",
          padding: "24px 30px",
          color: "#fff",
        }}
      >
        <Space style={{ alignItems: "center" }}>
          <img src={"/logo.svg"} alt="mobisale" width={50} />
          <Typography.Title level={4} style={{ margin: 0, color: "#fff" }}>
            {t("site.title")}
          </Typography.Title>
        </Space>

        <div style={{ fontWeight: 600, fontSize: 18, margin: "30px 0px" }}>
          {t("packages.scan_qr_to_pay")}
        </div>

        <Space size="middle" direction="vertical">
          {[1, 2, 3].map((number) => (
            <div>
              <BulletNumber number={number} />{" "}
              <span style={{ fontSize: 13, marginLeft: 3 }}>
                {t(`packages.step_${number}`)}
              </span>
            </div>
          ))}
        </Space>

        <div
          style={{
            fontSize: 12,
            color: "#F6F6F6",
            marginTop: 60,
            marginBottom: 20,
          }}
        >
          {t("packages.supports")}
        </div>
      </div>

      <div
        className="right"
        style={{ flex: 1, position: "relative", padding: 24 }}
      >
        <Spin spinning={isLoading}>
          <Space size="middle" style={{ flexWrap: "wrap", marginTop: 30 }}>
            <Space
              direction="vertical"
              style={{
                flex: 1,
                alignItems: "center",
                border: "1px solid #E4E6EF",
                borderRadius: 6,
                backgroundColor: "#FAFAFA",
                padding: 24,
                minWidth: 310,
                minHeight: 350,
              }}
            >
              <Typography.Title level={5}>
                {t("packages.scan_pay")}
              </Typography.Title>

              {pkgData.orders && pkgData.orders > 1 && (
                <img
                  src={imgQRs[pkgData.orders - 1]}
                  alt={`qr-${pkgData.orders - 1}`}
                  width={150}
                />
              )}

              <ul style={{ padding: 0, paddingLeft: 15 }}>
                <li>
                  <span>{t("packages.bankBankName")}</span>
                  <span
                    style={{
                      color: "#00238A",
                      fontWeight: 500,
                      margin: "0px 4px",
                    }}
                  >
                    {PAYMENT_BANK_NAME}
                  </span>
                  <div>{`(${PAYMENT_BANK_BRANCH_NAME})`}</div>
                </li>
                <li>
                  <span>{t("packages.bankAccountNumber")}</span>
                  <span
                    style={{
                      color: "#00238A",
                      fontWeight: 500,
                      margin: "0px 4px",
                    }}
                  >
                    {PAYMENT_BANK_ACCOUNT_NUMBER}
                  </span>
                </li>
                <li>
                  <span>{t("packages.bankAccountName")}</span>
                  <span
                    style={{
                      color: "#00238A",
                      fontWeight: 500,
                      margin: "0px 4px",
                    }}
                  >
                    {PAYMENT_BANK_ACCOUNT_NAME}
                  </span>
                </li>
              </ul>
            </Space>

            <Space
              direction="vertical"
              style={{
                flex: 1,
                alignItems: "center",
                border: "1px solid #E4E6EF",
                borderRadius: 6,
                backgroundColor: "#FAFAFA",
                padding: 24,
                minWidth: 310,
                minHeight: 350,
              }}
            >
              <Typography.Title level={5}>
                {t("packages.payContentTitle")}
              </Typography.Title>

              <ul style={{ padding: 0, paddingLeft: 15, marginTop: 50 }}>
                <li>
                  <div>{t("packages.payContentLabel")}</div>
                  <div style={{ color: "#00238A", fontWeight: 500 }}>
                    {toNonAccentVietnamese(
                      PAYMENT_TEMPLATE_CONTENT.replace(
                        "{{company}}",
                        data?.data?.company?.name ?? "Ten cong ty"
                      ).replace("{{pkg}}", pkgData.name ?? "Ten goi")
                    )}
                  </div>
                </li>
                <li>
                  <div>{t("packages.payAmountLabel")}</div>
                  <div style={{ color: "#00238A", fontWeight: 500 }}>
                    {formatCurrency((pkgData.price ?? 0) * BILLING_CICRLE)}{" "}
                    {MONEY_UNIT}
                  </div>
                </li>
              </ul>
            </Space>
          </Space>
        </Spin>

        <Space
          size="small"
          style={{
            marginTop: 24,
            background: "rgba(69, 104, 244, 0.1)",
            borderRadius: 12,
            padding: 16,
          }}
        >
          <div>
            <strong>{t("packages.payContentWarningLabel")}</strong>
            <span style={{ marginLeft: 4 }}>
              {t("packages.payContentWarningText")}
            </span>
          </div>
          <StarOutlined style={{ color: "#002A83" }} />
        </Space>
      </div>
    </div>
  );
};
