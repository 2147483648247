import useUserRole from "hooks/useUserRole";
import { ItemOrdersChart } from "./ItemOrdersChart";
import { ProductBestSales } from "./ProductBestSales";
import { ProductMostInStock } from "./ProductMostInStock";
import { ProductWorstSales } from "./ProductWorstSales";
import { SalesOverviewChart } from "./SalesOverviewChart";
import { SummaryWidget } from "./SummaryWidget";
import { UpgradeService } from "./UpgradeService";

const Dashboard: React.FC = () => {
  const { hasAdmin, hasDirector } = useUserRole();

  return (
    <div
      style={{ padding: 24, display: "flex", flexDirection: "column", gap: 24 }}
    >
      {(hasAdmin || hasDirector) && <UpgradeService />}

      <ItemOrdersChart />

      {(hasAdmin || hasDirector) && <SummaryWidget />}

      <SalesOverviewChart />

      <ProductBestSales />

      {(hasAdmin || hasDirector) && <ProductWorstSales />}
      
      {(hasAdmin || hasDirector) && <ProductMostInStock />}
    </div>
  );
};

export default Dashboard;
