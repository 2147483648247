import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import {
  ACTIVE_STATUS_LIST,
  API_RESOURCES,
  DEFAULT_TEXTAREA_MAX_LENGTH,
  SERVICE_MOBISALE,
} from "config";
import { IArea, IEmployee, IUserRoleCode, IWarehouse } from "interfaces";
import React from "react";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const AreaEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const { list } = useNavigation();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { formProps, saveButtonProps, queryResult } = useForm<IArea, HttpError>(
    {
      meta,
      errorNotification(error) {
        return {
          type: "error",
          message: error?.message ?? t("areas.notification.editError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("areas.notification.editSuccess"),
      },
    }
  );

  // const {
  //   selectProps: {
  //     options: areaParentOptions,
  //     showSearch: showSearchArea,
  //     onSearch: onSearchArea,
  //     ...areaParentSelectProps
  //   },
  // } = useSelect<IArea, HttpError>({
  //   resource: API_RESOURCES.areas,
  //   meta,
  //   optionLabel: "name",
  //   pagination: {
  //     current: 1,
  //     pageSize: 99999, // use the large PageSize to get full
  //     mode: "server",
  //   },
  //   // onSearch: (value: string) => [
  //   //   {
  //   //     field: "q",
  //   //     operator: "eq",
  //   //     value,
  //   //   },
  //   // ],
  // });

  const {
    selectProps: {
      showSearch: showSearchWarehouse,
      onSearch: onSearchWarehouse,
      ...warehouseSelectProps
    },
  } = useSelect<IWarehouse, HttpError>({
    resource: API_RESOURCES.warehouses,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const {
    selectProps: {
      showSearch: showSearchEmployee,
      onSearch: onSearchEmployee,
      ...employeeSelectProps
    },
    queryResult: employeeQueryResult,
  } = useSelect<IEmployee, HttpError>({
    resource: API_RESOURCES.employees,
    meta,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
    filters: [
      {
        field: "role",
        operator: "eq",
        value: "PHAR_REP" as IUserRoleCode,
      },
    ],
    // onSearch: (value: string) => [
    //   {
    //     field: "q",
    //     operator: "eq",
    //     value,
    //   },
    // ],
  });

  const employeeSelectOptions = employeeQueryResult?.data?.data
    ?.filter(
      (employee) =>
        !employee.areaId || employee.areaId === queryResult?.data?.data.id
    )
    .map((employee) => ({
      value: employee?.id,
      label: employee?.name,
    }));

  const parentId = Form.useWatch(["parentArea", "id"], formProps.form);
  // const warehouseId = Form.useWatch(["warehouse", "id"], formProps.form);

  // const filterAreaOptions = useMemo(() => {
  //   return areaParentOptions?.filter(
  //     (item) => item.value !== queryResult?.data?.data.id
  //   );
  // }, [areaParentOptions, queryResult?.data?.data.id]);

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  // TODO: workaround to update area,
  // Remove these code later after BE update API update
  const apiUrl = useApiUrl();

  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: updateArea } = useCustomMutation<IArea>();

  const handleUpdateArea = async (values: IArea) => {
    updateArea(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/areas/update`,
        method: "put",
        values: {
          ...values,
          id: queryResult?.data?.data?.id,
          parentId: values?.parentArea?.id,
          warehouseId: values?.warehouse?.id,
          employee: values?.employee?.id,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("areas.notification.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("areas.notification.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.areas);
          }, 100);
        },
      }
    );
  };
  //

  return (
    <Edit
      headerButtons={
        <>
          {/* <ListButton disabled={isProcessing} />
          <RefreshButton
            disabled={isProcessing}
            loading={isProcessing}
            meta={meta}
          /> */}
          <ShowButton />
        </>
      }
      footerButtons={
        <>
          {resource?.meta?.canDelete && (
            <DeleteButton
              disabled={isProcessing}
              meta={meta}
              onSuccess={() => {
                list(API_RESOURCES.areas);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("areas.notification.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("areas.notification.deleteSuccess"),
              }}
            />
          )}
          <SaveButton disabled={isProcessing} {...saveButtonProps} />
        </>
      }
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => handleUpdateArea(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("areas.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("areas.errors.nameRequired"),
                },
              ]}
            >
              <Input placeholder={t("areas.placeholders.name")} />
            </Form.Item>
          </Col>

          {/* <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("areas.fields.parent")}
              name={["parentArea", "id"]}
            >
              <Select
                allowClear
                {...areaParentSelectProps}
                options={filterAreaOptions}
                placeholder={t("areas.placeholders.parent")}
                disabled={Boolean(warehouseId)}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col> */}

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("areas.fields.warehouse")}
              name={["warehouse", "id"]}
              rules={[
                {
                  required: true,
                  message: t("areas.errors.warehouseRequired"),
                },
              ]}
            >
              <Select
                allowClear
                {...warehouseSelectProps}
                placeholder={t("areas.placeholders.warehouse")}
                disabled={Boolean(parentId)}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("areas.fields.employee")}
              name={["employee", "id"]}
            >
              <Select
                allowClear
                {...employeeSelectProps}
                options={employeeSelectOptions}
                placeholder={t("areas.placeholders.employee")}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("areas.fields.status")} name={["status"]}>
              <Select placeholder={t("areas.placeholders.status")} disabled>
                {ACTIVE_STATUS_LIST(t).map((role) => (
                  <Select.Option key={role.value} value={role.value}>
                    {role.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("areas.fields.description")}
              name={["description"]}
            >
              <Input.TextArea
                rows={3}
                placeholder={t("areas.placeholders.description")}
                maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
