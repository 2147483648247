import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Form, Input, Space, Switch, Table } from "antd";
import { API_RESOURCES, SERVICE_MOBISALE } from "config";
import { IUpdateWarehouseStatusTypes, IWarehouse } from "interfaces";
import React from "react";

export const WarehouseList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const t = useTranslate();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps, searchFormProps } = useTable<IWarehouse, HttpError>({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    meta,
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const apiUrl = useApiUrl();

  const invalidate = useInvalidate();

  const { mutate: updateWarehouseStatus, isLoading: isUpdatingStatus } =
    useCustomMutation<IUpdateWarehouseStatusTypes>();

  const handleUpdateWarehouseStatus = (values: IUpdateWarehouseStatusTypes) => {
    updateWarehouseStatus(
      {
        url: `${apiUrl}/${SERVICE_MOBISALE}/warehouses/action`,
        method: "post",
        values: {
          ...values,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("warehouses.notification.updateStatusError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("warehouses.notification.updateStatusSuccess"),
        },
      },
      {
        onSuccess() {
          invalidate({
            resource: API_RESOURCES.warehouses,
            invalidates: ["list"],
          });
        },
      }
    );
  };

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchWarehouse } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <List
      headerButtons={
        <>
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q" style={{ marginBottom: 0 }}>
              <Input.Search
                allowClear
                placeholder={t("placeholders.input_search")}
                style={{ width: 230 }}
                onSearch={() => searchWarehouse()}
              />
            </Form.Item>
          </Form>
          {resource?.canCreate && <CreateButton />}
        </>
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("warehouses.fields.idx")} />
        <Table.Column dataIndex="name" title={t("warehouses.fields.name")} />
        <Table.Column
          dataIndex="address"
          title={t("warehouses.fields.address")}
        />
        <Table.Column
          dataIndex={["manager", "name"]}
          title={t("warehouses.fields.manager")}
        />
        <Table.Column<IWarehouse>
          dataIndex="warehouse-status"
          key="warehouse-status"
          title={t("warehouses.fields.status")}
          render={(_, record) => (
            <Switch
              disabled
              loading={isUpdatingStatus}
              checked={record.warehouseStatus === "ACTIVE"}
              onChange={(checked) => {
                handleUpdateWarehouseStatus({
                  warehouseId: String(record.id),
                  status: checked ? "ACTIVE" : "INACTIVE",
                });
              }}
            />
          )}
        />
        <Table.Column
          title={t("warehouses.fields.actions")}
          dataIndex="warehouses"
          render={(_, record: IWarehouse) => (
            <Space>
              {resource?.canShow && (
                <ShowButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.canEdit && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.meta?.canDelete && (
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  meta={meta}
                  errorNotification={(error) => {
                    return {
                      type: "error",
                      message:
                        (error as any)?.message ??
                        t("warehouses.notification.deleteError"),
                    };
                  }}
                  successNotification={{
                    type: "success",
                    message: t("warehouses.notification.deleteSuccess"),
                  }}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
