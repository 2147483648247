import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigation } from "@refinedev/core";
import { Button, Image, Table } from "antd";
import { API_RESOURCES } from "config";
import { IProduct } from "interfaces";
import { useTranslation } from "react-i18next";

interface ItemTableWidgetProps {
  title?: string;
  dataSource?: IProduct[];
  onDownload?: () => void;
}

export const ItemTableWidget: React.FC<ItemTableWidgetProps> = ({
  title,
  dataSource,
  onDownload,
}) => {
  const { t } = useTranslation();

  const { show } = useNavigation();

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 8,
        padding: 24,
      }}
    >
      <div
        style={{
          fontWeight: 600,
          fontSize: 16,
          display: "flex",
          gap: 24,
          alignItems: "center",
          marginBottom: 12,
        }}
      >
        <span style={{ flex: 1 }}>{title}</span>
        <Button
          shape="circle"
          icon={<DownloadOutlined style={{ fontSize: 16 }} />}
          onClick={onDownload}
        />
      </div>

      <Table
        dataSource={dataSource}
        rowKey={"id"}
        scroll={{ x: 300 }}
        pagination={false}
      >
        <Table.Column
          dataIndex="idx"
          title={t("dashboard.itemTable.fields.idx") as string}
        />
        <Table.Column
          dataIndex="name"
          title={t("dashboard.itemTable.fields.name") as string}
        />
        <Table.Column
          dataIndex="image"
          title={t("dashboard.itemTable.fields.image") as string}
          render={(v) => <Image src={v} />}
        />
        <Table.Column
          dataIndex="price"
          title={t("dashboard.itemTable.fields.price") as string}
        />
        <Table.Column
          dataIndex="amount"
          title={t("dashboard.itemTable.fields.amount") as string}
        />
        <Table.Column<IProduct>
          dataIndex="action"
          title={t("dashboard.itemTable.fields.action") as string}
          render={(_, item) => {
            return (
              <>
                <Button
                  icon={<EyeOutlined />}
                  type="text"
                  onClick={() => show(API_RESOURCES.products, item.id ?? "")}
                />
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};
