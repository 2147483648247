export function parseQueryString(url?: string) {
  if (!url) return {};

  const queryString = url.replace(/.*\?/, "");
  if (queryString === url || !queryString) {
    return {};
  }

  const urlParams = new URLSearchParams(queryString);
  const result: Record<string, any> = {};

  urlParams.forEach((val, key) => {
    if (result.hasOwnProperty(key)) {
      result[key] = [result[key], val];
    } else {
      result[key] = val;
    }
  });

  return result;
}

export function isWebsite(str: string): boolean {
  // Regular expression pattern to match a website URL
  const urlPattern = /^(https?:\/\/)?([^\s:/?#]+\.?)+(\/[^\s]*)?$/i;

  return urlPattern.test(str);
}
