import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Button, Form, Image, Input, Space, Table, Tag } from "antd";
import imgPlaceholder from "assets/img_placeholder.svg";
import { SERVICE_MOBISALE } from "config";
import { useCompanyId } from "hooks/useCompanyId";
import useUserRole from "hooks/useUserRole";
import { ICustomer } from "interfaces";
import React from "react";
import { formatCurrency } from "utils/numberHelper";

export const CustomerList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();

  const t = useTranslate();

  const companyId = useCompanyId();

  const { hasAdmin, hasDirector } = useUserRole();

  const meta = {
    service: SERVICE_MOBISALE,
    extraProps: {
      companyId,
    },
  };

  const { tableProps, searchFormProps } = useTable<ICustomer, HttpError>({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    meta,
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const apiUrl = useApiUrl();

  const { isRefetching: isExportingCustomers, refetch: exportCustomers } =
    useCustom({
      url: `${apiUrl}/${SERVICE_MOBISALE}/export/customers`,
      method: "get",
      queryOptions: {
        enabled: false,
        retry: 0,
      },
      errorNotification(error) {
        return {
          type: "error",
          message: error?.message ?? t("customers.notification.exportError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("customers.notification.exportSuccess"),
      },
    });

  const handleExport = () => {
    exportCustomers();
  };

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchCustomer } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <List
      headerButtons={
        <>
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q" style={{ marginBottom: 0 }}>
              <Input.Search
                allowClear
                placeholder={t("placeholders.input_search")}
                style={{ width: 230 }}
                onSearch={() => searchCustomer()}
              />
            </Form.Item>
          </Form>

          {(hasAdmin || hasDirector) && (
            <Button
              icon={<DownloadOutlined />}
              shape="circle"
              onClick={handleExport}
              loading={isExportingCustomers}
              disabled={isExportingCustomers}
            />
          )}

          {resource?.canCreate && <CreateButton />}
        </>
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("customers.fields.idx")} />
        <Table.Column dataIndex="name" title={t("customers.fields.name")} />
        <Table.Column
          dataIndex="image"
          title={t("customers.fields.image")}
          render={(image: string) => (
            <Image
              style={{ maxWidth: 64, maxHeight: 64 }}
              src={image}
              fallback={imgPlaceholder}
            />
          )}
        />
        <Table.Column
          dataIndex={["area", "name"]}
          title={t("customers.fields.area")}
        />
        <Table.Column
          dataIndex={["employee", "name"]}
          title={t("customers.fields.employee")}
        />
        <Table.Column
          dataIndex="totalPriceInMonth"
          title={t("customers.fields.totalPriceInMonth")}
          render={(value) => formatCurrency(value)}
        />
        <Table.Column
          dataIndex="completeOrderNo"
          title={t("customers.fields.completeOrderNo")}
        />
        <Table.Column
          dataIndex="pendingOrderNo"
          title={t("customers.fields.pendingOrderNo")}
        />
        <Table.Column<ICustomer>
          dataIndex="status"
          title={t("customers.fields.status")}
          render={(status) => {
            const color =
              status === "APPROVED"
                ? "success"
                : status === "REJECTED"
                ? "warning"
                : status === "REMOVED"
                ? "error"
                : "default";
            const icon =
              status === "APPROVED" ? (
                <CheckCircleOutlined />
              ) : status === "REJECTED" ? (
                <WarningOutlined />
              ) : status === "REMOVED" ? (
                <StopOutlined />
              ) : (
                <ClockCircleOutlined />
              );
            return (
              <Tag color={color} icon={icon}>
                {t(`customers.fields.status_${status}`)}
              </Tag>
            );
          }}
        />
        {/* <Table.Column
          dataIndex="address"
          title={t("customers.fields.address")}
        /> */}
        <Table.Column
          title={t("customers.fields.actions")}
          dataIndex="actions"
          render={(_, record: ICustomer) => (
            <Space>
              {resource?.canShow && (
                <ShowButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.canEdit && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              {resource?.meta?.canDelete && (
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  meta={meta}
                  errorNotification={(error) => {
                    return {
                      type: "error",
                      message:
                        (error as any)?.message ??
                        t("customers.notification.deleteError"),
                    };
                  }}
                  successNotification={{
                    type: "success",
                    message: t("customers.notification.deleteSuccess"),
                  }}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
