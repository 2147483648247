import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Spin } from "antd";
import { useMemo, useState } from "react";

import { RangeValue, SelectDateType } from "components/selectDate";
import {
  DEFAULT_DAYS_FOR_REPORTS,
  DEFAULT_MONTHS_FOR_REPORTS,
  DEFAULT_YEARS_FOR_REPORTS,
  SERVICE_MOBISALE,
} from "config";
import { useCompanyId } from "hooks/useCompanyId";
import { ICompanySaleReportItem, IWithPaging } from "interfaces";
import { BarChartWidget } from "../../components/chart/BarChartWidget";

import {
  rangeDayFilters,
  rangeMonthFilters,
  rangeYearFilters,
} from "utils/dayjsHelper";
import { toMillions } from "utils/numberHelper";
import imgTrend2 from "../../assets/img_trend_2.svg";

export const SalesOverviewChart: React.FC = () => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const companyId = useCompanyId();

  const [type, setType] = useState<SelectDateType>("DAY");
  const [rangeValue, setRangeValue] = useState<RangeValue>(
    rangeDayFilters(new Date(), DEFAULT_DAYS_FOR_REPORTS)
  );

  const { data, isRefetching, isFetching, isLoading } = useCustom<
    IWithPaging<ICompanySaleReportItem>
  >({
    url: `${apiUrl}/${SERVICE_MOBISALE}/dashboard/total_price`,
    method: "get",
    queryOptions: {
      enabled: Boolean(rangeValue?.[0] && rangeValue?.[1] && type),
      retry: 1,
    },
    config: {
      query: {
        page: 0,
        size: 99999,
        companyId,
        startTime: rangeValue?.at(0)?.toISOString(),
        endTime: rangeValue?.at(1)?.toISOString(),
        type,
      },
    },
  });

  const categories = useMemo(() => {
    return data?.data?.content?.map((item) => item.time ?? "") ?? [];
  }, [data]);

  const series = useMemo(() => {
    return toMillions(
      data?.data?.content?.map((item) => item.total_price ?? 0) ?? []
    );
  }, [data]);

  return (
    <Spin spinning={isRefetching || isFetching || isLoading}>
      <BarChartWidget
        title={t("dashboard.barChart.title.salesOverview")}
        image={imgTrend2}
        series={[
          {
            name: t("dashboard.barChart.money_unit"),
            data: series,
          },
        ]}
        categories={categories}
        yAxisTitle={t("dashboard.barChart.money_unit")}
        type={type}
        rangeValue={rangeValue}
        onTypeChange={(type) => {
          const today = new Date();
          setType(type);
          setRangeValue(
            type === "DAY"
              ? rangeDayFilters(today, DEFAULT_DAYS_FOR_REPORTS)
              : type === "MONTH"
              ? rangeMonthFilters(today, DEFAULT_MONTHS_FOR_REPORTS)
              : rangeYearFilters(today, DEFAULT_YEARS_FOR_REPORTS)
          );
        }}
        onRangeValueChange={setRangeValue}
      />
    </Spin>
  );
};
