import React from "react";
import { createRoot } from "react-dom/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";

import "./i18n";

import LoadingScreen from "components/loadingScreen";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const keycloak = new Keycloak("/keycloak.json");

root.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <React.StrictMode>
      <React.Suspense fallback={<LoadingScreen />}>
        <App />
      </React.Suspense>
    </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
