import { HttpError } from "@refinedev/core";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useTable } from "@refinedev/antd";

import { SERVICE_MOBISALE } from "config";
import { IProduct } from "interfaces";
import { ItemTableWidget } from "./ItemTableWidget";

export const ProductBestSales: React.FC = () => {
  const { t } = useTranslation();

  const meta = {
    service: SERVICE_MOBISALE,
  };

  const { tableProps } = useTable<IProduct, HttpError>({
    resource: `dashboard/best_sale`,
    syncWithLocation: false,
    pagination: {
      mode: "off",
    },
    meta,
  });

  return (
    <Spin spinning={Boolean(tableProps.loading)}>
      <ItemTableWidget
        title={t("dashboard.itemTable.title.bestSellingInMonth")}
        dataSource={[...(tableProps.dataSource ?? [])]}
      />
    </Spin>
  );
};
