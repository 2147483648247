import { useLink, useLogin, useTranslate } from "@refinedev/core";
import { Button, Card, Checkbox, Form, Input, Typography, theme } from "antd";
import { UnauthenticatedLayout } from "components/layout";
import { PASSWORD_PLACEHOLDER } from "config";
import { ILoginTypes } from "interfaces";

const { Title, Text } = Typography;
const { useToken } = theme;

interface LoginWithUserPassProps {
  forgotPasswordLink?: string;
  registerLink?: string;
  noRememberMe?: boolean;
}

export const LoginWithUserPass: React.FC<LoginWithUserPassProps> = ({
  forgotPasswordLink = "/forgot-password",
  registerLink = "/register",
  noRememberMe = false,
}) => {
  const { token } = useToken();
  const t = useTranslate();
  const [form] = Form.useForm<ILoginTypes>();
  const Link = useLink();

  const { mutate: login, isLoading } = useLogin<ILoginTypes>();

  const CardTitle = (
    <Title
      level={3}
      style={{
        textAlign: "center",
        fontSize: "24px",
        letterSpacing: "-0.04em",
        overflowWrap: "break-word",
        hyphens: "manual",
        textOverflow: "unset",
        whiteSpace: "pre-wrap",
        // color: token.colorPrimaryTextHover,
      }}
    >
      {t("pages.login.title", "Sign in to your account")}
    </Title>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={{ borderBottom: 0 }}
      style={{
        minWidth: "320px",
        maxWidth: "408px",
        margin: "auto",
        padding: "25px 10px 0px",
        backgroundColor: token.colorBgElevated,
      }}
    >
      <Form<ILoginTypes>
        layout="vertical"
        form={form}
        onFinish={(values) => login({ ...values, translate: t })}
        requiredMark={false}
        initialValues={{
          remember: false,
        }}
      >
        <Form.Item
          name="username"
          label={t("pages.login.fields.username")}
          rules={[
            {
              required: true,
              message: t("pages.login.errors.usernameRequired"),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t("pages.login.fields.username")}
            autoComplete="username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("pages.login.fields.password", "Password")}
          rules={[
            { required: true, message: t("pages.login.errors.passRequired") },
          ]}
          style={{ marginBottom: "12px" }}
        >
          <Input.Password
            type="password"
            placeholder={PASSWORD_PLACEHOLDER}
            size="large"
            autoComplete="current-password"
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!noRememberMe && (
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox
                style={
                  {
                    // fontSize: "12px",
                  }
                }
              >
                {t("pages.login.buttons.rememberMe", "Remember me")}
              </Checkbox>
            </Form.Item>
          )}
          {forgotPasswordLink && (
            <Link
              style={{
                fontSize: "12px",
                marginLeft: "auto",
                fontWeight: "bold",
                // color: token.colorPrimaryTextHover,
              }}
              to={forgotPasswordLink}
            >
              {t("pages.login.buttons.forgotPassword", "Forgot password?")}
            </Link>
          )}
        </div>

        <Form.Item style={{ marginTop: "30px" }}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            block
          >
            {t("pages.login.signin", "Sign in")}
          </Button>
        </Form.Item>

        <div style={{ marginTop: 8 }}>
          {registerLink && (
            <Text style={{ fontSize: 12 }}>
              {t("pages.login.buttons.noAccount", "Don't have an account?")}{" "}
              <Link
                to={registerLink}
                style={{
                  fontWeight: "bold",
                  // color: token.colorPrimaryTextHover,
                }}
              >
                {t("pages.login.signup", "Sign up")}
              </Link>
            </Text>
          )}
        </div>
      </Form>
    </Card>
  );

  return <UnauthenticatedLayout>{CardContent}</UnauthenticatedLayout>;
};
