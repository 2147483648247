import { useKeycloak } from "@react-keycloak/web";
import { useLogin, useTranslate } from "@refinedev/core";
import { Button, Card, Typography, theme } from "antd";
import { UnauthenticatedLayout } from "components/layout";

const { Title, Text } = Typography;

const { useToken } = theme;

export const LoginWithKeycloak: React.FC = () => {
  const t = useTranslate();

  const { keycloak } = useKeycloak();

  const { mutate: login } = useLogin();

  const { token } = useToken();

  const handleRegister = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const { to } = Object.fromEntries(urlSearchParams.entries());
      await keycloak.register({
        redirectUri: to ? `${window.location.origin}${to}` : undefined,
      });
    } catch (error) {
      console.log("handleRegister", { error });
    }
  };

  const CardTitle = (
    <Title
      level={3}
      style={{
        textAlign: "center",
        fontSize: "24px",
        letterSpacing: "-0.04em",
        overflowWrap: "break-word",
        hyphens: "manual",
        textOverflow: "unset",
        whiteSpace: "pre-wrap",
        // color: token.colorPrimaryTextHover,
      }}
    >
      {t("pages.login.title", "Sign in to your account")}
    </Title>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={{ borderBottom: 0 }}
      style={{
        minWidth: "320px",
        maxWidth: "408px",
        margin: "auto",
        padding: "25px 10px 0px",
        backgroundColor: token.colorBgElevated,
      }}
    >
      <Button
        type="primary"
        size="large"
        htmlType="submit"
        onClick={() => login({})}
        block
      >
        {t("pages.login.signin", "Sign in")}
      </Button>

      <div style={{ marginTop: 8 }}>
        <Text style={{ fontSize: 12 }}>
          {t("pages.login.buttons.noAccount", "Don't have an account?")}{" "}
          <Button type="link" onClick={handleRegister} style={{ padding: 0 }}>
            {t("pages.login.signup", "Sign up")}
          </Button>
        </Text>
      </div>
    </Card>
  );

  return <UnauthenticatedLayout>{CardContent}</UnauthenticatedLayout>;
};
