import { Button, Grid, Modal, Space, Spin, Typography } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PackageItem } from "components/billing";
import { IMobisalePackage, IMobisaleSubscription } from "interfaces";
import { PaymentGuide } from "./PaymentGuide";

import { useApiUrl, useCustomMutation } from "@refinedev/core";
import { BILLING_SERVICE_MOBISALE, SERVICE_BILLING } from "config";
import "./PackageList.css";

interface PackageListProps {
  packages?: IMobisalePackage[];
  subscription?: IMobisaleSubscription;
  withTitle?: boolean;
  onAfterCreateSubscription?: () => void;
  createSubscriptionInModal?: boolean;
}

export const PackageList: React.FC<PackageListProps> = ({
  packages = [],
  subscription,
  withTitle,
  onAfterCreateSubscription,
  createSubscriptionInModal,
}) => {
  const { t } = useTranslation();

  const apiUrl = useApiUrl();

  const [pkgData, setPkgData] = useState<IMobisalePackage | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: createSubscription, isLoading: isCreatingSubcription } =
    useCustomMutation<{
      packageId: string;
    }>();

  const handleCreateSubcription = async (packageId?: string) => {
    createSubscription(
      {
        url: `${apiUrl}/${SERVICE_BILLING}/subscriptions`,
        method: "post",
        values: {
          packageId,
          service: BILLING_SERVICE_MOBISALE,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("common.activate_package_error"),
          };
        },
        successNotification: {
          type: "success",
          message: t("common.activate_package_success"),
        },
      },
      {
        onSuccess() {
          onAfterCreateSubscription?.();
        },
      }
    );
  };

  const activatePackage = (pkgData: IMobisalePackage) => {
    if (pkgData.price === 0) {
      handleCreateSubcription(pkgData.id);
    } else {
      setPkgData(pkgData);
      if (createSubscriptionInModal) {
        setIsModalOpen(true);
      }
    }
  };

  const breakpoint = Grid.useBreakpoint();

  const sortedPackages = useMemo(() => {
    return packages.sort((a, b) => (a?.orders ?? 0) - (b?.orders ?? 0));
  }, [packages]);

  return (
    <>
      {!pkgData && (
        <Spin spinning={isCreatingSubcription}>
          {withTitle && (
            <Space style={{ alignItems: "center", padding: 16 }}>
              <img src={"/logo.svg"} alt="mobisale" width={50} />
              <Typography.Title level={4} style={{ margin: 0 }}>
                {t("site.title")}
              </Typography.Title>
            </Space>
          )}

          <div
            style={{
              display: "grid",
              gridTemplateColumns: breakpoint?.xs
                ? "repeat(auto-fill, minmax(250px, 1fr))"
                : "repeat(auto-fill, minmax(270px, 1fr))",
              gap: 16,
              padding: "5px 16px 16px",
              maxWidth: 1100,
              margin: "0px auto",
            }}
          >
            {sortedPackages?.map((pkg) => {
              const active = Boolean(subscription?.pack?.id === pkg.id);

              return (
                <PackageItem
                  {...pkg}
                  key={pkg.id}
                  active={active}
                  actionBtn={
                    !active ? (
                      <Button
                        type="primary"
                        onClick={() => activatePackage(pkg)}
                      >
                        {t("common.activate_package")}
                      </Button>
                    ) : null
                  }
                />
              );
            })}
          </div>
        </Spin>
      )}

      {pkgData && !createSubscriptionInModal && (
        <PaymentGuide
          pkgData={pkgData}
          onClose={() => {
            setPkgData(null);
          }}
        />
      )}

      {pkgData && createSubscriptionInModal && (
        <Modal
          className="modal-activate-package"
          title={null}
          open={isModalOpen}
          width={breakpoint.xl ? 1150 : breakpoint.lg ? 900 : 768}
          footer={null}
          closable={false}
          maskClosable={false}
          onOk={() => {
            setIsModalOpen(false);
          }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <PaymentGuide
            pkgData={pkgData}
            onClose={() => {
              setIsModalOpen(false);
              setPkgData(null);
            }}
          />
        </Modal>
      )}
    </>
  );
};
